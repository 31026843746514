import {
	collection,
	deleteDoc,
	doc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
	where,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { firestore, storage } from '../../resources/firebase';
import FirebaseManager from '../Firebase/FirebaseManager';
import store from '../../store/store';
import GroupItemManager from '../GroupItem/GroupItemManager';

export default class PostItemManager {
	static itemFetchCount = 10;

	static getOrgPostsRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/posts`
		);
	}

	static getOrgPosts(groupId, orgOnly, lastCursor) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			var ref = this.getOrgPostsRef(orgId);

			if (groupId) {
				ref = GroupItemManager.getOrgGroupPostsRef(orgId, groupId);
			}

			var queryRef = query(
				ref,
				orderBy('created', 'desc'),
				limit(this.itemFetchCount)
			);

			if (orgOnly) {
				queryRef = query(
					ref,
					orderBy('created', 'desc'),
					where('authorId', '==', orgId),
					limit(this.itemFetchCount)
				);
			}

			if (lastCursor) {
				if (orgOnly) {
					queryRef = query(
						ref,
						orderBy('created', 'desc'),
						where('authorId', '==', orgId),
						startAfter(lastCursor),
						limit(this.itemFetchCount)
					);
				} else {
					queryRef = query(
						ref,
						orderBy('created', 'desc'),
						startAfter(lastCursor),
						limit(this.itemFetchCount)
					);
				}
			}

			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument =
							total >= this.itemFetchCount ? documents[documents.length - 1] : null;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var postData = document.data();
							items.push(postData);
							if (counter == total - 1) {
								return resolve({ items: items, lastCursor: lastDocument });
							}
							counter += 1;
						});
					} else {
						return resolve({ items: [], lastCursor: null });
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getOrgPendingPosts(groupId, lastCursor) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			var ref = this.getOrgPostsRef(orgId);

			if (groupId) {
				ref = GroupItemManager.getOrgGroupPostsRef(orgId, groupId);
			}

			var queryRef = query(
				ref,
				orderBy('created', 'desc'),
				where('isApproved', '==', false),
				limit(this.itemFetchCount)
			);

			if (lastCursor) {
				queryRef = query(
					ref,
					orderBy('created', 'desc'),
					where('isApproved', '==', false),
					startAfter(lastCursor),
					limit(this.itemFetchCount)
				);
			}

			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument = snapshot.docs.lastItem;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var postData = document.data();
							items.push(postData);
							if (counter == total - 1) {
								return resolve({ items: items, lastCursor: lastDocument });
							}
							counter += 1;
						});
					} else {
						return resolve({ items: [], lastCursor: null });
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addPostItem(caption, imageContent) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const newPostRef = doc(this.getOrgPostsRef(orgId));

			var postData = {
				authorId: orgId,
				orgId: orgId,
				created: FirebaseManager.timestamp(),
				isAnonymous: false,
				isApproved: true,
				notificationName: organization.orgName,
				notificationTitle: organization.orgName,
				postId: newPostRef.id,
			};

			if (caption) {
				postData['postCaption'] = caption;
			}

			if (imageContent) {
				return this.addImage(newPostRef.id, imageContent)
					.then((downloadURL) => {
						const imageType = 'square';
						postData['imageItem'] = [{ imageUrl: downloadURL, imageType: imageType }];
						return this.completePostItem(newPostRef, postData)
							.then(() => {
								return resolve(postData);
							})
							.catch((error) => {
								return reject(error);
							});
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else {
				return this.completePostItem(newPostRef, postData)
					.then(() => {
						return resolve(postData);
					})
					.catch((error) => {
						return reject(error);
					});
			}
		});
	}

	static addImage(newPostRefId, imageContent) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;

			/*
let newPostImageStorageRef = storageRef.child(postId).child("image:\(postId).jpeg")
        let newPostVideoStorageRef = storageRef.child(postId).child("video:\(postId).mp4")
		*/

			const newPostStorageRef = ref(
				storage,
				`${
					FirebaseManager.organizationRef().path
				}/${orgId}/posts/${newPostRefId}/image:${newPostRefId}.jpeg`
			);

			const metadata = {
				contentType: 'image/jpeg',
			};

			uploadBytes(newPostStorageRef, imageContent, metadata)
				.then((snapshot) => {
					getDownloadURL(snapshot.ref)
						.then((downloadURL) => {
							return resolve(downloadURL);
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static completePostItem(newPostRef, postData) {
		return new Promise((resolve, reject) => {
			setDoc(newPostRef, postData)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static approvePostItem(postId, groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			var postRef = doc(firestore, `${this.getOrgPostsRef(orgId).path}/${postId}`);
			if (groupId) {
				postRef = doc(
					firestore,
					`${GroupItemManager.getOrgGroupPostsRef(orgId, groupId).path}/${postId}`
				);
			}
			setDoc(
				postRef,
				{ isApproved: true, created: FirebaseManager.timestamp() },
				{ merge: true }
			)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static deletePostItem(postId, groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			var storageRef = ref(
				storage,
				`${this.getOrgPostsRef(orgId).path}/${postId}`
			);

			if (groupId) {
				storageRef = ref(
					storage,
					`${GroupItemManager.getOrgGroupPostsRef(orgId, groupId).path}/${postId}`
				);
			}

			FirebaseManager.deleteStorageRef(storageRef).finally(() => {
				return this.completeDelete(postId, groupId)
					.then(() => {
						return resolve();
					})
					.catch((errorMessage) => {
						return reject(errorMessage);
					});
			});
		});
	}

	static completeDelete(postId, groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			var postRef = doc(firestore, `${this.getOrgPostsRef(orgId).path}/${postId}`);
			if (groupId) {
				postRef = doc(
					firestore,
					`${GroupItemManager.getOrgGroupPostsRef(orgId, groupId).path}/${postId}`
				);
			}
			deleteDoc(postRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
