<template>
	<BaseTable
		:headerTitle="headerTitle"
		:isLoading="isLoading"
		:filterOption="filterOption"
		:algoliaSearch="false"
		:tableHeaders="tableHeaders"
		:tableData="tableData"
		:recordsPerPage="getRecordsPerPage()"
		:total="total"
		@currentPage="currentPage"
		@acceptClicked="acceptClicked"
		@declineClicked="declineClicked"
		@viewClicked="viewClicked"
		@actionDropdownClicked="actionDropdownClicked"
	/>
</template>
<script>
import BaseTable from '../../../components/Tables/BaseTable.vue';
import AppUserManager from '../../../models/AppUser/AppUserManager';

export default {
	props: {
		isLoading: Boolean,
		isPending: Boolean,
		headerTitle: {
			type: String,
			default: null,
		},
		filterOption: {
			type: Boolean,
			default: true,
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
		total: {
			type: Number,
			description: 'Total Number of data for table',
		},
	},
	data() {
		return {
			recordsPerPage: 0,
			tableHeaders: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return {
							name: `${item.userData.firstName} ${item.userData.lastName}`,
							photo: item.userData.profilePhotoURL,
							isAdmin: AppUserManager.isAdminUser(item.userData),
							isFlagged: AppUserManager.isUserFlagged(item.userData),
						};
					},
					sortByFormatted: (value, key, item) => {
						return `${item.userData.firstName} ${item.userData.lastName}`;
					},
				},
				{
					key: 'username',
					label: 'Username',
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return `@${item.userData.username}`;
					},
				},
				{
					key: 'email',
					label: 'Email',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return `@${item.userData.email}`;
					},
				},
				{
					key: this.isPending ? 'pendingActions' : 'actions',
					label: '',
					thStyle: { width: this.isPending ? 'auto' : '20%' },
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return [
							{
								title: 'View Member',
								click: 'viewClicked',
							},
							{
								isDivider: true,
							},
							{
								title: 'Remove Member',
								click: 'removeClicked',
								variant: 'danger',
							},
						];
					},
				},
			],
		};
	},
	methods: {
		getRecordsPerPage() {
			return AppUserManager.limitFetch;
		},
		actionDropdownClicked(item) {
			const click = item.item.click;
			const userItem = item.row;
			if (click == 'viewClicked') {
				this.viewClicked(userItem);
			} else if (click == 'removeClicked') {
				this.removeClicked(userItem);
			}
		},
		acceptClicked(item) {
			this.$emit('acceptClicked', item);
		},
		declineClicked(item) {
			this.$emit('declineClicked', item);
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		removeClicked(item) {
			this.$emit('removeClicked', item);
		},
		currentPage(index) {
			this.$emit('currentPage', index);
		},
	},
	components: { BaseTable },
};
</script>
