<template>
	<div>
		<Card :no-body="!updatedData.isCustomDefaultButton">
			<CardHeader
				slot="header"
				:title="defaultButtonLabel"
				:tooltip="defaultButtonTooltip"
				:endSwitch="showUpdateButton ? null : { title: '' }"
				:endSwitchValue="updatedData.isCustomDefaultButton"
				@change="customButtonSwitchChanged"
				:end-button="
					showUpdateButton ? { title: 'Update', disabled: !needsUpdate } : null
				"
				@click="onUpdate"
			/>
			<CardFooter
				slot="footer"
				v-if="updatedData.isCustomDefaultButton && defaultButtonFooterLabel"
				:title="defaultButtonFooterLabel"
			/>
			<b-row v-if="updatedData.isCustomDefaultButton">
				<b-col>
					<BaseInput
						label="Button Title"
						id="button title"
						placeholder="Button Title"
						type="text"
						v-model="updatedData.defaultButtonTitle"
					/>
				</b-col>
				<b-col>
					<label class="form-control-label">Button Link</label>
					<b-row class="align-items-center">
						<b-col>
							<BaseInput
								id="button link"
								placeholder="https://example.com"
								name="link"
								type="url"
								pattern="https://.*"
								:with-padding="false"
								v-model="updatedData.defaultButtonLink"
							/>
						</b-col>
						<b-col cols="auto" v-if="updatedData.defaultButtonLink">
							<a
								target="_blank"
								rel="noopener noreferrer"
								:href="updatedData.defaultButtonLink"
								class="base-button"
								><u><h5 class="mb-0 text-muted">Test Link</h5></u></a
							>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import ButtonCheckboxGroup from '../../../components/ButtonCheckboxGroup';
import ButtonRadioGroup from '../../../components/ButtonRadioGroup.vue';
import Card from '../../../components/Cards/Card.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';

export default {
	props: {
		defaultButtonLabel: {
			type: String,
			default: 'Registration Button',
		},
		defaultButtonFooterLabel: {
			type: String,
			default: null,
		},
		defaultButtonTooltip: {
			type: String,
			default: 'Replace the default button with your own button title or link.',
		},
		customButtonTooltip: {
			type: String,
			default:
				'Add a custom button to the information page. This additional button can link to any external web source.',
		},
		allowCustomButton: {
			type: Boolean,
			default: false,
		},
		requiresLink: {
			type: Boolean,
			default: false,
		},
		showUpdateButton: {
			type: Boolean,
			default: false,
		},
		defaultTitleValue: {
			type: String,
			default: null,
		},
		defaultLinkValue: {
			type: String,
			default: null,
		},
	},
	components: {
		Card,
		CardHeader,
		BaseInput,
		ButtonRadioGroup,
		BaseButton,
		ButtonCheckboxGroup,
		CardFooter,
	},
	data() {
		return {
			needsUpdate: false,
			updatedData: {
				isCustomDefaultButton: false,
				defaultButtonTitle: null,
				defaultButtonLink: null,
			},
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				this.needsUpdate = this.checkUpdate();
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		customButtonSwitchChanged(value) {
			this.updatedData.isCustomDefaultButton = value;
			if (!value) {
				this.updatedData.defaultButtonTitle = null;
				this.updatedData.defaultButtonLink = null;
			}
		},
		checkUpdate() {
			if (this.updatedData.isCustomDefaultButton) {
				if (
					this.requiresLink &&
					this.$helpers.isBlank(this.updatedData.defaultButtonLink)
				) {
					return false;
				}
				if (this.updatedData.defaultButtonTitle != this.defaultTitleValue) {
					return true;
				}
				if (this.updatedData.defaultButtonLink != this.defaultLinkValue) {
					return true;
				}
			}
			return false;
		},
		onUpdate() {
			this.$emit('onUpdate', this.updatedData);
			this.needsUpdate = false;
		},
		onChange() {
			if (this.updatedData.isCustomDefaultButton) {
				if (this.$helpers.isBlank(this.updatedData.defaultButtonTitle)) {
					return false;
				}
				if (
					this.requiresLink &&
					this.$helpers.isBlank(this.updatedData.defaultButtonLink)
				) {
					return false;
				}
			}
			this.$emit('onChange', this.updatedData);
		},
	},
	mounted() {
		this.updatedData.isCustomDefaultButton = this.showUpdateButton;
		if (this.defaultTitleValue) {
			this.updatedData.defaultButtonTitle = this.defaultTitleValue;
		}
		if (this.defaultLinkValue) {
			this.updatedData.defaultButtonLink = this.defaultLinkValue;
		}
	},
};
</script>
