<template>
	<div>
		<Card :no-body="!updatedData.isAgeLimit">
			<CardHeader
				slot="header"
				:title="ageLabel"
				:endSwitch="showUpdateButton ? null : {}"
				:endSwitchValue="updatedData.isAgeLimit"
				:end-button="
					showUpdateButton ? { title: 'Update', disabled: !needsUpdate } : null
				"
				@click="onUpdate"
				@change="ageSwitchChanged"
			/>

			<form v-if="updatedData.isAgeLimit">
				<b-row>
					<b-col>
						<BaseInput
							label="Start Age (in years)"
							id="start age"
							name="start age"
							placeholder="Start Age"
							:required="this.showUpdateButton ? false : this.updatedData.isAgeLimit"
							:minLength="parseInt(1)"
							type="number"
							v-model="updatedData.startAge"
						/>
					</b-col>
					<b-col>
						<BaseInput
							label="End Age (optional)"
							id="end age"
							name="end age"
							placeholder="End Age (optional)"
							:minLength="parseInt(updatedData.startAge) + 1"
							type="number"
							v-model="updatedData.endAge"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<ButtonCheckboxGroup
							:showBorder="false"
							:options="ageLimitOptions"
							@change="ageLimitEnforcedChanged"
						/>
					</b-col>
				</b-row>
			</form>
		</Card>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import ButtonCheckboxGroup from '../../../components/ButtonCheckboxGroup';

export default {
	props: {
		startAgeValue: {
			type: Number,
			default: null,
		},
		endAgeValue: {
			type: Number,
			default: null,
		},
		defaultAgeEnforced: {
			type: Boolean,
			default: false,
		},
		showUpdateButton: {
			type: Boolean,
			default: false,
		},
	},
	components: { Card, CardHeader, BaseInput, ButtonCheckboxGroup },
	data() {
		return {
			needsUpdate: false,
			ageLabel: 'Age Limit (optional)',
			ageLimitOptions: [
				{
					name: 'Enforce Age Limit',
					tooltip: 'When turned on, users must be this age limit to register.',
					selected: false,
				},
			],
			updatedData: {
				isAgeLimit: false,
				startAge: null,
				endAge: null,
				ageEnforced: false,
			},
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				this.adjustAgeTitle();
				this.needsUpdate = this.checkUpdate();
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		ageSwitchChanged(value) {
			this.updatedData.isAgeLimit = value;
			if (!value) {
				this.updatedData.startAge = null;
				this.updatedData.endAge = null;
				this.updatedData.ageEnforced = false;
			}
		},
		ageLimitEnforcedChanged(values) {
			this.updatedData.ageEnforced = this.ageLimitOptions[values[0]] != null;
		},
		adjustAgeTitle() {
			if (this.updatedData.startAge) {
				if (this.updatedData.endAge) {
					this.ageLabel = `Age Limit: (${this.updatedData.startAge}yrs - ${this.updatedData.endAge}yrs)`;
				} else {
					this.ageLabel = `Age Limit: (${this.updatedData.startAge}+)`;
				}
			} else {
				if (this.showUpdateButton) {
					this.ageLabel = 'No Age Limit';
				} else {
					this.ageLabel = 'Age Limit (optional)';
				}
			}
		},
		checkUpdate() {
			if (this.updatedData.startAge != this.startAgeValue) {
				return true;
			}
			if (
				this.updatedData.startAge &&
				this.updatedData.endAge != this.endAgeValue
			) {
				return true;
			}
			if (
				this.updatedData.startAge &&
				this.updatedData.ageEnforced != this.defaultAgeEnforced
			) {
				return true;
			}
			return false;
		},
		onUpdate() {
			this.$emit('onUpdate', this.updatedData);
			this.needsUpdate = false;
		},
		onChange() {
			this.$emit('onChange', this.updatedData);
		},
	},
	mounted() {
		this.updatedData.isAgeLimit = this.showUpdateButton;
		this.updatedData.ageEnforced = this.defaultAgeEnforced;
		if (this.startAgeValue) {
			this.updatedData.startAge = this.startAgeValue;
		}
		if (this.endAgeValue) {
			this.updatedData.endAge = this.endAgeValue;
		}
	},
};
</script>
