<template>
	<div
		v-if="featuredButton"
		@mouseover="viewHover = true"
		@mouseleave="viewHover = false"
	>
		<Card no-body type="light">
			<div class="b-img-helpers card-img px-1 py-2 m-0">
				<div :style="{ opacity: viewHover ? 0.4 : 1 }">
					<div class="d-flex align-items-center justify-content-center text-center">
						<h5 class="mb-0 text-dark oneLine">
							<strong>
								<i :class="`fa ${featuredButton.icon}`"></i>
								{{ featuredButton.title }}</strong
							>
						</h5>
					</div>
				</div>
				<div
					v-if="viewHover"
					style="
						height: 100%;
						width: 100%;
						border: dashed 1px black;
						display: flex;
						flex-wrap: wrap;
						align-content: center;
					"
					class="justify-content-center b-img-base"
				>
					<BaseButton type="medium" size="sm" block @click="itemClicked"
						>Delete</BaseButton
					>
				</div>
			</div>
		</Card>
	</div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import Card from '../../../components/Cards/Card.vue';

export default {
	props: {
		featuredButton: {
			type: Object,
			default: null,
		},
	},
	components: { Card, BaseButton },
	data() {
		return {
			viewHover: false,
		};
	},
	methods: {
		itemClicked() {
			this.$emit('deleteClicked', this.featuredButton);
		},
	},
};
</script>
