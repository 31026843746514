<template>
	<div>
		<b-row>
			<b-col>
				<Card>
					<CardHeader slot="header" title="Current Plan" />
					<b-row>
						<b-col>
							<PlanOptionCard
								:isLoading="isPlanLoading"
								:planData="defaulPlanData"
								planBadgeTitle="Current Plan"
								showUpdateButton
								isCurrent
								@cancelClicked="cancelClicked"
							/>
						</b-col>
					</b-row>
					<hr class="mt-3" />
					<label class="form-control-label">Other Options:</label>
					<b-row>
						<b-col
							v-for="planData in allPlanOptions"
							v-if="planData.planId !== defaulPlanData.planId"
						>
							<PlanOptionCard
								:planData="planData"
								@subscribeClicked="subscribeClicked"
							/>
						</b-col>
					</b-row>
				</Card>
			</b-col>
			<b-col>
				<b-row>
					<b-col>
						<b-overlay :show="isLoading" rounded="sm">
							<Card>
								<CardHeader
									slot="header"
									title="Payment Method"
									:end-button="{ title: 'Update', disabled: false }"
									@click="updateCreditCard"
								/>
								<CreditCard :creditCard="defaultPayment" />
							</Card>
						</b-overlay>
					</b-col>
				</b-row>
				<b-row>
					<b-col> something here </b-col>
				</b-row>
			</b-col>
		</b-row>
		<UpdateCreditCard
			:showModal="showUpdateCardModal"
			@onPaymentId="newPaymentId"
			@close="showUpdateCardModal = false"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AppOrganizationBillingManager from '../../../models/AppOrganization/AppOrganizationBillingManager';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import CreditCard from '../../../components/Cards/CreditCard.vue';
import UpdateCreditCard from '../PaymentViews/UpdateCreditCard.vue';
import PlanOptionCard from '../PaymentViews/PlanOptionCard.vue';

export default {
	components: {
		Card,
		CardHeader,
		CreditCard,
		UpdateCreditCard,
		PlanOptionCard,
	},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	data() {
		return {
			isLoading: false,
			isPlanLoading: false,
			defaultPayment: null,
			defaulPlanData: null,
			allPlanOptions: [],
			showUpdateCardModal: false,
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getAllOrgPlans() {
			this.isPlanLoading = true;
			AppOrganizationBillingManager.getAllOrgPlans().then((items) => {
				this.isPlanLoading = false;
				this.allPlanOptions = items;
			});
		},
		getOrgPlan() {
			this.isPlanLoading = true;
			AppOrganizationBillingManager.getOrgPlanOption().then((planData) => {
				this.isPlanLoading = false;
				this.defaulPlanData = planData;
			});
		},
		getDefaultOrgCard() {
			this.isLoading = true;
			AppOrganizationBillingManager.getDefaultPaymentMethod().then((cardInfo) => {
				this.isLoading = false;
				this.defaultPayment = cardInfo;
			});
		},
		subscribeClicked(planData) {
			console.log(planData.planId);
		},
		cancelClicked(planData) {
			console.log(planData.planId);
		},
		newPaymentId(paymentId) {
			this.isLoading = true;
			AppOrganizationBillingManager.createPaymentMethod(paymentId)
				.then(() => {
					this.getDefaultOrgCard();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		updateCreditCard() {
			this.showUpdateCardModal = true;
		},
	},
	mounted() {
		this.getDefaultOrgCard();
		this.getAllOrgPlans();
		this.getOrgPlan();
	},
};
</script>
