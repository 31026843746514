<template>
	<div>
		<Card>
			<CardHeader
				slot="header"
				:title="'Featured'"
				:end-button="{ title: 'New Featured', icon: 'fa fa-plus' }"
				@click="checkIfCanUpload()"
			/>
			<CardFooter
				slot="footer"
				:title="'*For best experience, please limit these items to under 8'"
			/>
			<b-overlay :show="isLoading" rounded="sm">
				<b-row class="justify-content-start">
					<b-col
						class="my-2"
						cols="4"
						v-for="featuredItem in allFeatured"
						:key="featuredItem.featuredItem"
					>
						<FeaturedItem :featured-data="featuredItem" @editClicked="editClicked" />
					</b-col>
				</b-row>
			</b-overlay>
		</Card>
		<NewFeaturedModal
			:show-modal="showModal"
			@close="showModal = false"
			@newFeatured="addNewFeatured"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@yesClicked="deleteFeaturedItem"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import FeaturedItemManager from '../../../models/FeaturedItem/FeaturedItemManager';
import FeaturedItem from './FeaturedItem.vue';
import NewFeaturedModal from './NewFeaturedModal.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';

export default {
	props: {
		allFeatured: {
			type: Array,
			default: [],
		},
	},
	components: {
		Card,
		CardHeader,
		CardFooter,
		FeaturedItem,
		NewFeaturedModal,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			showModal: false,
			showQuestionModal: false,
			modalQuestion: 'Do you want to delete this featured item?',
			featuredData: null,
		};
	},
	methods: {
		...mapActions(['showNotification']),
		checkIfCanUpload() {
			FeaturedItemManager.canUploadFeatured().then((canUpload) => {
				if (canUpload) {
					this.showModal = true;
				} else {
					this.showNotification({
						type: 'danger',
						message:
							'You have reached your limit of featured items allowed. To add more, please remove any previous item.',
					});
				}
			});
		},
		editClicked(featuredData) {
			this.featuredData = featuredData;
			this.showQuestionModal = true;
		},
		deleteFeaturedItem() {
			this.isLoading = true;
			FeaturedItemManager.deleteFeaturedItem(this.featuredData.featuredId)
				.then(() => {
					this.$emit('updateFeatured');
					this.isLoading = false;
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		addNewFeatured(featuredData) {
			this.$emit('newFeatured', featuredData);
		},
	},
};
</script>
