<template>
	<Modal :show="showModal" @close="closeModal" hideFooter>
		<template v-slot:header>
			<h5 class="modal-title" id="modalTitle">{{ headerTitle }}</h5>
		</template>
		<b-overlay :show="isLoading" rounded="sm">
			<h5 style="flex: auto; text-align: center">
				{{ headerDescription }}
			</h5>
			<h6 v-if="errorMessage" style="color: red; flex: auto; text-align: center">
				{{ errorMessage }}
			</h6>
			<form style="flex-grow: 1">
				<BaseInput class="mb-0">
					<textarea
						placeholder="Enter your message..."
						name="message"
						v-model="model.message"
						class="form-control mb-0"
						id="notification"
						rows="9"
						@input="onInput"
					></textarea>
					<b-row class="mr-0 mt-1 mb-0">
						<div style="flex-grow: 1"></div>
						<h6 class="">
							{{ model.messageCharCount }}/{{ model.messageCharMaxCount }}
						</h6>
					</b-row>
				</BaseInput>
				<b-row class="mt-0">
					<b-col cols="auto" class="mr-auto">
						<label class="form-control-label">Schedule Notification</label>
					</b-col>
					<b-col cols="auto">
						<BaseSwitch
							v-model="isScheduled"
							@change="onScheduledChange"
						></BaseSwitch>
					</b-col>
				</b-row>
				<div v-if="isScheduled">
					<BaseInput
						class="mt-2 pb-0"
						:required="isScheduled"
						type="datetime-local"
						v-model="model.scheduledDate"
						id="schedule time"
						:min="dateChange(minDate)"
					>
					</BaseInput>
					<h6 class="mt-0 pt-0">
						TIP: Please allow an additional 10-15 minutes for schedule notifications
						to trigger
					</h6>
				</div>

				<b-row class="mr-0 mt-3 mb-3">
					<div style="flex-grow: 1"></div>
					<BaseButton
						type="primary"
						:loading="isLoading"
						:shake="shake"
						@click="submit"
						>Send Notification</BaseButton
					>
				</b-row>
			</form>
		</b-overlay>
	</Modal>
</template>
<script>
import { mapActions } from 'vuex';
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import Modal from '../../../components/Modals/Modal.vue';
import NotificationItemManager from '../../../models/NotificationItem/NotificationManager';
import helpers from '../../../helpers/helpers';
export default {
	props: {
		headerTitle: {
			type: String,
			default: 'New Notification',
		},
		headerDescription: {
			type: String,
			default: 'Send your message as a notification to all users.',
		},
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Modal,
		BaseInput,
		BaseButton,
		BaseSwitch,
	},
	watch: {
		model: {
			handler(val) {
				return (this.errorMessage = null);
			},
			deep: true,
		},
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			errorMessage: null,
			isScheduled: false,
			minDate: new Date(),
			model: {
				message: null,
				messageCharCount: 0,
				messageCharMinCount: 10,
				messageCharMaxCount: 500,
				scheduledDate: null,
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
		onInput() {
			this.model.messageCharCount = this.model.message.length;
			if (this.model.messageCharCount > 0) {
				this.errorMessage == null;
			}
			if (this.model.messageCharCount >= this.model.messageCharMaxCount) {
				this.model.message = this.model.message.substring(
					0,
					this.model.messageCharMaxCount
				);
				this.model.messageCharCount = this.model.message.length;
			}
		},
		onScheduledChange() {
			if (!this.isScheduled) {
				this.model.scheduledDate = null;
			}
		},
		dateChange(date) {
			helpers.formatDate(date, "yyyy-MM-dd'T'HH:mm:ss");
		},
		submit() {
			this.shake = false;
			if (this.model.messageCharCount < this.model.messageCharMinCount) {
				this.errorMessage = 'Please enter a valid notification message.';
				this.shake = true;
				return;
			} else if (this.isScheduled && !this.model.scheduledDate) {
				this.errorMessage = 'Please enter a valid scheduled date.';
				this.shake = true;
				return;
			} else {
				this.isLoading = true;
				NotificationItemManager.sendNotificationToDB(
					this.model.message,
					this.isScheduled ? this.model.scheduledDate : null
				)
					.then((notification) => {
						this.isLoading = false;
						this.$emit('newNotification', notification);
					})
					.catch((error) => {
						this.isLoading = false;
						this.showNotification({
							type: 'danger',
							message: error,
						});
					});
			}
		},
		closeModal() {
			if (!this.isLoading) {
				this.$emit('update:show', false);
				this.$emit('close');
			}
		},
	},
	mounted() {
		this.model.scheduledDate = this.dateChange(new Date());
	},
};
</script>
