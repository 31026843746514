<template>
	<Card v-if="userData">
		
		<CardHeader
			slot="header"
			:title="'Account Information'"
			:end-button="{ title: 'Update Profile', disabled: !isUpdatedNeed }"
			@click="updateUserInformation"
		>
		</CardHeader>
		
		<form>
			<b-overlay :show="isLoading" rounded="sm">
			<div>
				<b-row>
					<b-col>
						<BaseInput
							label="First Name"
							type="text"
							placeholder="First Name"
							name="first name"
							v-model="updatedData.firstName"
							required
						/>
					</b-col>
					<b-col>
						<BaseInput
							label="Last Name"
							placeholder="Last Name"
							name="last name"
							type="text"
							v-model="updatedData.lastName"
							required
						/>
					</b-col>
				</b-row>
				<BaseInput
					type="email"
					label="Email"
					placeholder="Email"
					name="email"
					disabled
					v-model="userData.email"
					required
				/>
				<BaseInput
					type="username"
					label="Username"
					placeholder="Username"
					name="username"
					disabled
					v-model="userData.username"
					required
				/>
				<BaseInput
					type="mask"
					label="Phone Number"
					placeholder="(123) 456-7890"
					name="phoneNumber"
					mask="(###) ###-####"
					:maskedValue="true"
					v-model="updatedData.phoneNumber"
				>
				</BaseInput>
				<BaseInput
					type="text"
					label="Member Id"
					placeholder="Member Id"
					disabled
					:value="`${userData.uid.substring(0, 10)}****`"
				>
				</BaseInput>
			</div>
			<hr class="my-3" />
			<h6 class="heading-small text-muted mb-2">Additional Information</h6>
			<div>
				<BaseInput
					type="text"
					label="Address"
					placeholder="Add Current Address"
					name="address"
					v-model="updatedData.address"
				>
				</BaseInput>
				<BaseInput
					type="date"
					label="Birthday"
					placeholder="Enter Date Of Birth"
					name="birthday"
					v-model="updatedData.dateOfBirth"
				>
				</BaseInput>
				<BaseInput
					type="text"
					label="Martial Status"
					placeholder="Enter Martial Status"
					name="martial status"
				>
					<select
						class="form-control"
						id="gender"
						v-model="updatedData.martialStatus"
						required
					>
						<option :value="null" disabled>Add Martial Status</option>
						<option
							v-for="options in martialStatusOptions"
							:key="options.value"
							:value="options.value"
						>
							{{ options.name }}
						</option>
					</select>
				</BaseInput>
				<BaseInput
					type="text"
					label="Gender"
					placeholder="Enter Gender"
					name="gender"
				>
					<select
						class="form-control"
						id="gender"
						v-model="updatedData.gender"
						required
					>
						<option :value="null" disabled>Add Gender</option>
						<option
							v-for="options in genderOptions"
							:key="options.value"
							:value="options.value"
						>
							{{ options.name }}
						</option>
					</select></BaseInput
				>
			</div>
			<hr class="my-3" />
			<div>
				<BaseInput label="Quick Bio">
					<textarea
						type="text"
						class="form-control"
						rows="5"
						placeholder="Quick bio summary about this user for others to view..."
						name="app bio"
						v-model="updatedData.appBio"
						disabled
					></textarea>
				</BaseInput>
			</div>
		</b-overlay>
		</form>
	</Card>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import AppUserManager from '../../../models/AppUser/AppUserManager';

export default {
	props: {
		userData: null,
	},
	components: { Card, CardHeader, BaseInput },
	data() {
		return {
			isLoading: false,
			isUpdatedNeed: false,
			updatedData: {
				firstName: null,
				lastName: null,
				username: null,
				phoneNumber: null,
				address: null,
				dateOfBirth: null,
				martialStatus: null,
				gender: null,
				appBio: null,
			},
			completedData: {},
			martialStatusOptions: [
				{
					name: 'Single',
					value: 'single',
				},
				{
					name: 'Married',
					value: 'married',
				},
				{
					name: 'Widowed',
					value: 'widowed',
				},
				{
					name: 'Divorced',
					value: 'divorced',
				},
			],
			genderOptions: [
				{
					name: 'Female',
					value: 'female',
				},
				{
					name: 'Male',
					value: 'male',
				},
			],
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				return (this.isUpdatedNeed = this.checkIfUpdateNeeded());
			},
			deep: true,
		},
	},
	methods: {
		...mapActions(['showNotification']),
		checkIfUpdateNeeded(){
			this.completedData = {}
			if (this.$helpers.isBlank(this.updatedData.firstName) || this.$helpers.isBlank(this.updatedData.lastName) || this.$helpers.isBlank(this.updatedData.username)) {
				return false;
			}
			if (this.updatedData.firstName != this.userData.firstName) {
				this.completedData.firstName = this.updatedData.firstName
			}
			if (this.updatedData.lastName != this.userData.lastName) {
				this.completedData.lastName = this.updatedData.lastName
			}
			if (this.updatedData.phoneNumber != this.$helpers.getNullOrValue(this.userData.phoneNumber)) {
				this.completedData.phoneNumber = this.updatedData.phoneNumber
			}
			if (this.updatedData.address != this.$helpers.getNullOrValue(this.userData.address)) {
				this.completedData.address = this.updatedData.address
			}
			if (this.updatedData.martialStatus != this.$helpers.getNullOrValue(this.userData.martialStatus)) {
				this.completedData.martialStatus = this.updatedData.martialStatus
			}
			if (this.updatedData.gender != this.$helpers.getNullOrValue(this.userData.gender)) {
				this.completedData.gender = this.updatedData.gender
			}
			if (this.updatedData.dateOfBirth) {
				if (!this.userData.dateOfBirth){
					this.completedData.dateOfBirth = new Date(this.updatedData.dateOfBirth)
				} else if (this.updatedData.dateOfBirth != this.$helpers.formatDate(
					this.$helpers.returnFirebaseDate(this.userData.dateOfBirth),
					'yyyy-MM-dd'
				)) {
					this.completedData.dateOfBirth = new Date(this.updatedData.dateOfBirth)
				}
			}
			this.completedData = this.$helpers.removeAllNullValues(this.completedData)
			return !this.$helpers.isBlankObject(this.completedData)
		},
		updateUserInformation() {
			if (this.completedData){
				this.updateUserData()
			}
		},
		setupView() {
			this.updatedData = {
				firstName: this.userData.firstName,
				lastName: this.userData.lastName,
				username: `@${this.userData.username}`,
				phoneNumber: this.$helpers.getNullOrValue(this.userData.phoneNumber),
				address: this.$helpers.getNullOrValue(this.userData.address),
				martialStatus: this.$helpers.getNullOrValue(this.userData.martialStatus),
				gender: this.$helpers.getNullOrValue(this.userData.gender),
				appBio: this.$helpers.getNullOrValue(this.userData.appBio),
			};
			if (this.userData.dateOfBirth) {
				this.updatedData.dateOfBirth = this.$helpers.formatDate(
					this.$helpers.returnFirebaseDate(this.userData.dateOfBirth),
					'yyyy-MM-dd'
				);
			}
		},
		updateUserData() {
			this.isLoading = true;
			AppUserManager.updateUserField(this.userData.uid, this.completedData).then(() => {
				this.isLoading = false
				this.$emit('onUpdate', this.completedData)
				//UPDAET THIS HERE
				this.showNotification({
						type: 'success',
						message: 'User information has been updated',
					});
			})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
	},
	mounted() {
		this.setupView();
	},
};
</script>
