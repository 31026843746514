<template>
	<div>
		<Card :no-body="isDefault">
			<CardHeader
				slot="header"
				:title="headerLabel"
				:endSwitch="
					showUpdateButton || !defaultValue ? null : { title: 'Our Location' }
				"
				:endSwitchValue="isDefault"
				:end-button="
					showUpdateButton ? { title: 'Update', disabled: !needsUpdate } : null
				"
				@change="locationSwitchChanged"
				@click="onUpdate"
			/>
			<div v-if="!isDefault">
				<b-row>
					<b-col>
						<BaseInput
							:label="titleLabel"
							:id="titleLabel"
							:name="titleLabel"
							:placeholder="titleLabel"
							required
							type="text"
							v-model="updatedData.title"
						/>
					</b-col>
					<b-col>
						<BaseInput
							:label="descriptionLabel"
							:id="descriptionLabel"
							:name="descriptionLabel"
							:placeholder="descriptionLabel"
							type="text"
							v-model="updatedData.description"
						/>
					</b-col>
				</b-row>
			</div>
		</Card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		headerLabel: {
			type: String,
			default: 'Location (optional)',
		},
		titleLabel: {
			type: String,
			default: 'Title',
		},
		titleValue: {
			type: String,
			default: null,
		},
		descriptionLabel: {
			type: String,
			default: 'Description',
		},
		descriptionValue: {
			type: String,
			default: null,
		},
		defaultValue: {
			type: Boolean,
			default: true,
		},
		showUpdateButton: {
			type: Boolean,
			default: false,
		},
	},
	components: { Card, CardHeader, BaseInput },
	data() {
		return {
			isDefault: true,
			needsUpdate: false,
			updatedData: {
				title: null,
				description: null,
			},
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				this.needsUpdate = this.checkUpdate();
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		locationSwitchChanged(value) {
			this.isDefault = value;
			if (this.isDefault) {
				this.setDefaultLocation();
			}
		},
		setDefaultLocation() {
			if (this.titleValue) {
				this.updatedData.title = this.titleValue;
			}
			if (this.descriptionValue) {
				this.updatedData.description = this.descriptionValue;
			}
			if (
				this.titleValue == null &&
				this.descriptionValue == null &&
				!this.showUpdateButton &&
				this.organization.orgLocation
			) {
				this.updatedData.title = this.organization.orgLocation.title;
				this.updatedData.description = this.organization.orgLocation.description;
			}
		},
		checkUpdate() {
			if (this.$helpers.isBlank(this.updatedData.title)) {
				return false;
			}
			if (this.updatedData.title != this.titleValue) {
				return true;
			}
			if (this.updatedData.description != this.descriptionValue) {
				return true;
			}
			return false;
		},
		onUpdate() {
			this.$emit('onUpdate', this.updatedData);
			this.needsUpdate = false;
		},
		onChange() {
			this.$emit('onChange', this.updatedData);
		},
	},
	mounted() {
		this.isDefault = !this.showUpdateButton;
		if (this.defaultValue == false) {
			this.isDefault = this.defaultValue;
		} else {
			this.setDefaultLocation();
		}
	},
};
</script>
