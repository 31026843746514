<template>
	<BaseHeader class="">
		<b-row>
			<b-col cols="4">
				<h2 class="text-dark">{{ title }}</h2>
			</b-col>
			<b-col cols="8" class="d-flex justify-content-end align-items-center">
				<div
					v-if="buttonItems"
					class="pl-2 d-inline-block"
					v-for="button in buttonItems"
					v-bind:key="button.index"
				>
					<BaseButton
						v-if="!button.children"
						:outline="button.outline"
						:disabled="button.isDisabled"
						:type="button.type"
						:icon="button.icon"
						@click="handleClick(button)"
						size="sm"
					>
						{{ button.title }}
					</BaseButton>

					<b-dropdown
						v-if="button.children"
						variant="primary"
						:text="button.title"
						size="sm"
						right
					>
						<div v-for="buttonChild in button.children" :key="buttonChild.index">
							<b-dropdown-item
								v-if="!buttonChild.isDivider"
								:disabled="buttonChild.isDisabled"
								@click="handleChildClick(buttonChild)"
							>
								<span :class="buttonChild.type ? `text-${buttonChild.type}` : ``">
									{{ buttonChild.title }}
								</span></b-dropdown-item
							>

							<b-dropdown-divider v-if="buttonChild.isDivider"></b-dropdown-divider>
						</div>
					</b-dropdown>
				</div>
			</b-col>
		</b-row>
	</BaseHeader>
</template>

<script>
import BaseButton from '../BaseButton.vue';
import BaseHeader from '../BaseHeader.vue';

export default {
	components: { BaseHeader, BaseButton },
	props: {
		title: String,
		buttonItems: [],
	},
	methods: {
		handleChildClick(buttonItem) {
			this.$emit('childClick', buttonItem);
		},
		handleClick(buttonItem) {
			this.$emit('click', buttonItem);
		},
	},
};
</script>
