<template>
	<div>
		<DashboardHeader :title="$route.name" />
		<BaseContent>
			<b-overlay :show="isLoading" rounded="sm">
				<Card no-body>
					<b-tabs
						active-nav-item-class="bg-white font-weight-bold description"
						nav-class="bg-white description"
						card
						no-body
						@input="tabChanged"
					>
						<b-tab ref="tab1" title="Organization" active>
							<OrgAccountCard />
						</b-tab>

						<b-tab title="Giving Options" @click="tabClicked(2)">
							<OrgGivingCard :refresh="activeTabs.includes(2)" />
						</b-tab>
						<b-tab title="Account Billing" @click.prevent="tabClicked(1)"> </b-tab>
					</b-tabs>
				</Card>
			</b-overlay>
		</BaseContent>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import AppOrganizationBillingManager from '../../models/AppOrganization/AppOrganizationBillingManager';
import BaseContent from '../../components/BaseContent.vue';
import Card from '../../components/Cards/Card.vue';
import CardHeader from '../../components/Cards/CardHeader.vue';
import DashboardHeader from '../../components/Dashboard/DashboardHeader.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import OrgAccountCard from '../SecondaryComponents/OrgViews/OrgAccountCard.vue';
import GivingCard from '../SecondaryComponents/OrgViews/GivingCard.vue';
import OrgGivingCard from '../SecondaryComponents/OrgViews/OrgGivingCard.vue';
import BillingCard from '../SecondaryComponents/OrgViews/BillingCard.vue';

export default {
	components: {
		DashboardHeader,
		BaseContent,
		Card,
		CardHeader,
		BaseInput,
		OrgAccountCard,
		GivingCard,
		OrgGivingCard,
		BillingCard,
	},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
			publicInformation: 'getPublicInformation',
		}),
	},
	data() {
		return {
			isLoading: false,
			givingData: false,
			activeTabs: [],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		tabChanged(event) {
			if (event == 2) {
				this.$refs.tab1.activate();
			}
		},
		tabClicked(index) {
			if (index == 1) {
				this.fetchBillingPortal();
				return;
			}
			if (!this.activeTabs.find((i) => i == index)) {
				this.activeTabs.push(index);
			}
		},
		fetchBillingPortal() {
			this.isLoading = true;
			AppOrganizationBillingManager.createBillingPortal()
				.then((url) => {
					this.isLoading = false;
					if (url) {
						this.$helpers.openUrl(url);
					} else {
						this.showNotification({
							type: 'danger',
							message: `There was an issue with your account. Please try again later.`,
						});
					}
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
	},
	mounted() {},
};
</script>
