<template>
	<div v-if="organization">
		<b-row>
			<b-col>
				<Card>
					<CardHeader
						slot="header"
						:title="'Account Information'"
						:end-button="{ title: 'Update', disabled: !isUpdatedNeed }"
						@click="updateUserInformation"
					/>

					<BaseInput
						label="Organization Name"
						type="text"
						placeholder="Organization Name"
						name="organization name"
						disabled
						v-model="updatedData.orgName"
						required
					/>
					<BaseInput
						label="Organization Username"
						type="text"
						placeholder="Organization Username"
						name="organization username"
						disabled
						v-model="updatedData.orgUsername"
						required
					/>

					<div class="mb-3">
						<BaseInput
							label="Organization Bio"
							type="textarea"
							:withPadding="false"
							placeholder="Organization Bio"
							name="organization bio"
							v-model="updatedData.orgBio"
							required
							:maxLength="250"
							showMaxLabel
						/>
					</div>
					<hr class="my-3" />
					<h6 class="heading-small text-muted mb-2">Additional Information</h6>
					<b-row>
						<b-col>
							<BaseInput
								label="Members"
								type="text"
								placeholder="Members"
								name="members"
								disabled
								v-model="updatedData.orgMembers"
								required
							/>
						</b-col>
						<b-col>
							<BaseInput
								label="Followers"
								type="text"
								placeholder="Followers"
								name="followers"
								disabled
								v-model="updatedData.orgFollowers"
								required
							/>
						</b-col>
						<b-col>
							<BaseInput
								label="Connections (coming soon)"
								type="text"
								placeholder="Connections"
								name="connections"
								disabled
								v-model="updatedData.orgConnections"
								required
							/>
						</b-col>
					</b-row>
				</Card>
			</b-col>

			<b-col lg="5">
				<AllFeaturedButtonCard
					:all-buttons="allFeaturedButtons"
					@deleteButton="deleteResource"
					@click="addNewResource"
				/>
			</b-col>
		</b-row>
		<Card>
			<CardHeader
				slot="header"
				:title="'Locations'"
				:end-button="{ title: 'Update', disabled: !needLocationUpdate }"
				@click="addLocation"
			/>
			<div>
				<label class="form-control-label">Organization Location</label>
				<div v-for="(location, index) in allOrgLocations">
					<b-row class="justify-content-center align-items-center mb-3">
						<b-col>
							<BaseInput
								type="text"
								:withPadding="false"
								placeholder="Location Title"
								name="location title"
								disabled
								v-model="location.title"
							/>
						</b-col>
						<b-col>
							<BaseInput
								type="text"
								:withPadding="false"
								placeholder="Location Address"
								name="location address"
								disabled
								v-model="location.description"
							/>
						</b-col>
						<b-col v-if="!isLocationDefault(location)" cols="auto">
							<BaseButton
								class="m-0"
								size="sm"
								type="primary"
								@click="makeDefaultLocation(location, index)"
								>Make Default</BaseButton
							>
						</b-col>
						<b-col v-if="isLocationDefault(location)" cols="auto">
							<Badge>Default</Badge>
						</b-col>
						<b-col v-if="!isLocationDefault(location)" cols="auto">
							<BaseButton
								class="m-0"
								size="sm"
								type="danger"
								@click="removeLocation(location, index)"
								><i class="fa fa-times"></i
							></BaseButton>
						</b-col>
					</b-row>
				</div>
				<b-row>
					<b-col>
						<hr class="my-2" />
						<BaseButton class="mt-3" block outline @click="addLocation"
							>Add Location</BaseButton
						>
					</b-col>
				</b-row>
			</div>
		</Card>
		<NewLocationModal
			:show-modal="showLocationModal"
			@close="showLocationModal = false"
			@onNewLocation="onNewLocation"
		/>
		<NewResourceModal
			:show-modal="showResourceModal"
			:reset="showResourceModal == true"
			@close="showResourceModal = false"
			@onNewResource="onNewResource"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import BaseButton from '../../../components/BaseButton.vue';
import AppOrganizationManager from '../../../models/AppOrganization/AppOrganizationManager';
import AllFeaturedButtonCard from '../FeaturedViews/AllFeaturedButtonCard.vue';
import Badge from '../../../components/Badge.vue';
import NewLocationModal from './NewLocationModal.vue';
import NewResourceModal from './NewResourceModal.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
			publicInformation: 'getPublicInformation',
		}),
	},
	data() {
		return {
			isLoading: false,
			isUpdatedNeed: false,
			needLocationUpdate: false,
			showLocationModal: false,
			showResourceModal: false,
			allFeaturedButtons: [],
			allOrgLocations: [],
			defaultLocation: null,
			updatedData: {
				orgName: null,
				orgUsername: null,
				orgBio: null,
				orgMembers: 0,
				orgFollowers: 0,
				orgConnections: 0,
			},
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				return (this.isUpdatedNeed = this.checkIfUpdateNeeded());
			},
			deep: true,
		},
		allOrgLocations: {
			handler(val) {
				return (this.needLocationUpdate = this.checkIfLocationUpdateNeeded());
			},
			deep: true,
		},
	},
	methods: {
		getOrgButtons() {
			this.allFeaturedButtons = [];
			AppOrganizationManager.getOrganizationButtons().then((items) => {
				this.allFeaturedButtons = items.items;
			});
		},
		getOrgLocations() {
			AppOrganizationManager.getOrganizationLocations().then((items) => {
				this.allOrgLocations = items.items;
			});
		},
		isLocationDefault(location) {
			if (this.organization.orgLocation) {
				return location.locationId == this.organization.orgLocation.locationId;
			}
			return false;
		},
		addNewResource() {
			this.showResourceModal = true;
		},
		deleteResource(button) {
			this.allFeaturedButtons = this.allFeaturedButtons.filter(
				(e) => e.buttonId !== button.buttonId
			);
		},
		onNewLocation(locationData) {
			this.allOrgLocations.push(locationData);
		},
		onNewResource(resourceData) {
			this.getOrgButtons();
		},
		checkIfUpdateNeeded() {},
		checkIfLocationUpdateNeeded() {
			return false;
		},
		updateUserInformation() {},
		addLocation() {
			this.showLocationModal = true;
		},
		makeDefaultLocation(locationData) {
			AppOrganizationManager.updateDefaultLocation(locationData).then(() => {
				this.getOrgLocations();
			});
		},
		removeLocation(locationData, index) {
			AppOrganizationManager.removeOrganizationLocation(locationData.locationId)
				.then(() => {
					this.allOrgLocations = this.allOrgLocations.filter(
						(e) => e.locationId !== locationData.locationId
					);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		setupView() {
			this.getOrgButtons();
			this.getOrgLocations();
			this.defaultLocation = this.organization.orgLocation;
			this.updatedData = {
				orgName: this.organization.orgName,
				orgUsername: this.organization.orgUsername,
				orgBio: this.organization.orgBio,
				orgMembers: this.organization.orgMembers,
				orgFollowers: this.organization.orgFollowers,
				orgConnections: this.organization.orgConnections,
			};
		},
	},
	mounted() {
		this.setupView();
	},
	components: {
		Card,
		CardHeader,
		BaseInput,
		BaseButton,
		AllFeaturedButtonCard,
		Badge,
		NewLocationModal,
		NewResourceModal,
	},
};
</script>
