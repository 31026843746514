<template>
	<div>
		<Card>
			<CardHeader
				slot="header"
				:title="'Giving Options'"
				:end-button="{ title: 'Add New Option' }"
				@click="addNewGiving"
			></CardHeader>
			<b-overlay :show="isLoading" rounded="sm">
				<b-row>
					<b-col cols="4" v-for="item in allGivingData">
						<GivingCard :giving-data="item" @deleteClicked="askDeleteClicked" />
					</b-col>
				</b-row>
			</b-overlay>
		</Card>
		<NewGivingModal
			:show-modal="showModal"
			@close="showModal = false"
			@uploadData="uploadData"
		/>
		<QuestionModal
			:show="showQuestionModal"
			@yesClicked="deleteClicked"
			@close="showQuestionModal = false"
			:question="showQuestion"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import AppOrganizationManager from '../../../models/AppOrganization/AppOrganizationManager';
import GivingCard from './GivingCard.vue';
import NewGivingModal from './NewGivingModal.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';

export default {
	props: {
		refresh: false,
	},
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getGivingData();
				}
			},
		},
	},
	data() {
		return {
			isLoading: false,
			showModal: false,
			showQuestionModal: false,
			showQuestion: null,
			selectedGivingData: null,
			allGivingData: [],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getGivingData() {
			this.allGivingData = [];
			this.isLoading = true;
			AppOrganizationManager.getOrganizationGiving()
				.then((data) => {
					this.isLoading = false;
					this.allGivingData = data.items;
				})
				.catch((error) => {
					this.isLoading = false;
				});
		},
		askDeleteClicked(data) {
			this.selectedGivingData = data;
			this.showQuestion = 'Are you sure you want to delete this giving option?';
			this.showQuestionModal = true;
		},
		deleteClicked() {
			this.isLoading = true;
			AppOrganizationManager.removeOrganizationGiving(
				this.selectedGivingData.givingId
			)
				.then(() => {
					this.isLoading = false;
					this.getGivingData();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showError(errorMessage);
				});
		},
		uploadData(data) {
			const givingThumbnail = data.givingThumbnail;
			const givingData = data.givingData;
			this.isLoading = true;
			AppOrganizationManager.addOrganizationGiving(
				givingData,
				givingThumbnail,
				null
			)
				.then((givingData) => {
					this.isLoading = false;
					this.allGivingData.push(givingData);
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		showError(message) {
			this.showNotification({
				type: 'danger',
				message: message,
			});
		},
		addNewGiving() {
			this.showModal = true;
		},
		viewClicked(item) {
			console.log('selected giving: ', item);
		},
	},
	components: { GivingCard, Card, CardHeader, NewGivingModal, QuestionModal },
};
</script>
