import { resolve } from 'path';
import store from '../../store/store';
import { firestore, storage } from '../../resources/firebase';
import FirebaseManager from '../Firebase/FirebaseManager';
import {
	addDoc,
	collection,
	deleteDoc,
	doc,
	getDocs,
	query,
	setDoc,
	where,
} from 'firebase/firestore';
import helpers from '../../helpers/helpers';
import { ref } from 'firebase/storage';

export default class AppOrganizationManager {
	static getOrgGivingRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/giving`
		);
	}

	static getOrgButtonRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/featuredButtons`
		);
	}

	static getOrgLocationRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/locations`
		);
	}

	static getOrganizationGiving() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const givingRef = this.getOrgGivingRef(organization.orgId);
			getDocs(givingRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					const lastDocument = snapshot.docs.lastItem;
					var counter = 0;
					var items = [];
					if (counter == total) {
						items.sort((item1, item2) => item1.index - item2.index);
						return resolve({ items: items, lastCursor: lastDocument });
					}
					documents.forEach((document) => {
						var givingData = document.data();
						items.push(givingData);
						if (counter == total - 1) {
							items.sort((item1, item2) => item1.index - item2.index);
							return resolve({ items: items, lastCursor: lastDocument });
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getOrganizationButtons() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const buttonRef = this.getOrgButtonRef(organization.orgId);
			getDocs(buttonRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					const lastDocument = snapshot.docs.lastItem;
					var counter = 0;
					var items = [];
					if (counter == total) {
						return resolve({ items: items, lastCursor: lastDocument });
					}
					documents.forEach((document) => {
						var buttonData = document.data();
						items.push(buttonData);
						if (counter == total - 1) {
							return resolve({ items: items, lastCursor: lastDocument });
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getTempOrganization(token) {
		return new Promise((resolve, reject) => {
			const tempOrgs = FirebaseManager.tempOrganizationRef();
			const queryRef = query(tempOrgs, where('token', '==', token));
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						return resolve(snapshot.docs[0].data());
					} else {
						return reject('No Token');
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getOrganizationLocations() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const locationRef = this.getOrgLocationRef(organization.orgId);
			getDocs(locationRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					const lastDocument = snapshot.docs.lastItem;
					var counter = 0;
					var items = [];
					if (counter == total) {
						return resolve({ items: items, lastCursor: lastDocument });
					}
					documents.forEach((document) => {
						var locationData = document.data();
						items.push(locationData);
						if (counter == total - 1) {
							return resolve({ items: items, lastCursor: lastDocument });
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addOrganizationLocation(data, isDefault) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const user = store.state.user;
			const orgId = organization.orgId;
			const locationRef = this.getOrgLocationRef(orgId);
			const newRef = doc(locationRef);
			var locationData = data;
			locationData.locationId = newRef.id;
			locationData.orgId = orgId;
			locationData.created = FirebaseManager.timestamp();
			locationData.createdBy = user.uid;
			setDoc(newRef, locationData)
				.then(() => {
					if (isDefault) {
						this.updateDefaultLocation(locationData)
							.then(() => {
								return resolve(locationData);
							})
							.catch((error) => {
								return reject(error.message);
							});
					} else {
						return resolve(locationData);
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addOrganizationFeaturedButton(data) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const user = store.state.user;
			const orgId = organization.orgId;
			const locationRef = this.getOrgButtonRef(orgId);
			const newRef = doc(locationRef);
			var featuredData = data;
			featuredData.buttonId = newRef.id;
			featuredData.orgId = orgId;
			featuredData.created = FirebaseManager.timestamp();
			featuredData.createdBy = user.uid;
			setDoc(newRef, featuredData)
				.then(() => {
					return resolve(featuredData);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addOrganizationGiving(data, givingThumbnail) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const user = store.state.user;
			const orgId = organization.orgId;
			const newRef = doc(this.getOrgGivingRef(orgId));

			const imageStorageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/giving/${
					newRef.id
				}/image:${newRef.id}.jpeg`
			);

			if (givingThumbnail) {
				FirebaseManager.uploadImage(imageStorageRef, givingThumbnail, false).then(
					(thumbnailURL) => {
						FirebaseManager.createDynamicLink(
							newRef.id,
							null,
							FirebaseManager.DataType.givingItem,
							data.title,
							data.description,
							thumbnailURL
						).then((shareLink) => {
							var givingData = helpers.removeAllNullValues(data);
							givingData.imageItem = { imageUrl: thumbnailURL };
							givingData.shareLink = shareLink;
							givingData.givingId = newRef.id;
							givingData.orgId = orgId;
							givingData.created = FirebaseManager.timestamp();
							givingData.createdBy = user.uid;
							setDoc(newRef, givingData)
								.then(() => {
									return resolve(givingData);
								})
								.catch((error) => {
									return reject(error.message);
								});
						});
					}
				);
			} else {
				FirebaseManager.createDynamicLink(
					newRef.id,
					null,
					FirebaseManager.DataType.givingItem,
					data.title,
					data.description,
					null
				).then((shareLink) => {
					var givingData = helpers.removeAllNullValues(data);
					givingData.shareLink = shareLink;
					givingData.givingId = newRef.id;
					givingData.orgId = orgId;
					givingData.created = FirebaseManager.timestamp();
					givingData.createdBy = user.uid;
					setDoc(newRef, givingData)
						.then(() => {
							return resolve(givingData);
						})
						.catch((error) => {
							return reject(error.message);
						});
				});
			}
		});
	}

	static updateDefaultLocation(locationData) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const orgRef = doc(
				firestore,
				`${FirebaseManager.organizationRef().path}/${orgId}`
			);
			setDoc(orgRef, { orgLocation: locationData }, { merge: true })
				.then(() => {
					return resolve(locationData);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static removeOrganizationLocation(locationId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const locationRef = doc(
				firestore,
				`${this.getOrgLocationRef(orgId).path}/${locationId}`
			);
			deleteDoc(locationRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static removeOrganizationButton(buttonId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const buttonRef = doc(
				firestore,
				`${this.getOrgButtonRef(orgId).path}/${buttonId}`
			);
			deleteDoc(buttonRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static removeOrganizationGiving(givingId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const storageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/giving/${givingId}`
			);

			FirebaseManager.deleteStorageRef(storageRef).finally(() => {
				return this.completeDelete(givingId)
					.then(() => {
						return resolve();
					})
					.catch((errorMessage) => {
						return reject(errorMessage);
					});
			});
		});
	}

	static completeDelete(givingId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const givingRef = doc(
				firestore,
				`${FirebaseManager.organizationRef().path}/${orgId}/giving/${givingId}`
			);
			deleteDoc(givingRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
