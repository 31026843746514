import {
	collection,
	deleteField,
	doc,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
	updateDoc,
	writeBatch,
} from 'firebase/firestore';
import store from '../../store/store';
import FirebaseManager from '../Firebase/FirebaseManager';
import { auth, firestore } from '../../resources/firebase';
import helpers from '../../helpers/helpers';
import { sendPasswordResetEmail } from 'firebase/auth';

export default class AppUserManager {
	static limitFetch = 25;

	static returnDefaultProfilePicture() {
		return 'https://firebasestorage.googleapis.com/v0/b/mysunday-7eab9.appspot.com/o/MySunday%2Fno-image.png?alt=media&token=830c328b-08ad-40c9-ac65-a6081be9781b';
	}

	static returnDefaultAnonymous() {
		return 'https://firebasestorage.googleapis.com/v0/b/mysunday-7eab9.appspot.com/o/MySunday%2FdefaultAnonymousPicture.png?alt=media&token=7813aa8a-7f3b-4d7c-9ec0-282f288b0a65';
	}

	static isUserActive(userData) {
		if (userData.lastLoggedIn != null) {
			const today = new Date().getDate() - 30;
			const time30Before = new Date(new Date().setDate(today));
			const lastLoggedInDate =
				helpers.returnFirebaseDate(userData.lastLoggedIn) != null
					? helpers.returnFirebaseDate(userData.lastLoggedIn)
					: new Date();

			return time30Before.getTime() <= lastLoggedInDate.getTime();
		}
		return false;
	}

	static isAdminUser(userData) {
		if (userData.adminRoles) {
			const organization = store.state.organization;
			const adminRole = userData.adminRoles.find(
				(e) => e.orgId === organization.orgId
			);
			if (adminRole) {
				return true;
			}
		}
		return false;
	}

	static isUserFlagged(userData) {
		if (userData.isFlagged !== null) {
			return userData.isFlagged;
		}
		return false;
	}

	static getUsers(lastDocument, maxCount) {
		return new Promise((resolve, reject) => {
			const fetchCount =
				maxCount != null
					? maxCount > AppUserManager.limitFetch
						? maxCount
						: AppUserManager.limitFetch
					: AppUserManager.limitFetch;
			const organization = store.state.organization;
			const membersRef = collection(
				firestore,
				`${FirebaseManager.organizationRef().path}/${organization.orgId}/members`
			);
			var queryRef = query(
				membersRef,
				orderBy('created', 'desc'),
				limit(fetchCount)
			);
			if (lastDocument) {
				queryRef = query(
					membersRef,
					orderBy('created', 'desc'),
					startAfter(lastDocument),
					limit(fetchCount)
				);
			}
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument =
							total >= fetchCount ? documents[documents.length - 1] : null;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var memberData = document.data();
							if (memberData.uid) {
								this.getUserByUid(memberData.uid).then((userData) => {
									if (userData) {
										items.push(userData);
									}
									if (counter == total - 1) {
										return resolve([items, lastDocument]);
									}
									counter += 1;
								});
							} else {
								if (counter == total - 1) {
									return resolve([items, lastDocument]);
								}
								counter += 1;
							}
						});
					} else {
						return reject();
					}
				})
				.catch((error) => {
					console.error(error.message);
					return reject(error.message);
				});
		});
	}

	static getUserRelationships(uid) {
		return new Promise((resolve, reject) => {
			const ref = collection(
				firestore,
				`${FirebaseManager.usersRef().path}/${uid}/userRelationships`
			);
			getDocs(ref)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = snapshot.size;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var documentData = document.data();
							if (documentData.tempUid) {
								items.push(documentData);
								if (counter == total - 1) {
									return resolve(items);
								}
								counter += 1;
							} else {
								this.getUserByUid(documentData.uid)
									.then((userData) => {
										if (userData) {
											const updateData = {
												...documentData,
												...userData,
											};
											items.push(updateData);
										}
										if (counter == total - 1) {
											return resolve(items);
										}
										counter += 1;
									})
									.catch((error) => {
										if (counter == total - 1) {
											return resolve(items);
										}
										counter += 1;
									});
							}
						});
					} else {
						return reject();
					}
				})
				.catch((error) => {
					console.log(error.message);
					return reject();
				});
		});
	}

	static sendPasswordReset(email) {
		return new Promise((resolve, reject) => {
			sendPasswordResetEmail(auth, email)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					console.log(error.message);
					return resolve();
				});
		});
	}

	static adjustUserAdmin(userData, isAdmin) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const batch = writeBatch(firestore);
			const adminRef = doc(
				firestore,
				`${FirebaseManager.organizationRef().path}/${orgId}/admins/${userData.uid}`
			);
			const usersRef = doc(
				firestore,
				`${FirebaseManager.usersRef().path}/${userData.uid}`
			);

			var userAdminRoles = userData.adminRoles;
			if (isAdmin) {
				const updatedData = {
					orgId: orgId,
					type: 'owner',
				};
				userAdminRoles = userAdminRoles
					? userAdminRoles.push(updatedData)
					: [updatedData];
				batch.set(adminRef, {
					orgId: orgId,
					type: 'owner',
					created: FirebaseManager.timestamp(),
					uid: userData.uid,
				});
			} else {
				const updatedRoles = userAdminRoles.filter((e) => e.orgId !== orgId);
				userAdminRoles = updatedRoles.length !== 0 ? updatedRoles : deleteField();
				batch.delete(adminRef);
			}
			batch.set(usersRef, { adminRoles: userAdminRoles }, { merge: true });
			batch
				.commit()
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getUserByUid(uid) {
		return new Promise((resolve, reject) => {
			const usersRef = doc(firestore, `${FirebaseManager.usersRef().path}/${uid}`);
			getDoc(usersRef)
				.then((snapshot) => {
					if (snapshot.exists()) {
						return resolve(this.returnUserData(snapshot.data()));
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static returnUserData(data) {
		const userData = data;
		if (!userData.profilePhotoURL) {
			userData['profilePhotoURL'] = this.returnDefaultProfilePicture();
		}
		return userData;
	}

	static updateUserField(uid, fieldData) {
		return new Promise((resolve, reject) => {
			const usersRef = doc(firestore, `${FirebaseManager.usersRef().path}/${uid}`);
			setDoc(usersRef, fieldData, { merge: true })
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
