<template>
	<div v-if="allButtons">
		<Card>
			<CardHeader
				slot="header"
				:title="'Resources'"
				:end-button="getButtonTitle() ? { title: getButtonTitle() } : null"
				@click="resourceClicked"
			/>
			<CardFooter slot="footer" :title="'*Please limit these items to under 4'" />
			<b-overlay :show="isLoading" rounded="sm">
				<b-row class="justify-content-start">
					<b-col cols="4" v-for="featuredButton in allButtons">
						<FeaturedButtonItem
							:featured-button="featuredButton"
							@deleteClicked="deleteClicked"
						/>
					</b-col>
				</b-row>
			</b-overlay>
		</Card>
		<QuestionModal
			:show="showQuestionModal"
			:question="modalQuestion"
			@yesClicked="deleteFeaturedButton"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import AppOrganizationManager from '../../../models/AppOrganization/AppOrganizationManager';
import FeaturedButtonItem from './FeaturedButtonItem.vue';

export default {
	props: {
		allButtons: {
			type: Array,
			default: [],
		},
	},
	components: {
		Card,
		CardHeader,
		FeaturedButtonItem,
		CardFooter,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			showQuestionModal: false,
			modalQuestion: 'Do you want to delete this featured button?',
			featuredButton: null,
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getButtonTitle() {
			return this.allButtons.length >= 4 ? null : 'Add Resource';
		},
		resourceClicked() {
			this.$emit('click');
		},
		deleteClicked(featuredButton) {
			this.featuredButton = featuredButton;
			this.showQuestionModal = true;
		},
		deleteFeaturedButton() {
			this.isLoading = true;
			AppOrganizationManager.removeOrganizationButton(this.featuredButton.buttonId)
				.then(() => {
					this.isLoading = false;
					this.$emit('deleteButton', this.featuredButton);
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
	},
};
</script>
