<template>
	<div>
		<Card>
			<CardHeader
				slot="header"
				:title="headerLabel"
				v-if="headerLabel"
				:end-button="
					showUpdateButton ? { title: 'Update', disabled: !needsUpdate } : null
				"
				@click="onUpdate"
			/>
			<b-row>
				<b-col>
					<BaseInput
						:label="startDateLabel"
						:id="startDateLabel"
						:placeholder="startDateLabel"
						type="datetime-local"
						required
						v-model="updatedData.startDate"
					/>
				</b-col>
				<b-col>
					<label class="form-control-label">{{ durationLabel }}</label>
					<b-row>
						<b-col>
							<BaseInput
								id="hours"
								placeholder="Hours"
								type="mask"
								:mask="['# hours', '## hours']"
								required
								v-model="hoursHandle"
							/>
						</b-col>
						<b-col>
							<BaseInput
								id="minutes"
								placeholder="Minutes"
								type="mask"
								:mask="['# minutes', '## minutes']"
								required
								v-model="minutesHandle"
							/>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';

export default {
	props: {
		headerLabel: {
			type: String,
			default: 'Date and Time',
		},
		startDateLabel: {
			type: String,
			default: 'Start Date',
		},
		startDateValue: {
			type: Date,
			default() {
				return new Date();
			},
		},
		durationLabel: {
			type: String,
			default: 'Duration',
		},
		durationValue: {
			type: Number,
			default: 0,
		},
		showUpdateButton: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			needsUpdate: false,
			hoursHandle: 0,
			minutesHandle: 0,
			updatedData: {
				startDate: null,
				endDate: null,
				duration: 0,
			},
		};
	},
	watch: {
		hoursHandle: {
			handler(val) {
				return this.handleDuration();
			},
		},
		minutesHandle: {
			handler(val) {
				return this.handleDuration();
			},
		},
		updatedData: {
			handler(val) {
				this.needsUpdate = this.checkUpdate();
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		handleSeconds(totalSeconds) {
			this.hoursHandle = (totalSeconds / 3600).toFixed(0);
			this.minutesHandle = totalSeconds / 60 - this.hoursHandle * 60;
			this.updatedData.duration = this.durationValue;
		},
		handleDuration() {
			const hours = this.hoursHandle;
			const minutes = this.minutesHandle;
			const totalSeconds = hours * 3600 + minutes * 60;
			if (totalSeconds > 0) {
				this.updatedData.duration = totalSeconds;
			} else {
				this.updatedData.duration = 0;
				this.hoursHandle = 0;
				this.minutesHandle = 0;
			}
		},
		checkUpdate() {
			if (this.$helpers.isBlank(this.updatedData.startDate)) {
				return false;
			}
			if (this.$helpers.isBlank(this.updatedData.duration)) {
				return false;
			}
			if (
				new Date(this.updatedData.startDate).getTime() !==
				this.startDateValue.getTime()
			) {
				return true;
			} else if (this.updatedData.duration !== this.durationValue) {
				return true;
			}
			return false;
		},
		onUpdate() {
			this.$emit('onUpdate', this.updatedData);
			this.needsUpdate = false;
		},
		onChange() {
			this.$emit('onChange', this.updatedData);
		},
	},
	mounted() {
		if (this.durationValue) {
			this.handleSeconds(this.durationValue);
		}
		this.updatedData.startDate = this.$helpers.formatDate(
			this.startDateValue,
			"yyyy-MM-dd'T'HH:mm"
		);
	},
	components: { Card, CardHeader, BaseInput },
};
</script>
