<template>
	<BaseTable
		:headerTitle="headerTitle"
		:isLoading="isLoading"
		:filterOption="filterOption"
		:algoliaSearch="true"
		:tableHeaders="tableHeaders"
		:tableData="tableData"
		:recordsPerPage="getRecordsPerPage()"
		:total="total"
		@currentPage="currentPage"
		@viewClicked="viewClicked"
	/>
</template>
<script>
import AppUserManager from '../../models/AppUser/AppUserManager';
import Card from '../Cards/Card.vue';
import BaseTable from './BaseTable.vue';

export default {
	components: { Card, BaseTable },
	props: {
		isLoading: Boolean,
		headerTitle: {
			type: String,
			default: null,
		},
		filterOption: {
			type: Boolean,
			default: true,
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
		total: {
			type: Number,
			description: 'Total Number of data for table',
		},
	},
	data() {
		return {
			recordsPerPage: 0,
			tableHeaders: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return {
							name: `${item.firstName} ${item.lastName}`,
							photo: item.profilePhotoURL,
							isAdmin: AppUserManager.isAdminUser(item),
							isFlagged: AppUserManager.isUserFlagged(item),
						};
					},
					sortByFormatted: (value, key, item) => {
						return `${item.firstName} ${item.lastName}`;
					},
				},
				{
					key: 'username',
					label: 'Username',
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return `@${value.toLowerCase()}`;
					},
				},
				{
					key: 'email',
					label: 'Email',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return value.toLowerCase();
					},
				},
				{
					key: 'isActive',
					label: 'Active',
					thStyle: { width: '20%' },
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return AppUserManager.isUserActive(item);
					},
					sortByFormatted: (value, key, item) => {
						return AppUserManager.isUserActive(item);
					},
				},
			],
		};
	},
	methods: {
		getRecordsPerPage() {
			return AppUserManager.limitFetch;
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		currentPage(index) {
			this.$emit('currentPage', index);
		},
	},
};
</script>
