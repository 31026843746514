<template>
	<div>
		<BaseTable
			:isLoading="isLoading"
			:isFixed="false"
			:filterOption="groupsData.length > 0"
			:algoliaSearch="false"
			:tableHeaders="tableHeaders"
			:tableData="groupsData"
			:total="groupsData.length"
			@currentPage="currentPage"
			@viewClicked="viewClicked"
		/>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseTable from '../../../components/Tables/BaseTable.vue';

export default {
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		groupsData: {
			type: Array,
			default: [],
		},
	},
	components: { Card, BaseTable },
	data() {
		return {
			tableHeaders: [
				{
					key: 'namePhotoRatio',
					label: 'Title',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.groupTitle;
					},
					formatter: (value, key, item) => {
						return {
							name: item.groupTitle,
							photo: item.imageItem ? item.imageItem[0].imageUrl : null,
							ar: '16:9',
						};
					},
				},
				{
					key: 'groupUsers',
					label: 'Members',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						const groupMaxUsers =
							item.groupMaxUsers != null ? `${item.groupMaxUsers}` : `Unlimited`;
						return `${value != null ? value : '0'} / ${groupMaxUsers}`;
					},
				},
				{
					key: 'groupPendingUsers',
					label: 'Pending',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						const pendingUsers =
							item.groupPendingUsers != null ? `${item.groupPendingUsers}` : `0`;
						return `${pendingUsers}`;
					},
				},
				{
					key: 'isPrivate',
					label: 'Privacy',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						const isPrivate = item.autoApprove != null ? item.autoApprove : false;
						return isPrivate;
					},
				},
			],
		};
	},
	methods: {
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		currentPage(index) {
			this.$emit('currentPage', index);
		},
	},
};
</script>
