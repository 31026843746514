<template>
	<div :class="{ [`bg-alternative`]: type }">
		<b-container fluid class="my-2">
			<slot></slot>
		</b-container>
	</div>
</template>
<script>
export default {
	name: 'base-content',
	props: {
		type: {
			type: String,
			default: 'success',
			description: 'Content background type',
		},
	},
};
</script>
<style></style>
