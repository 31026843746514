<template>
	<div>
		<div class="d-flex align-items-center justify-content-start">
			<Badge type="secondary" circle class="mr-2" :icon="icon"></Badge>
			<div>
				<h6 class="mb-0 font-weight-bold">
					{{ titleLabel }}
				</h6>
				<h6 class="mb-0 font-weight-normal">
					{{ bottomLabel }}
				</h6>
			</div>
		</div>
	</div>
</template>
<script>
import Badge from './Badge.vue';

export default {
	props: {
		icon: {
			type: String,
			default: null,
		},
		titleLabel: {
			type: String,
			default: null,
		},
		bottomLabel: {
			type: String,
			default: null,
		},
	},
	components: { Badge },
};
</script>
