import {
	collection,
	deleteDoc,
	doc,
	getCountFromServer,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
} from 'firebase/firestore';
import { firestore, storage } from '../../resources/firebase';
import store from '../../store/store';
import FirebaseManager from '../Firebase/FirebaseManager';
import FileItemManager from '../FileItem/FileItemManager';
import mimiDB from 'mime-db';
import helpers from '../../helpers/helpers';
import { ref } from 'firebase/storage';
import { PricingInformation } from '../../resources/PricingInformation';

export default class VideoItemManager {
	static limitFetch = 15;

	static getOrgVideosRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/videos`
		);
	}

	static canUpload() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const ref = this.getOrgVideosRef(organization.orgId);
			getCountFromServer(ref)
				.then((snapshot) => {
					const planInfo = PricingInformation.find(
						(e) => e.billingType == organization.billingType
					);
					const maxAllowed = planInfo.videos.count;
					return resolve(
						maxAllowed == null ? true : snapshot.data().count < maxAllowed
					);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getOrgVideos(lastDocument, maxCount) {
		return new Promise((resolve, reject) => {
			const fetchCount =
				maxCount != null
					? maxCount > FileItemManager.limitFetch
						? maxCount
						: FileItemManager.limitFetch
					: FileItemManager.limitFetch;
			const organization = store.state.organization;
			const videoRef = this.getOrgVideosRef(organization.orgId);
			var queryRef = query(
				videoRef,
				orderBy('created', 'desc'),
				limit(fetchCount)
			);
			if (lastDocument) {
				queryRef = query(
					videoRef,
					orderBy('created', 'desc'),
					startAfter(lastDocument),
					limit(fetchCount)
				);
			}

			getDocs(queryRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					const lastDocument = snapshot.docs.lastItem;
					var counter = 0;
					var items = [];
					if (counter == total) {
						return resolve({ items: items, lastCursor: lastDocument });
					}
					documents.forEach((document) => {
						var videoData = document.data();
						items.push(videoData);
						if (counter == total - 1) {
							return resolve({ items: items, lastCursor: lastDocument });
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addVideoToDB(
		data,
		file,
		fileThumbnail,
		fileName,
		fileType,
		videoExternalLink,
		loadingCallback
	) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const user = store.state.user;
			const orgId = organization.orgId;
			const newRef = doc(this.getOrgVideosRef(orgId));
			const cleanFileName = fileName.replace(/\s+/g, '');
			const imageStorageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/videos/${
					newRef.id
				}/image:${cleanFileName}.jpeg`
			);
			const storageRef = fileType
				? ref(
						storage,
						`${FirebaseManager.organizationRef().path}/${orgId}/videos/${
							newRef.id
						}/video:${cleanFileName}.${mimiDB[fileType].extensions[0]}`
				  )
				: null;

			FirebaseManager.uploadImage(imageStorageRef, fileThumbnail, false)
				.then((thumbnailURL) => {
					if (videoExternalLink) {
						FirebaseManager.createDynamicLink(
							newRef.id,
							null,
							FirebaseManager.DataType.videoItem,
							data.videoTitle,
							data.videoDescription,
							thumbnailURL
						).then((shareLink) => {
							var videoData = helpers.removeAllNullValues(data);
							videoData.shareLink = shareLink;
							videoData.imageItem = [{ imageUrl: thumbnailURL }];
							videoData.videoExternalLink = videoExternalLink;
							videoData.videoId = newRef.id;
							videoData.orgId = orgId;
							videoData.created = FirebaseManager.timestamp();
							videoData.createdBy = user.uid;
							setDoc(newRef, videoData)
								.then(() => {
									return resolve(videoData);
								})
								.catch((error) => {
									return reject(error.message);
								});
						});
					} else {
						FirebaseManager.uploadFile(storageRef, file, fileType, (progress) => {
							loadingCallback(progress);
						})
							.then((downloadURL) => {
								FirebaseManager.createDynamicLink(
									newRef.id,
									null,
									FirebaseManager.DataType.videoItem,
									data.videoTitle,
									data.videoDescription,
									thumbnailURL
								).then((shareLink) => {
									var videoData = helpers.removeAllNullValues(data);
									videoData.shareLink = shareLink;
									videoData.imageItem = [{ imageUrl: thumbnailURL }];
									videoData.videoLink = downloadURL;
									videoData.videoId = newRef.id;
									videoData.orgId = orgId;
									videoData.created = FirebaseManager.timestamp();
									videoData.createdBy = user.uid;
									setDoc(newRef, videoData)
										.then(() => {
											return resolve(videoData);
										})
										.catch((error) => {
											return reject(error.message);
										});
								});
							})
							.catch((error) => {
								return reject(error.message);
							});
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static deleteVideoItem(videoId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const storageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/videos/${videoId}`
			);

			FirebaseManager.deleteStorageRef(storageRef).finally(() => {
				return this.completeDelete(videoId)
					.then(() => {
						return resolve();
					})
					.catch((errorMessage) => {
						return reject(errorMessage);
					});
			});
		});
	}

	static completeDelete(videoId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const videoRef = doc(
				firestore,
				`${FirebaseManager.organizationRef().path}/${orgId}/videos/${videoId}`
			);
			deleteDoc(videoRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
