<template>
	<Card no-body class="p-3">
		<div class="d-flex align-items-center justify-content-between">
			<b-icon
				:class="[`bg-${color}`, `${isRounded ? 'rounded-circle' : ''}`]"
				:icon="icon"
				font-scale="3"
				:variant="variant"
			></b-icon>
			<div class="text-right">
				<h5 class="mb-0">{{ valueTitle }}</h5>
				<h2>{{ value }}</h2>
			</div>
		</div>
		<b-row>
			<b-col> </b-col>
			<b-col> </b-col>
		</b-row>
		<hr class="my-2" />
		<div class="d-flex text-right w-100">
			<h6 class="mb-0 text-right"><i class="fa fa-sync"></i>{{ footerTitle }}</h6>
		</div>
	</Card>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';

export default {
	props: {
		icon: String,
		color: String,
		valueTitle: String,
		value: {
			type: [String, Number],
		},
		footerTitle: String,
		variant: {
			type: String,
			default: 'white',
		},
		isRounded: {
			type: Boolean,
			default: true,
		},
	},
	components: { Card },
};
</script>
