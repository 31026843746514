<template>
	<Card no-body>
		<CardHeader
			slot="header"
			:title="titleLabel"
			:tooltip="titleLabelTooltip"
			:end-switch="{}"
			:end-switch-value="updatedData.isPrivate"
			@change="privacySwitchChanged"
		/>
	</Card>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';

export default {
	props: {
		titleLabel: {
			type: String,
			default: 'Privacy',
		},
		titleLabelTooltip: {
			type: String,
			default:
				'Decide if other users can interact with this item outside your organization.',
		},
		privacyValue: {
			type: Boolean,
			default: false,
		},
	},
	components: { Card, CardHeader },
	data() {
		return {
			updatedData: {
				isPrivate: false,
			},
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		privacySwitchChanged(value) {
			this.updatedData.isPrivate = value;
			if (this.updatedData.isPrivate != this.privacyValue) {
				this.onUpdate();
			}
		},
		onUpdate() {
			this.$emit('onUpdate', this.updatedData);
		},
		onChange() {
			this.$emit('onChange', this.updatedData);
		},
	},
	mounted() {
		this.updatedData.isPrivate = this.privacyValue;
	},
};
</script>
