<template>
	<div>
		<BaseTable
			:isLoading="isLoading"
			:isFixed="false"
			:filterOption="videosData.length > 0"
			:algoliaSearch="false"
			:tableHeaders="tableHeaders"
			:tableData="videosData"
			:total="videosData.length"
			@currentPage="currentPage"
			@viewClicked="viewClicked"
			@actionDropdownClicked="actionDropdownClicked"
		/>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseTable from '../../../components/Tables/BaseTable.vue';

export default {
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		videosData: {
			type: Array,
			default: [],
		},
	},
	components: { Card, BaseTable },
	data() {
		return {
			tableHeaders: [
				{
					key: 'namePhotoRatio',
					label: 'Title',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.videoTitle;
					},
					formatter: (value, key, item) => {
						return {
							name: item.videoTitle,
							photo: item.imageItem ? item.imageItem[0].imageUrl : null,
							ar: '16:9',
						};
					},
				},
				{
					key: 'actions',
					label: '',
					thStyle: { width: '5%' },
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return [
							{
								title: 'View Video',
								click: 'viewClicked',
							},
							{
								isDivider: true,
							},
							{
								title: 'Delete Video',
								click: 'deleteClicked',
								variant: 'danger',
							},
						];
					},
				},
			],
		};
	},
	methods: {
		actionDropdownClicked(item) {
			const click = item.item.click;
			const videoItem = item.row;
			if (click == 'viewClicked') {
				this.viewClicked(videoItem);
			} else if (click == 'deleteClicked') {
				this.deleteClicked(videoItem);
			}
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		currentPage(index) {
			this.$emit('currentPage', index);
		},
	},
};
</script>
