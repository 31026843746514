<template>
	<div>
		<BaseTable
			:headerTitle="headerTitle"
			:isLoading="isLoading"
			:isFixed="false"
			:filterOption="eventData.length > 0"
			:algoliaSearch="false"
			:tableHeaders="isUserTable ? userTableHeaders : tableHeaders"
			:tableData="eventData"
			:total="eventData.length"
			@currentPage="currentPage"
			@actionDropdownClicked="actionDropdownClicked"
			@viewClicked="viewClicked"
		/>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseTable from '../../../components/Tables/BaseTable.vue';

export default {
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		isUserTable: {
			type: Boolean,
			default: false,
		},
		eventData: {
			type: Array,
			default: [],
		},
		headerTitle: {
			type: String,
			default: null,
		},
	},
	components: { Card, BaseTable },
	data() {
		return {
			userTableHeaders: [
				{
					key: 'namePhotoRatio',
					label: 'Title',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.eventData.eventTitle;
					},
					formatter: (value, key, item) => {
						return {
							name: item.eventData.eventTitle,
							photo: item.eventData.imageItem
								? item.eventData.imageItem[0].imageUrl
								: null,
							ar: '16:9',
						};
					},
				},
				{
					key: 'eventStart',
					label: 'Start Date',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return this.$helpers.formatDate(
							item.eventData.eventStart.toDate(),
							"MMMM dd, yyyy 'at' hh:mm a"
						);
					},
				},
				{
					key: 'quantity',
					label: 'Quantity',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return `${item.userData.quantity}`;
					},
				},
			],
			tableHeaders: [
				{
					key: 'namePhotoRatio',
					label: 'Title',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.eventTitle;
					},
					formatter: (value, key, item) => {
						return {
							name: item.eventTitle,
							photo: item.imageItem ? item.imageItem[0].imageUrl : null,
							ar: '16:9',
						};
					},
				},
				{
					key: 'eventStart',
					label: 'Start Date',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return this.$helpers.formatDate(
							value.toDate(),
							"MMMM dd, yyyy 'at' hh:mm a"
						);
					},
				},
				{
					key: 'eventUsers',
					label: 'Attendance',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						const eventMaxUsers =
							item.eventMaxUsers != null ? `${item.eventMaxUsers}` : `Unlimited`;
						return `${value != null ? value : '0'} / ${eventMaxUsers}`;
					},
				},
				{
					key: 'badgeView',
					label: 'Status',
					sortable: true,
					thStyle: { width: '10%' },
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						const isCancelled = item.isCancelled != null ? item.isCancelled : false;
						const isPrevious = item.eventStart.toDate() < new Date();
						return {
							type: isPrevious ? 'warning' : isCancelled ? 'danger' : 'success',
							name: isPrevious ? 'Expired' : isCancelled ? 'Cancelled' : 'On Time',
						};
					},
				},
				{
					key: 'actions',
					label: '',
					thStyle: { width: '5%' },
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return [
							{
								title: 'View Event',
								click: 'viewClicked',
							},
							{
								isDivider: true,
							},
							{
								title:
									item.isCancelled != null
										? item.isCancelled
											? 'Re-Open Event'
											: 'Cancel Event'
										: 'Cancel Event',
								click: 'cancelClicked',
								variant: 'danger',
							},
						];
					},
				},
			],
		};
	},
	methods: {
		actionDropdownClicked(item) {
			const click = item.item.click;
			const eventItem = item.row;
			if (click == 'viewClicked') {
				this.viewClicked(eventItem);
			} else if (click == 'cancelClicked') {
				this.cancelClicked(eventItem);
			}
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		cancelClicked(item) {
			this.$emit('cancelClicked', item);
		},
		currentPage(index) {
			this.$emit('currentPage', index);
		},
	},
};
</script>
