<template>
	<div>
		<div v-if="showError">Show Error</div>
		<div v-else>
			<b-container>
				<b-row class="my-5 py-5 justify-content-center">
					<b-col cols="11" md="6">
						<b-img class="px-lg-8 my-3" fluid src="/img/AppLogoHorizontalWTEXT.png" />
						<Card class="shadow"
							><b-overlay :show="isLoading">
								<div class="d-flex align-items-center">
									<BaseButton
										:style="{ opacity: currentSlide == 0 ? 0 : 1 }"
										:disabled="currentSlide == 0"
										size="sm"
										icon="fa fa-chevron-left"
										type="dark"
										@click="prevClicked"
									></BaseButton>
									<div class="w-100 text-center">
										<h2 class="m-0">
											{{
												currentSlide == 0
													? `Welcome ${tempOrganization.firstName}!`
													: `Setup Organziation`
											}}
										</h2>
										<div v-if="errorMessage" class="my-2 text-center">
											<p style="color: red; font-size: 12px">{{ errorMessage }}</p>
										</div>
									</div>
									<BaseButton
										style="opacity: 0"
										disabled
										size="sm"
										icon="fa fa-chevron-left"
										type=""
										@click="prevClicked"
									></BaseButton>
								</div>

								<hr class="my-3" />
								<b-carousel
									ref="signupCarousel"
									class="px-0 py-0"
									style="width: 100%"
									v-model="currentSlide"
									no-touch
									no-wrap
									:interval="0"
								>
									<b-carousel-slide>
										<template #img>
											<TempOrgUserCard
												:temp-organization="tempOrganization"
												@userData="updateUserData"
											/>
										</template>
									</b-carousel-slide>
									<b-carousel-slide>
										<template #img>
											<TempOrgAccountCard
												:temp-organization="tempOrganization"
												@orgData="updateOrgData"
											/>
										</template>
									</b-carousel-slide>
								</b-carousel>
								<BaseButton block type="primary" @click="nextClicked">
									{{ currentSlide == 0 ? 'Complete Profile' : 'Activate Account' }}
								</BaseButton>
							</b-overlay>
						</Card>
						<p class="small text-center text-muted">
							By completing and activating your account, you agree to the MySunday
							<a href="/terms-of-use" target="_blank">Terms of Service </a> and
							<a href="/privacy-policy" target="_blank">Privacy Policy</a>.
						</p>
					</b-col>
				</b-row>
			</b-container>
		</div>
	</div>
</template>
<script>
import BaseInput from '../../components/Inputs/BaseInput.vue';
import BaseButton from '../../components/BaseButton.vue';
import Card from '../../components/Cards/Card.vue';
import AppOrganizationManager from '../../models/AppOrganization/AppOrganizationManager';
import TempOrgUserCard from '../SecondaryComponents/OrgViews/TempOrgUserCard.vue';
import TempOrgAccountCard from '../SecondaryComponents/OrgViews/TempOrgAccountCard.vue';
export default {
	props: {
		token: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			showError: false,
			errorMessage: null,
			tempOrganization: null,
			currentSlide: 0,
			orgData: null,
			userData: null,
		};
	},
	watch: {
		orgData: {
			handler(val) {
				return (this.errorMessage = null);
			},
			deep: true,
		},
		userData: {
			handler(val) {
				return (this.errorMessage = null);
			},
			deep: true,
		},
	},
	methods: {
		fetchTempOrganization() {
			this.isLoading = true;
			AppOrganizationManager.getTempOrganization(this.token)
				.then((data) => {
					this.isLoading = false;
					this.tempOrganization = data;
				})
				.catch(() => {
					this.isLoading = false;
					this.showError = true;
				});
		},
		nextClicked() {
			if (this.currentSlide == 0 && this.isValidSlide1()) {
				this.$refs.signupCarousel.next();
				return;
			} else if (this.currentSlide == 1 && this.isValidSlide2()) {
				this.submit();
				return;
			} else {
				return;
			}
		},
		prevClicked() {
			this.$refs.signupCarousel.prev();
		},
		updateUserData(data) {
			this.userData = data;
		},
		updateOrgData(data) {
			this.orgData = data;
		},
		isValidSlide1() {
			if (this.userData) {
				const firstName = this.userData.firstName;
				const lastName = this.userData.lastName;
				const password = this.userData.password;
				const confirmPassword = this.userData.confirmPassword;
				if (this.isEmpty(firstName) || this.isEmpty(lastName)) {
					this.errorMessage = 'Please enter your first and last name.';
					return false;
				} else if (this.isEmpty(password) || this.isEmpty(confirmPassword)) {
					this.errorMessage = 'Please complete all required fields.';
					return false;
				} else if (password.length <= 5) {
					this.errorMessage = 'Password too weak. Please try again.';
					return false;
				} else if (password !== confirmPassword) {
					this.errorMessage = 'Password does not match. Please try again.';
					return false;
				} else {
					return true;
				}
			}
			return false;
		},
		isValidSlide2() {
			if (this.orgData) {
				const orgName = this.orgData.orgName;
				const orgUsername = this.orgData.orgUsername;
				const orgLogo = this.orgData.orgLogo;
				const orgBio = this.orgData.orgBio;

				if (this.isEmpty(orgLogo)) {
					this.errorMessage = "Please add your organization's logo.";
					return false;
				} else if (this.isEmpty(orgName)) {
					this.errorMessage = "Please enter your organization's name.";
					return false;
				} else if (this.isEmpty(orgUsername)) {
					this.errorMessage = "Please enter your organization's username.";
					return false;
				} else if (!orgUsername.match('^\\w{7,20}$')) {
					this.errorMessage =
						'Please enter a username between 7-20 characters and letters (a-z), numbers, underscores, and no spaces.';
					return false;
				} else if (this.isEmpty(orgBio)) {
					this.errorMessage = "Please add your organization's bio.";
					return false;
				} else {
					return true;
				}
			}
			return false;
		},
		submit() {
			this.isLoading = true;
			this.errorMessage = null;
		},
		isEmpty(value) {
			if (value == null || value.length <= 2) {
				return true;
			}
			return false;
		},
	},
	mounted() {
		if (this.token) {
			this.fetchTempOrganization();
		} else {
			this.isLoading = false;
			this.showError = true;
		}
	},
	components: {
		BaseInput,
		BaseButton,
		Card,
		TempOrgUserCard,
		TempOrgAccountCard,
	},
};
</script>
