const getters = {
	getUser(state) {
		return state.user;
	},
	getOrganization(state) {
		return state.organization;
	},
	getNotification(state) {
		return state.notification;
	},
	getLastLoggedIn(state) {
		return state.lastLoggedIn;
	},
};
export default getters;
