<template>
	<div>
		<div v-if="tempOrganization">
			<b-row>
				<b-col>
					<BaseInput
						label="First Name"
						placeholder="First Name"
						required
						v-model="userData.firstName"
						name="name"
					></BaseInput>
				</b-col>
				<b-col>
					<BaseInput
						label="Last Name"
						placeholder="Last Name"
						required
						v-model="userData.lastName"
						name="name"
					></BaseInput>
				</b-col>
			</b-row>
			<BaseInput
				type="password"
				label="Enter password"
				placeholder="Enter password"
				required
				v-model.trim="userData.password"
				name="password"
			></BaseInput>
			<BaseInput
				type="password"
				label="Confirm password"
				placeholder="Confirm password"
				required
				v-model.trim="userData.confirmPassword"
				name="password"
			></BaseInput>
		</div>
	</div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';

export default {
	props: {
		tempOrganization: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			userData: {
				firstName: null,
				lastName: null,
				password: null,
				confirmPassword: null,
			},
		};
	},
	watch: {
		userData: {
			handler(val) {
				return this.$emit('userData', val);
			},
			deep: true,
		},
	},
	mounted() {
		if (this.tempOrganization) {
			this.userData.firstName = this.tempOrganization.firstName;
			this.userData.lastName = this.tempOrganization.lastName;
		}
	},
	components: { BaseButton, BaseInput },
};
</script>
