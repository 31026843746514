<template>
    <div>
        <DashboardHeader
			:title="$route.name"
		/>
		<BaseContent>
            <b-row class="align-items-right justify-content-right">
                <b-col>
                    something
                </b-col>
                <b-col class="text-right">
                    <BaseButton size="sm" @click="exportTemplate">Export Html</BaseButton>
                </b-col>
            </b-row>
            <Card no-body>
                <EmailEditor style="height: 100vh;" ref="emailEditor" v-on:load="editorLoaded" v-on:ready="editorReady"></EmailEditor>
            </Card>
        
        </BaseContent>
    </div>
</template>
<script>
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import BaseContent from '../../../components/BaseContent.vue';
import BaseButton from '../../../components/BaseButton'
import Card from '../../../components/Cards/Card'
import EmailEditor from '../../../components/EmailEditor/EmailEditor.vue';
export default {
    methods: {
        exportTemplate(){
            this.$refs.emailEditor.editor.exportHtml(
        (data) => {
          console.log('exportHtml', data);
        }
      )
        }
    },
    components: { DashboardHeader,BaseContent,BaseButton,Card,EmailEditor }
}
</script>