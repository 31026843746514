<template>
	<div>
		<DashboardHeader
			:title="this.$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<BaseContent>
			<b-overlay :show="isLoading" rounded="sm">
				<Card no-body>
					<b-tabs
						active-nav-item-class="bg-white font-weight-bold description"
						nav-class="bg-white description"
						card
						no-body
					>
						<b-tab title="Event Details" active>
							<TitleDescriptionView
								:header-label="'General Information'"
								:title-label="'Event Title'"
								:description-label="'Event Description'"
								@onChange="onTitleDescriptionChange"
							/>
							<DateTimeView @onChange="onDateTimeChange" />
							<LocationView @onChange="onLocationChange" />
						</b-tab>
						<b-tab title="Registration">
							<PriceView @onChange="onPriceChange" />
							<RegistrationAttendanceView @onChange="onAttendanceChange" />
							<RegistrationButtonView
								:defaultButtonFooterLabel="'By adding a button link, you will not be able to accept registration for this event.'"
								@onChange="onRegistrationChange"
							/>
						</b-tab>
						<b-tab title="Additional Settings">
							<AgeLimitView @onChange="onAgeChange" />
							<PrivacyView
								:title-label="'Private Event'"
								@onChange="onPrivacyChange"
							/>
							<PrivacyView
								:title-label="'Member Event'"
								:title-label-tooltip="'Decide if this item can been seen by users outside of the MySunday.'"
								@onChange="onMemberPrivacyChange"
							/>
						</b-tab>
					</b-tabs>
				</Card>
			</b-overlay>
		</BaseContent>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseContent from '../../../components/BaseContent.vue';
import Card from '../../../components/Cards/Card.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import { DashboardChildren } from '../../../routes/routesNames';
import EventItemManager from '../../../models/EventItem/EventItemManager';
import TitleDescriptionView from '../../SecondaryComponents/BaseNewViews/TitleDescriptionView.vue';
import DateTimeView from '../../SecondaryComponents/BaseNewViews/DateTimeView.vue';
import LocationView from '../../SecondaryComponents/BaseNewViews/LocationView.vue';
import RegistrationButtonView from '../../SecondaryComponents/BaseNewViews/RegistrationButtonView.vue';
import PriceView from '../../SecondaryComponents/BaseNewViews/PriceView.vue';
import AgeLimitView from '../../SecondaryComponents/BaseNewViews/AgeLimitView.vue';
import RegistrationAttendanceView from '../../SecondaryComponents/BaseNewViews/RegistrationAttendanceView.vue';
import PrivacyView from '../../SecondaryComponents/BaseNewViews/PrivacyView.vue';

export default {
	components: {
		DashboardHeader,
		BaseContent,
		Card,
		TitleDescriptionView,
		DateTimeView,
		LocationView,
		RegistrationButtonView,
		PriceView,
		AgeLimitView,
		RegistrationAttendanceView,
		PrivacyView,
	},
	computed: {
		...mapGetters({
			appInformation: 'getAppInformation',
		}),
	},
	data() {
		return {
			isLoading: false,
			buttonItems: [
				{
					index: 0,
					title: 'Save & Publish',
					type: 'primary',
				},
			],
			selectedImage: null,
			isAgeLimit: false,
			isCustomDefaultButton: false,
			eventData: {
				eventTitle: null,
				eventDescription: null,
				eventStart: null,
				eventEnd: null,
				eventDuration: null,
				eventMaxUsers: null,
				eventButtonTitle: null,
				eventButtonLink: null,
				location: {
					title: null,
					description: null,
				},
				price: {
					amount: 0,
					quantity: null,
				},
				ageItem: {
					startAge: 0,
					endAge: null,
					enforced: false,
				},
				isPrivate: false,
				isMembersOnly: false,
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
		onTitleDescriptionChange(updatedData) {
			const title = updatedData.title;
			const description = updatedData.description;
			const image = updatedData.image;
			this.selectedImage = image;
			this.eventData.eventTitle = title;
			this.eventData.eventDescription = description;
		},

		onDateTimeChange(updatedData) {
			const startDate = updatedData.startDate; //date ref
			const endDate = updatedData.endDate; //could be null
			const duration = updatedData.duration; //seconds
			this.eventData.eventStart = startDate != null ? new Date(startDate) : null;
			this.eventData.eventEnd = endDate != null ? new Date(endDate) : null;
			this.eventData.eventDuration = duration;
		},
		onLocationChange(updatedData) {
			const title = updatedData.title;
			const description = updatedData.description;
			this.eventData.location.title = title;
			this.eventData.location.description = description;
		},
		onPriceChange(updatedData) {
			const isFree = updatedData.isFree;
			const price = parseInt(updatedData.price * 100);
			if (isFree) {
				this.eventData.price = null;
			} else {
				this.eventData.price = {
					amount: price,
					quantity: this.eventData.eventMaxUsers,
				};
			}
		},
		onAttendanceChange(updatedData) {
			const maxUsers =
				updatedData.maxUsers != null ? parseInt(updatedData.maxUsers) : null;
			this.eventData.eventMaxUsers = maxUsers;
		},
		onRegistrationChange(updatedData) {
			const isCustomDefaultButton = updatedData.isCustomDefaultButton;
			const defaultButtonTitle = updatedData.defaultButtonTitle;
			const defaultButtonLink = updatedData.defaultButtonLink;
			if (isCustomDefaultButton) {
				this.eventData.eventButtonLink = defaultButtonLink;
				this.eventData.eventButtonTitle = defaultButtonTitle;
			} else {
				this.eventData.eventButtonLink = null;
				this.eventData.eventButtonTitle = null;
			}
			this.isCustomDefaultButton = isCustomDefaultButton;
		},
		onAgeChange(updatedData) {
			const isAgeLimit = updatedData.isAgeLimit;
			const startAge =
				updatedData.startAge != null ? parseInt(updatedData.startAge) : null;
			const endAge =
				updatedData.endAge != null ? parseInt(updatedData.endAge) : null;
			const ageEnforced = updatedData.ageEnforced;
			if (isAgeLimit) {
				if (startAge == 0 && endAge == null) {
					this.eventData.ageItem = null;
				} else {
					this.eventData.ageItem = {
						startAge: startAge,
						endAge: endAge,
						enforced: ageEnforced,
					};
				}
			} else {
				this.eventData.ageItem = null;
			}
			this.isAgeLimit = isAgeLimit;
		},
		onPrivacyChange(updatedData) {
			const isPrivate = updatedData.isPrivate;
			this.eventData.isPrivate = isPrivate;
		},
		onMemberPrivacyChange(updatedData) {
			const isMembersOnly = updatedData.isPrivate;
			this.eventData.isMembersOnly = isMembersOnly;
		},
		onSave() {
			if (!this.checkTitleDescription()) {
				this.showError('Please enter a title and description.');
				return;
			} else if (!this.checkImage()) {
				this.showError('Please add an event image.');
				return;
			} else if (!this.checkDate()) {
				this.showError('Please select a start time and duration.');
				return;
			} else if (!this.checkRegistrationPrice()) {
				this.showError("Please enter an event price or mark this event as 'Free'.");
				return;
			} else if (!this.checkRegistrationButton()) {
				this.showError('Please add a custom button title and valid link.');
				return;
			} else if (!this.checkRegistrationAge()) {
				this.showError('Please enter a valid age limit.');
				return;
			}

			this.isLoading = true;

			EventItemManager.addEventToDB(this.eventData, this.selectedImage)
				.then((eventData) => {
					this.isLoading = false;
					this.navigateToEvents();
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		checkTitleDescription() {
			if (
				!this.$helpers.getNullOrValue(this.eventData.eventTitle) ||
				!this.$helpers.getNullOrValue(this.eventData.eventDescription)
			) {
				return false;
			}
			return true;
		},
		checkImage() {
			if (!this.$helpers.getNullOrValue(this.selectedImage)) {
				return false;
			}
			return true;
		},
		checkDate() {
			if (
				!this.$helpers.getNullOrValue(this.eventData.eventStart) &&
				!this.$helpers.getNullOrValue(this.eventData.eventDuration)
			) {
				return false;
			}
			return true;
		},
		checkRegistrationPrice() {
			if (this.$helpers.getNullOrValue(this.eventData.price)) {
				if (!this.$helpers.getNullOrValue(this.eventData.price.amount)) {
					return false;
				}
			}
			return true;
		},
		checkRegistrationButton() {
			if (this.isCustomDefaultButton) {
				if (
					!this.$helpers.getNullOrValue(this.eventData.eventButtonTitle) ||
					!this.$helpers.getNullOrValue(this.eventData.eventButtonLink)
				) {
					return false;
				}
			}
			return true;
		},
		checkRegistrationAge() {
			if (this.isAgeLimit) {
				if (!this.$helpers.getNullOrValue(this.eventData.ageItem.startAge)) {
					return false;
				}
			}
			return true;
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.onSave();
			}
		},
		checkIfCanUpload() {
			this.isLoading = true;
			EventItemManager.canUpload().then((canUpload) => {
				this.isLoading = false;
				if (!canUpload) {
					this.showError('You have reached your limit on your current plan.');
					this.navigateToEvents();
				}
			});
		},
		showError(message) {
			this.showNotification({
				type: 'danger',
				message: message,
			});
		},
		navigateToEvents() {
			this.$router.replace({
				name: DashboardChildren.events.name,
				params: { refresh: true },
			});
		},
	},
	mounted() {
		this.checkIfCanUpload();
	},
};
</script>
