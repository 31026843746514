<template>
	<div>
		<Card no-body class="pt-3 pb-1">
			<div class="d-flex align-items-center px-2">
				<b-avatar
					style="background-color: white"
					class="mr-2"
					:src="organization.orgLogo"
					variant="dark"
				></b-avatar>
				<BaseInput
					ref="newPostInput"
					style="width: 100%"
					:withPadding="false"
					placeholder="What's on your mind?"
					@focus="newPostClicked($event)"
				/>
			</div>
			<hr class="mt-3 mb-0" />
			<b-row class="mt-2 mb-2 p-0">
				<b-col>
					<BaseButton
						type="button"
						block
						icon="fas fa-image"
						size="sm"
						link
						class="text-dark"
						@click="newPostClicked"
						>Photo</BaseButton
					>
				</b-col>
				<b-col>
					<BaseButton
						type="button"
						block
						icon="fas fa-video"
						size="sm"
						link
						class="text-dark"
						@click="newPostClicked"
						>Video</BaseButton
					>
				</b-col>
				<b-col>
					<BaseButton
						type="button"
						block
						icon="fas fa-hand-paper"
						size="sm"
						link
						class="text-dark"
						@click="newPostClicked"
					>
						Prayer</BaseButton
					>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseButton from '../BaseButton.vue';
import Card from '../Cards/Card.vue';
import BaseInput from '../Inputs/BaseInput.vue';
import CardFooter from '../Cards/CardFooter.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: { Card, BaseInput, BaseButton, CardFooter },
	methods: {
		newPostClicked(event) {
			event.target.blur();
			this.$emit('newPostClicked');
		},
	},
};
</script>
