import {
	collection,
	deleteDoc,
	doc,
	getCountFromServer,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
} from 'firebase/firestore';
import { firestore, storage } from '../../resources/firebase';
import store from '../../store/store';
import FirebaseManager from '../Firebase/FirebaseManager';
import GroupItemManager from '../GroupItem/GroupItemManager';
import { ref } from 'firebase/storage';
import helpers from '../../helpers/helpers';
import mimiDB from 'mime-db';
import { PricingInformation } from '../../resources/PricingInformation';

export default class FileItemManager {
	static limitFetch = 15;

	static getOrgFilesRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/files`
		);
	}

	static canUpload() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const ref = this.getOrgFilesRef(organization.orgId);
			getCountFromServer(ref)
				.then((snapshot) => {
					const planInfo = PricingInformation.find(
						(e) => e.billingType == organization.billingType
					);
					const maxAllowed = planInfo.billingType !== 'basic';
					return resolve(maxAllowed);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getOrgFiles(lastDocument, maxCount, groupId) {
		return new Promise((resolve, reject) => {
			const fetchCount =
				maxCount != null
					? maxCount > FileItemManager.limitFetch
						? maxCount
						: FileItemManager.limitFetch
					: FileItemManager.limitFetch;
			const organization = store.state.organization;
			const groupRef = groupId
				? GroupItemManager.getOrgGroupFilesRef(organization.orgId, groupId)
				: this.getOrgFilesRef(organization.orgId);
			var queryRef = query(
				groupRef,
				orderBy('created', 'desc'),
				limit(fetchCount)
			);
			if (lastDocument) {
				queryRef = query(
					groupRef,
					orderBy('created', 'desc'),
					startAfter(lastDocument),
					limit(fetchCount)
				);
			}

			getDocs(queryRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					const lastDocument = snapshot.docs.lastItem;
					var counter = 0;
					var items = [];
					if (counter == total) {
						return resolve({ items: items, lastCursor: lastDocument });
					}
					documents.forEach((document) => {
						var fileData = document.data();
						items.push(fileData);
						if (counter == total - 1) {
							return resolve({ items: items, lastCursor: lastDocument });
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static addFileToDB(data, file, fileName, fileType, groupId, loadingCallback) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const user = store.state.user;
			const orgId = organization.orgId;
			const orgRef = groupId
				? GroupItemManager.getOrgGroupFilesRef(orgId, groupId)
				: this.getOrgFilesRef(orgId);
			const newRef = doc(orgRef);
			const cleanFileName = fileName.replace(/\s+/g, '');
			const storageRef = ref(
				storage,
				`${orgRef.path}/${newRef.id}/file:${cleanFileName}.${mimiDB[fileType].extensions[0]}`
			);

			FirebaseManager.uploadFile(storageRef, file, fileType, (progress) => {
				loadingCallback(progress);
			}).then((downloadURL) => {
				FirebaseManager.createDynamicLink(
					newRef.id,
					groupId,
					FirebaseManager.DataType.fileItem,
					data.fileTitle,
					data.fileDescription,
					downloadURL
				).then((shareLink) => {
					var fileData = helpers.removeAllNullValues(data);
					fileData.shareLink = shareLink;
					fileData.fileLink = downloadURL;
					fileData.fileId = newRef.id;
					fileData.orgId = orgId;
					fileData.created = FirebaseManager.timestamp();
					fileData.createdBy = user.uid;
					if (groupId) {
						fileData.groupId = groupId;
					}
					setDoc(newRef, fileData)
						.then(() => {
							return resolve(fileData);
						})
						.catch((error) => {
							return reject(error.message);
						});
				});
			});
		});
	}

	static deleteFileItem(fileId, groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const orgRef = groupId
				? GroupItemManager.getOrgGroupFilesRef(orgId, groupId)
				: this.getOrgFilesRef(orgId);
			const storageRef = ref(storage, `${orgRef.path}/${fileId}`);

			FirebaseManager.deleteStorageRef(storageRef).finally(() => {
				return this.completeFileDelete(fileId, groupId)
					.then(() => {
						return resolve();
					})
					.catch((errorMessage) => {
						return reject(errorMessage);
					});
			});
		});
	}

	static completeFileDelete(fileId, groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const orgRef = groupId
				? GroupItemManager.getOrgGroupFilesRef(orgId, groupId)
				: this.getOrgFilesRef(orgId);
			const fileRef = doc(firestore, `${orgRef.path}/${fileId}`);
			deleteDoc(fileRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
