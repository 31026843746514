<template>
	<div
		v-if="featuredData"
		@mouseover="imageHover = true"
		@mouseleave="imageHover = false"
	>
		<VueAspectRatio ar="16:9">
			<div class="b-img-helpers card-img">
				<div :style="{ opacity: imageHover ? 0.4 : 1 }">
					<b-img-lazy
						fluid-grow
						rounded
						:src="featuredData.imageItem.imageUrl"
						class="b-img-background-blur"
					/>
					<div class="b-img-overlay"></div>
					<b-img-lazy
						fluid-grow
						:src="featuredData.imageItem.imageUrl"
						class="b-img-base"
					/>
				</div>
				<div
					v-if="imageHover"
					style="
						height: 100%;
						width: 100%;
						border: dashed 1px black;
						display: flex;
						flex-wrap: wrap;
						align-content: center;
					"
					class="justify-content-center b-img-base"
				>
					<BaseButton squared type="medium" size="sm" block @click="imageClicked"
						>Delete</BaseButton
					>
				</div>
			</div>
		</VueAspectRatio>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import Card from '../../../components/Cards/Card.vue';
import BaseButton from '../../../components/BaseButton.vue';

export default {
	props: {
		featuredData: {
			type: Object,
			default: null,
		},
	},
	components: { VueAspectRatio, Card, BaseButton },
	data() {
		return {
			imageHover: false,
		};
	},
	methods: {
		imageClicked() {
			this.$emit('editClicked', this.featuredData);
		},
	},
};
</script>
