var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-button',{staticClass:"base-button",class:[
		{ 'rounded-circle': _vm.round },
		{ 'btn-wd': _vm.wide },
		{ 'btn-link': _vm.link },
		{ shake: _vm.shake },
		{ disabled: _vm.disabled },
	],attrs:{"type":_vm.nativeType,"disabled":_vm.disabled || _vm.loading,"variant":!_vm.outline ? _vm.type : `outline-${_vm.type}`,"size":_vm.size,"block":_vm.block,"squared":_vm.squared},on:{"click":_vm.handleClick}},[_vm._t("loading",function(){return [(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e()]}),(_vm.icon)?_c('span',{staticClass:"btn-inner-icon"},[_c('i',{class:_vm.icon})]):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }