<template>
	<div>
		<Card no-body class="py-1">
			<FeedHeader
				:postData="postData"
				@headerData="onHeaderData"
				@optionClicked="optionClicked"
				@approvedClicked="approvedClicked"
				@deleteClicked="askDeleteQuestion"
				:description="description"
				:isNew="isNew"
			></FeedHeader>
			<FeedContent
				:headerData="headerData"
				:postData="postData"
				:videoContent="videoContent"
				:imageContent="imageContent"
				:isNew="isNew"
			></FeedContent>
			<FeedSocialButtons :postData="postData" :isNew="isNew"></FeedSocialButtons>
		</Card>
		<QuestionModal
			:show="showQuestionModal"
			@close="showQuestionModal = false"
			:question="question"
			@yesClicked="deleteClicked"
		/>
	</div>
</template>
<script>
import PostItemManager from '../../models/PostItem/PostItemManager';
import Card from '../Cards/Card.vue';
import QuestionModal from '../Modals/QuestionModal.vue';
import FeedContent from './FeedContent.vue';
import FeedHeader from './FeedHeader.vue';
import FeedSocialButtons from './FeedSocialButtons.vue';

export default {
	components: {
		Card,
		FeedHeader,
		FeedContent,
		FeedSocialButtons,
		QuestionModal,
	},
	props: {
		postData: {
			type: Object,
			default: null,
		},
		description: {
			type: String,
			default: null,
		},
		imageContent: {
			type: String,
			default: null,
		},
		videoContent: {
			type: String,
			default: null,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			showQuestionModal: false,
			question: null,
			type: null,
			headerData: null,
		};
	},
	methods: {
		setupView() {},
		onHeaderData(data) {
			return (this.headerData = data);
		},
		optionClicked() {
			console.log('opeion cliked');
		},
		approvedClicked() {
			PostItemManager.approvePostItem(this.postData.postId, null)
				.then(() => {
					this.$emit('approvePost', this.postData);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		askDeleteQuestion() {
			this.question = 'Are you sure you want to delete this post?';
			this.showQuestionModal = true;
		},
		deleteClicked() {
			PostItemManager.deletePostItem(this.postData.postId, null)
				.then(() => {
					this.$emit('deletePost', this.postData);
				})
				.catch((error) => {
					console.log(error);
				});
		},
	},
	mounted() {
		this.setupView();
	},
};
</script>
