<template>
	<div>
		<Modal
			:show="showModal"
			@close="closeModal"
			:size="isLink == true ? 'lg' : 'xl'"
			hide-footer
		>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">New Video</h5>
			</template>
			<hr class="m-0 p-0" />
			<div>
				<h5
					class="py-2"
					v-if="errorMessage"
					style="color: red; flex: auto; text-align: center"
				>
					{{ errorMessage }}
				</h5>
			</div>

			<div v-if="isLink == null">
				<b-container
					style="border: 1 dotted lightgray"
					class="text-center my-5 py-5"
				>
					<h5 class="mb-0">Select the type of video you'd like to add</h5>
					<b-row class="justify-content-center my-3">
						<b-col cols="auto">
							<BaseButton @click="isLink = true"
								>Video Link<i class="ml-1 fas fa-link"></i
							></BaseButton>
						</b-col>
						<b-col cols="auto">
							<BaseButton @click="isLink = false"
								>Upload File<i class="ml-1 fas fa-upload"></i
							></BaseButton>
						</b-col>
					</b-row>
				</b-container>
			</div>

			<div v-if="isLink == false && videoItem == null">
				<b-container
					style="border: 1 dotted lightgray"
					class="text-center my-5 py-5"
				>
					<h5 class="mb-0">Select a video file to upload</h5>
					<h5 class="mb-1 text-muted font-weight-normal">
						You can adjust the content of your video once you upload.
					</h5>
					<input
						id="fileUploader"
						type="file"
						accept="video/mp4,video/x-m4v,video/*"
						@change="onFileChanged($event)"
						hidden
					/>
					<BaseButton class="my-2" @click="openFiles()"
						>Select Video File</BaseButton
					>
				</b-container>
				<hr class="my-2" />
				<h6 class="text-center font-weight-normal my-3">
					By submitting your content to MySunday, you acknowledge that you agree to
					MySunday's Terms of Service and Community Guidelines. Please be sure not to
					violate others' copyright or privacy rights.
				</h6>
			</div>
			<div v-else-if="isLink == false" class="py-2">
				<b-row>
					<b-col order="1" md="8">
						<div>
							<label class="form-control-label m-0 p-0">Video Thumbnail</label>
							<h6 class="m-0 text-muted font-weight-normal">
								Upload a a picture that shows what's in your video. A good thumbnail
								stands out and draws viewers' attention.
							</h6>
							<div class="my-3">
								<BaseDropbox @onFile="onFileChange"> </BaseDropbox>
							</div>
						</div>
						<BaseInput
							label="Video Title"
							id="Video Title"
							name="Video Title"
							placeholder="Enter Video Title"
							required
							type="text"
							v-model="videoData.videoTitle"
						></BaseInput>

						<BaseInput
							label="Video Description"
							id="Video Description"
							name="Video Description"
							placeholder="Enter your description..."
							type="textarea"
							required
							rows="6"
							:max-length="350"
							v-model="videoData.videoDescription"
						/>
					</b-col>
					<b-col order-sm="1">
						<Card no-body style="overflow: hidden">
							<video
								id="videoItemPlayer"
								:src="getVideoFileSrc()"
								controls
								:autoplay="false"
								style="width: 100%"
								class="card-img-top"
								controlsList="nodownload nofullscreen"
							></video>
							<div class="p-2">
								<h6 class="heading-small text-muted mb-0">File Name</h6>
								<h4 class="m-0">{{ getVideoFileName() }}</h4>
								<h6 class="heading-small text-muted mb-0">File Duration</h6>
								<h4 class="m-0">{{ getVideoFileDuration() }}</h4>
								<div class="playground"></div>
							</div>
						</Card>
					</b-col>
				</b-row>
				<b-row class="mr-0 mt-3 mb-3">
					<div style="flex-grow: 1"></div>
					<BaseButton type="primary" :shake="shake" @click="submit"
						>Add Video</BaseButton
					>
				</b-row>
			</div>
			<div v-else-if="isLink == true">
				<b-row>
					<b-col>
						<div>
							<label class="form-control-label m-0 p-0">Video Thumbnail</label>
							<h6 class="m-0 text-muted font-weight-normal">
								Upload a a picture that shows what's in your video. A good thumbnail
								stands out and draws viewers' attention.
							</h6>
							<div class="my-3">
								<BaseDropbox @onFile="onFileChange"> </BaseDropbox>
							</div>
						</div>
						<BaseInput
							label="Video Title"
							id="Video Title"
							name="Video Title"
							placeholder="Enter Video Title"
							required
							type="text"
							v-model="videoData.videoTitle"
						></BaseInput>

						<BaseInput
							label="Video Description"
							id="Video Description"
							name="Video Description"
							placeholder="Enter your description..."
							type="textarea"
							required
							rows="6"
							:max-length="350"
							v-model="videoData.videoDescription"
						/>
						<BaseInput
							label="Video Link"
							id="Video Link"
							name="Video Link"
							placeholder="Enter Video Link"
							required
							type="url"
							v-model="videoData.videoExternalLink"
						></BaseInput>
					</b-col>
				</b-row>
				<b-row class="mr-0 mt-3 mb-3">
					<div style="flex-grow: 1"></div>
					<BaseButton type="primary" :shake="shake" @click="submit"
						>Add Video Link</BaseButton
					>
				</b-row>
			</div>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../../components/Modals/Modal.vue';
import FileInput from '../../../components/Inputs/FileInput.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import BaseButton from '../../../components/BaseButton.vue';
import helpers from '../../../helpers/helpers';
import Card from '../../../components/Cards/Card.vue';
import BaseDropbox from '../../../components/Inputs/BaseDropbox.vue';

export default {
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Modal,
		VueDropZone,
		BaseInput,
		FileInput,
		BaseButton,
		Card,
		BaseDropbox,
	},
	data() {
		return {
			shake: false,
			isLink: null,
			videoItem: null,
			videoThumbnail: null,
			errorMessage: null,
			videoData: {
				videoTitle: null,
				videoDescription: null,
				videoDuration: 0,
				videoExternalLink: null,
			},
		};
	},
	methods: {
		openFiles() {
			document.getElementById('fileUploader').click();
		},
		onFileChange(file) {
			this.videoThumbnail = file;
		},
		onFileChanged(event) {
			this.videoItem = event.target.files[0];
			this.videoData.videoTitle = this.videoItem.name.replace(/\.[^/.]+$/, '');
		},
		getVideoFileSrc() {
			return this.videoItem ? window.URL.createObjectURL(this.videoItem) : null;
		},
		getVideoFileName() {
			return this.videoItem ? this.videoItem.name : null;
		},
		getVideoFileDuration() {
			const hackDate = new Date(0);
			hackDate.setSeconds(this.videoData.videoDuration);
			return hackDate.toISOString().substring(11, 19);
		},
		submit() {
			this.shake = false;
			if (this.isLink == true) {
				if (this.videoThumbnail == null) {
					this.errorMessage = 'Please select a video thumbnail.';
					this.shake = true;
					return;
				} else if (helpers.isBlank(this.videoData.videoTitle)) {
					this.errorMessage = 'Please enter a video title.';
					this.shake = true;
					return;
				} else if (helpers.isBlank(this.videoData.videoDescription)) {
					this.errorMessage = 'Please enter a video description.';
					this.shake = true;
					return;
				} else if (helpers.isBlank(this.videoData.videoExternalLink)) {
					this.errorMessage = 'Please select a video file.';
					this.shake = true;
					return;
				} else {
					this.errorMessage = null;
					this.addVideoItem();
				}
			} else {
				if (this.videoItem == null) {
					this.errorMessage = 'Please select a video file.';
					this.shake = true;
					return;
				} else if (this.videoThumbnail == null) {
					this.errorMessage = 'Please select a video thumbnail.';
					this.shake = true;
					return;
				} else if (helpers.isBlank(this.videoData.videoTitle)) {
					this.errorMessage = 'Please enter a video title.';
					this.shake = true;
					return;
				} else if (helpers.isBlank(this.videoData.videoDescription)) {
					this.errorMessage = 'Please enter a video description.';
					this.shake = true;
					return;
				} else {
					this.errorMessage = null;
					this.addVideoItem();
				}
			}
		},
		addVideoItem() {
			this.$emit('uploadData', {
				videoItem: this.videoItem,
				videoThumbnail: this.videoThumbnail,
				videoData: this.videoData,
			});
			this.closeModal();
		},
		resetView() {
			this.videoItem = null;
			this.videoThumbnail = null;
			this.videoData.videoTitle = null;
			this.videoData.videoDescription = null;
			this.videoData.videoDuration = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
			//this.resetView();
		},
	},
};
</script>
<style></style>
