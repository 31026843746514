<template>
	<div v-if="groupData">
		<DashboardHeader
			:title="groupData.groupTitle"
			:buttonItems="buttonItems"
			@childClick="childClicked"
		/>
		<BaseContent>
			<b-overlay :show="isLoading" rounded="sm">
				<b-row v-if="getNotificationBanner()" class="mb-3">
					<b-col @click="notificationClicked">
						<b-alert
							class="m-0 p-2 text-center"
							variant="primary"
							show
							:dismissible="false"
							fade
						>
							<div class="d-flex align-items-center text-center">
								<h5 class="mb-0 mr-2 text-white text-center w-100">
									{{ getNotificationBanner() }}
								</h5>
								<i class="mb-0 fas fa-chevron-right"></i>
							</div>
						</b-alert>
					</b-col>
				</b-row>
				<b-row>
					<b-col md="5" order="2">
						<b-row>
							<b-col>
								<GroupFilesCard :group-data="groupData" />
							</b-col>
						</b-row>
					</b-col>
					<b-col order="1">
						<Card no-body>
							<b-tabs
								active-nav-item-class="bg-white font-weight-bold description"
								nav-class="bg-white description"
								card
								no-body
							>
								<b-tab title="Group Details" active>
									<GroupImageCard :group-data="groupData" @updateImage="updateImage" />
									<TitleDescriptionView
										:showImage="false"
										:headerLabel="'Group Information'"
										:showUpdateButton="true"
										:title-label="'Group Title'"
										:titleValue="groupData.groupTitle"
										:description-label="'Group Description'"
										:descriptionValue="groupData.groupDescription"
										@onUpdate="onTitleDescriptionUpdate"
									/>

									<LocationView
										:showUpdateButton="true"
										:headerLabel="'Group Location'"
										:titleValue="groupData.location ? groupData.location.title : null"
										:descriptionValue="
											groupData.location ? groupData.location.description : null
										"
										@onUpdate="onLocationUpdate"
									/>
								</b-tab>
								<b-tab title="Registration">
									<RegistrationAttendanceView
										:showUpdateButton="true"
										:titleLabel="'Group Members'"
										:titleLabelTooltip="'The maximum amount of users that can join this group.'"
										:maxUsersValue="groupData.groupMaxUsers"
										@onUpdate="onAttendanceUpdate"
									/>
									<RegistrationButtonView
										:showUpdateButton="true"
										:requiresLink="true"
										:defaultTitleValue="groupData.groupCustomButtonTitle"
										:defaultLinkValue="groupData.groupCustomButtonLink"
										:defaultButtonLabel="'Custom Button'"
										:defaultButtonFooterLabel="'Add a custom button to the group for all users.'"
										@onUpdate="onCustomButtonUpdate"
									/>
								</b-tab>
								<b-tab title="Additional Settings">
									<AgeLimitView
										:showUpdateButton="true"
										:startAgeValue="groupData.ageItem ? groupData.ageItem.startAge : null"
										:endAgeValue="
											groupData.ageItem
												? groupData.ageItem.endAge
													? groupData.ageItem.endAge
													: null
												: null
										"
										:defaultAgeEnforced="
											groupData.ageItem
												? groupData.ageItem.enforced
													? groupData.ageItem.enforced
													: false
												: false
										"
										@onUpdate="onAgeUpdate"
									/>
									<PrivacyView
										:titleLabel="'Private Group'"
										titleLabelTooltip="Decide if users can auto join this group or request access to join."
										:privacyValue="
											groupData.isPrivate != null ? groupData.isPrivate : false
										"
										@onUpdate="onPrivacyUpdate"
									/>
									<PrivacyView
										:titleLabel="'Member Group'"
										titleLabelTooltip="Decide if this item can been seen by users outside of the MySunday."
										:privacyValue="
											groupData.isMemberOnly != null ? groupData.isMemberOnly : false
										"
										@onUpdate="onMemberPrivacyUpdate"
									/>
								</b-tab>
							</b-tabs>
						</Card>
					</b-col>
				</b-row>
			</b-overlay>
		</BaseContent>
		<QuestionModal
			:show="showQuestionModal"
			@close="showQuestionModal = false"
			:question="question"
			@yesClicked="yesClicked"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import BaseContent from '../../../components/BaseContent.vue';
import Card from '../../../components/Cards/Card.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import GroupItemManager from '../../../models/GroupItem/GroupItemManager';
import { DashboardChildren } from '../../../routes/routesNames';
import TitleDescriptionView from '../../SecondaryComponents/BaseNewViews/TitleDescriptionView.vue';
import DateTimeView from '../../SecondaryComponents/BaseNewViews/DateTimeView.vue';
import PrivacyView from '../../SecondaryComponents/BaseNewViews/PrivacyView.vue';
import RegistrationAttendanceView from '../../SecondaryComponents/BaseNewViews/RegistrationAttendanceView.vue';
import AgeLimitView from '../../SecondaryComponents/BaseNewViews/AgeLimitView.vue';
import LocationView from '../../SecondaryComponents/BaseNewViews/LocationView.vue';
import RegistrationButtonView from '../../SecondaryComponents/BaseNewViews/RegistrationButtonView.vue';
import GroupFilesCard from '../../SecondaryComponents/GroupViews/GroupFilesCard.vue';
import GroupImageCard from '../../SecondaryComponents/GroupViews/GroupImageCard.vue';
import FileTable from '../../SecondaryComponents/FileViews/FileTable.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';

export default {
	props: {
		groupId: {
			type: String,
			default: null,
		},
	},
	components: {
		DashboardHeader,
		BaseContent,
		Card,
		TitleDescriptionView,
		DateTimeView,
		PrivacyView,
		RegistrationAttendanceView,
		AgeLimitView,
		LocationView,
		RegistrationButtonView,
		GroupFilesCard,
		GroupImageCard,
		FileTable,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			groupData: null,
			showQuestionModal: false,
			question: null,
			selectedId: null,
			viewAttendeesId: 0,
			sendNotificationId: 2,
			shareGroupId: 3,
			deleteGroupId: 4,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		onTitleDescriptionUpdate(updatedData) {
			const title = updatedData.title;
			const description = updatedData.description;
			this.updateGroupData({
				groupTitle: title,
				groupDescription: description,
			});
		},
		onAttendanceUpdate() {
			const maxUsers =
				updatedData.maxUsers != null ? parseInt(updatedData.maxUsers) : null;
			this.updateGroupData({
				groupMaxUsers: maxUsers,
			});
		},
		onLocationUpdate(updatedData) {
			const title = updatedData.title;
			const description = updatedData.description;
			this.updateGroupData({
				location: {
					title: title,
					description: description,
				},
			});
		},
		onPrivacyUpdate(updatedData) {
			const isPrivate = !updatedData.isPrivate;
			this.updateGroupData({
				isPrivate: isPrivate,
			});
		},
		onMemberPrivacyUpdate(updatedData) {
			const isMemberOnly = !updatedData.isPrivate;
			this.updateGroupData({
				isMemberOnly: isMemberOnly,
			});
		},
		onAgeUpdate(updatedData) {
			const startAge =
				updatedData.startAge != null ? parseInt(updatedData.startAge) : null;
			const endAge =
				updatedData.endAge != null ? parseInt(updatedData.endAge) : null;
			const ageEnforced = updatedData.ageEnforced;
			if (startAge) {
				this.updateGroupData({
					ageItem: {
						startAge: startAge,
						endAge: endAge,
						enforced: ageEnforced,
					},
				});
			} else {
				this.updateGroupData({
					ageItem: null,
				});
			}
		},
		onCustomButtonUpdate(updatedData) {
			const isCustomDefaultButton = updatedData.isCustomDefaultButton;
			const defaultButtonTitle = updatedData.defaultButtonTitle;
			const defaultButtonLink = updatedData.defaultButtonLink;
			this.updateGroupData({
				groupCustomButtonTitle: isCustomDefaultButton ? defaultButtonTitle : null,
				groupCustomButtonLink: isCustomDefaultButton ? defaultButtonLink : null,
			});
		},
		updateGroupData(data) {
			this.isLoading = true;
			GroupItemManager.updateGroupDB(data, this.groupId)
				.then(() => {
					this.isLoading = false;
					this.getGroupData();
					this.showNotification({
						type: 'success',
						message: 'Group Updated',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
		updateImage(file) {
			this.isLoading = true;
			GroupItemManager.updateGroupImage(file, this.groupId)
				.then(() => {
					this.isLoading = false;
					this.getGroupData();
					this.showNotification({
						type: 'success',
						message: 'Group Updated',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
		getGroupData() {
			this.isLoading = true;
			GroupItemManager.getGroupById(this.groupId).then((groupData) => {
				this.isLoading = false;
				this.groupData = groupData;
				this.setButtons();
			});
		},
		shareGroup() {
			if (this.groupData.shareLink) {
				navigator.clipboard.writeText(this.groupData.shareLink);
				this.showNotification({
					type: 'success',
					message: 'Link Copied',
				});
			} else {
				this.showNotification({
					type: 'danger',
					message: 'Unable To Copy Link',
				});
			}
		},
		yesClicked() {
			if (this.selectedId == this.deleteGroupId) {
				this.deleteGroup();
			}
		},
		askDeleteQuestion() {
			this.selectedId = this.deleteGroupId;
			this.question =
				'Are you sure you want to delete this group? All events, posts, files, etc. will be deleted.';
			this.showQuestionModal = true;
		},
		deleteGroup() {
			this.isLoading = true;
			GroupItemManager.deleteGroupItem(this.groupId)
				.then(() => {
					this.navigateBack();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		setButtons() {
			var children = [
				{
					index: this.viewAttendeesId,
					title: 'View Members',
				},
				/*
				{
					index: this.sendNotificationId,
					title: 'Send Notification',
				},
				*/
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.shareGroupId,
					title: 'Share Group',
				},
				{
					index: -2,
					title: '',
					isDivider: true,
				},
				{
					index: this.deleteGroupId,
					title: 'Delete Group',
					type: 'danger',
				},
			];
			this.buttonItems[0].children = children;
		},
		childClicked(childClicked) {
			switch (childClicked.index) {
				case this.viewAttendeesId:
					this.navigateToGroupUsers();
					break;
				case this.shareGroupId:
					this.shareGroup();
					break;
				case this.deleteGroupId:
					this.askDeleteQuestion();
					break;
				default:
					break;
			}
		},
		getNotificationBanner() {
			if (this.groupData.groupPendingUsers) {
				if (this.groupData.groupPendingUsers > 0) {
					return `${this.groupData.groupPendingUsers} Pending User${
						this.groupData.groupPendingUsers > 1 ? 's' : ''
					}`;
				}
			}
			return false;
		},
		notificationClicked() {
			this.navigateToGroupUsers();
		},
		navigateToGroupUsers() {
			this.$router.push({
				name: DashboardChildren.groupUserView.name,
				params: {
					groupData: this.groupData,
					groupId: this.groupId,
				},
			});
		},
		navigateBack() {
			this.$router.replace({
				name: DashboardChildren.groups.name,
			});
		},
	},
	mounted() {
		if (!this.groupId) {
			this.navigateBack();
		} else if (this.$route.params.groupData) {
			this.groupData = this.$route.params.groupData;
			this.setButtons();
		} else {
			this.getGroupData();
		}
	},
};
</script>
