<template>
	<div v-if="groupData">
		<DashboardHeader
			:title="groupData.groupTitle"
			:buttonItems="buttonItems"
			@childClick="childClicked"
		/>
		<BaseContent>
			<GroupUserTable
				v-if="pendingUsers.length > 0"
				:headerTitle="'Pending Members'"
				:isLoading="isPendingLoading"
				:tableData="pendingUsers"
				:total="
					groupData.groupPendingUsers != null ? groupData.groupPendingUsers : 0
				"
				isPending
				@currentPage="currentPendingPage"
				@viewClicked="viewClicked"
				@acceptClicked="acceptClicked"
				@declineClicked="askDeclineQuestion"
			/>
			<GroupUserTable
				:headerTitle="'Members'"
				:isLoading="isLoading"
				:tableData="users"
				:total="groupData.groupUsers != null ? groupData.groupUsers : 0"
				@currentPage="currentPage"
				@viewClicked="viewClicked"
				@removeClicked="askRemoveQuestion"
			/>
		</BaseContent>
		<QuestionModal
			:show="showQuestionModal"
			@close="showQuestionModal = false"
			:question="question"
			@yesClicked="yesClicked"
		/>
	</div>
</template>
<script>
import BaseContent from '../../../components/BaseContent.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import GroupUserTable from '../../SecondaryComponents/GroupViews/GroupUserTable.vue';
import GroupItemManager from '../../../models/GroupItem/GroupItemManager';
import AppUserManager from '../../../models/AppUser/AppUserManager';
import { DashboardChildren } from '../../../routes/routesNames';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import { mapActions } from 'vuex';

export default {
	props: {
		groupId: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			isPendingLoading: false,
			groupData: null,
			showQuestionModal: false,
			question: null,
			selectedData: null,
			users: [],
			usersCount: 0,
			pendingUsers: [],
			pendingUsersCount: 0,
			lastFetched: null,
			lastPendingFetched: null,
			selectedId: null,
			addAttendeesId: 0,
			exportDataId: 1,
			declineId: 2,
			removeId: 3,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getGroupUsers(maxCount) {
			this.isLoading = true;
			GroupItemManager.getGroupUsers(
				this.groupId,
				this.lastFetched,
				maxCount,
				false
			)
				.then(([users, lastDocument]) => {
					this.users.push.apply(
						this.users,
						users.filter((e) => !e.groupData.isPending)
					);
					this.lastFetched = lastDocument;
					this.isLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		getGroupPendingUsers(maxCount) {
			this.isPendingLoading = true;
			GroupItemManager.getGroupUsers(
				this.groupId,
				this.lastFetched,
				maxCount,
				true
			)
				.then(([users, lastDocument]) => {
					this.pendingUsers.push.apply(this.pendingUsers, users);
					this.lastPendingFetched = lastDocument;
					this.isPendingLoading = false;
				})
				.catch((error) => {
					console.log(error);
				});
		},
		currentPage(index) {
			const minCount = AppUserManager.limitFetch * (index - 1);
			const maxCount = AppUserManager.limitFetch * index;
			const inBetween = maxCount - this.users.length;
			if (minCount >= this.users.length && this.lastFetched != null) {
				const value =
					inBetween > AppUserManager.limitFetch
						? inBetween
						: AppUserManager.limitFetch;
				this.getGroupUsers(value);
			}
		},
		currentPendingPage(index) {
			const minCount = AppUserManager.limitFetch * (index - 1);
			const maxCount = AppUserManager.limitFetch * index;
			const inBetween = maxCount - this.pendingUsers.length;
			if (minCount >= this.pendingUsers.length && this.lastFetched != null) {
				const value =
					inBetween > AppUserManager.limitFetch
						? inBetween
						: AppUserManager.limitFetch;
				this.getGroupPendingUsers(value);
			}
		},
		getGroupData() {
			this.isLoading = true;
			GroupItemManager.getGroupById(this.groupId).then((result) => {
				if (result) {
					this.isLoading = false;
					this.groupData = result;
					this.getGroupUsers(null);
					this.getGroupPendingUsers(null);
				} else {
					this.navigateToGroups();
				}
			});
		},
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: this.addAttendeesId,
					title: 'Add Members',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.exportDataId,
					title: 'Export Data',
				},
			];
		},
		childClicked(childClicked) {
			this.selectedId = childClicked.index;
		},
		askDeclineQuestion(userData) {
			this.selectedData = {
				selectedId: this.declineId,
				userData: userData,
			};
			this.question = 'Are you sure you want to decline this member?';
			this.showQuestionModal = true;
		},
		askRemoveQuestion(userData) {
			this.selectedData = {
				selectedId: this.removeId,
				userData: userData,
			};
			this.question = 'Are you sure you want to remove this member?';
			this.showQuestionModal = true;
		},
		yesClicked() {
			if (this.selectedData.selectedId == this.declineId) {
				this.declineClicked(this.selectedData.userData.userData);
			} else if (this.selectedData.selectedId == this.removeId) {
				this.removeClicked(this.selectedData.userData.userData);
			}
		},
		acceptClicked(data) {
			const pendingData = this.pendingUsers.find(
				(e) => e.userData.uid == data.userData.uid
			);
			const userData = pendingData.userData;
			this.isPendingLoading = true;
			this.isLoading = true;
			GroupItemManager.addUserToGroup(userData.uid, this.groupData.groupId)
				.then(() => {
					this.pendingUsers = this.pendingUsers.filter(
						(e) => e.userData.uid !== userData.uid
					);
					this.users.push(pendingData);
					this.isPendingLoading = false;
					this.isLoading = false;
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		removeClicked(userData) {
			this.isLoading = true;
			GroupItemManager.removeUserFromGroup(userData.uid, this.groupData.groupId)
				.then(() => {
					this.users = this.users.filter((e) => e.userData.uid !== userData.uid);
					this.isLoading = false;
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		declineClicked(userData) {
			this.isPendingLoading = true;
			GroupItemManager.removeUserFromGroup(userData.uid, this.groupData.groupId)
				.then(() => {
					this.pendingUsers = this.pendingUsers.filter(
						(e) => e.userData.uid !== userData.uid
					);
					this.isPendingLoading = false;
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		viewClicked(data) {
			const userData = data.userData;
			this.$router.push({
				name: DashboardChildren.memberView.name,
				params: {
					userData: userData,
				},
				query: {
					uid: userData.uid,
				},
			});
		},
		navigateToGroups() {
			this.$router.replace({
				name: DashboardChildren.groups.name,
				params: { refresh: true },
			});
		},
	},

	mounted() {
		if (!this.groupId) {
			this.navigateToGroups();
			return;
		} else if (this.$route.params.groupData) {
			this.groupData = this.$route.params.groupData;
			this.getGroupUsers(null);
			this.getGroupPendingUsers(null);
		} else {
			this.getGroupData();
		}
		this.setButtons();
	},
	components: { DashboardHeader, BaseContent, GroupUserTable, QuestionModal },
};
</script>
