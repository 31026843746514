<template>
	<b-overlay :show="isLoading" rounded="sm">
		<Card>
			<CardHeader
				slot="header"
				title="Notes"
				:end-button="{ title: 'Update Notes', disabled: !updateNotes }"
				@click="updateAdminNotes"
			>
			</CardHeader>
			<BaseInput
				type="textarea"
				rows="10"
				v-model="notes"
				placeholder="Add any special notes related to this profile. For example: This user has requested handicap accessibility."
			/>
			<CardFooter
				slot="footer"
				title="* This information is private to you and your admins."
			/>
		</Card>
	</b-overlay>
</template>
<script>
import { mapActions } from 'vuex';
import Card from '../../../components/Cards/Card.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import AppUserManager from '../../../models/AppUser/AppUserManager';

export default {
	props: {
		userData: null,
	},
	components: { Card, CardHeader, BaseInput, CardFooter },
	data() {
		return {
			isLoading: false,
			updateNotes: false,
			notes: null,
		};
	},
	watch: {
		notes: {
			handler(val) {
				return (this.updateNotes = this.checkIfUpdateNeed());
			},
		},
	},
	methods: {
		...mapActions(['showNotification']),
		checkIfUpdateNeed() {
			if (this.userData.admin) {
				return this.notes != this.userData.admin.notes;
			}
			return this.notes;
		},
		updateAdminNotes() {
			this.isLoading = true;
			AppUserManager.updateUserField(this.userData.uid, {
				admin: { notes: this.notes },
			})
				.then(() => {
					this.updateNotes = false;
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Notes Updated.',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error,
					});
				});
		},
	},
	mounted() {
		this.notes = this.userData.admin ? this.userData.admin.notes : null;
	},
};
</script>
