<template>
	<div>
		<Card :no-body="updatedData.isFree">
			<CardHeader
				slot="header"
				title="Pricing Information"
				:endSwitch="showUpdateButton ? null : { title: 'Free Event?' }"
				:endSwitchValue="updatedData.isFree"
				@change="isFreeSwitchChanged"
				:end-button="
					showUpdateButton ? { title: 'Update', disabled: !needsUpdate } : null
				"
				@click="onUpdate"
			/>
			<b-row v-if="!updatedData.isFree">
				<b-col>
					<BaseInput
						:label="priceLabel"
						:id="priceLabel"
						:placeholder="priceLabel"
						required
						type="currency"
						v-model="updatedData.price"
					/>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import CurrencyInput from '../../../components/Inputs/CurrencyInput.vue';

export default {
	props: {
		priceLabel: {
			type: String,
			default: 'Amount',
		},
		priceValue: {
			type: Number,
			default: null,
		},
		showUpdateButton: {
			type: Boolean,
			default: false,
		},
	},
	components: { Card, CardHeader, BaseInput, CurrencyInput },
	data() {
		return {
			needsUpdate: false,
			updatedData: {
				isFree: false,
				price: 0,
			},
		};
	},
	computed: {},
	watch: {
		updatedData: {
			handler(val) {
				this.needsUpdate = this.checkUpdate();
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		isFreeSwitchChanged(value) {
			this.updatedData.isFree = value;
			if (this.updatedData.isFree) {
				this.updatedData.price = 0;
			}
		},
		checkUpdate() {
			if (this.updatedData.price != this.priceValue) {
				return true;
			}
			return false;
		},
		onUpdate() {
			this.$emit('onUpdate', this.updatedData);
		},
		onChange() {
			this.$emit('onChange', this.updatedData);
		},
	},
	mounted() {
		if (this.priceValue) {
			this.updatedData.price = this.priceValue;
		}
	},
};
</script>
