<template>
	<div>
		<Modal :show="showModal" @close="closeModal" size="xl" hide-footer>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">New Audio</h5>
			</template>
			<hr class="m-0 p-0" />
			<div>
				<h5
					class="py-2"
					v-if="errorMessage"
					style="color: red; flex: auto; text-align: center"
				>
					{{ errorMessage }}
				</h5>
			</div>

			<div v-if="audioItem == null">
				<b-container
					style="border: 1 dotted lightgray"
					class="text-center my-5 py-5"
				>
					<h5 class="mb-0">Select an audio file to upload</h5>
					<h5 class="mb-1 text-muted font-weight-normal">
						You can adjust the content of your audio once you upload.
					</h5>
					<input
						id="fileUploader"
						type="file"
						accept="audio/mpeg3"
						@change="onFileChanged($event)"
						hidden
					/>
					<BaseButton class="my-2" @click="openFiles()"
						>Select Audio File</BaseButton
					>
				</b-container>
				<hr class="my-2" />
				<h6 class="text-center font-weight-normal my-3">
					By submitting your content to MySunday, you acknowledge that you agree to
					MySunday's Terms of Service and Community Guidelines. Please be sure not to
					violate others' copyright or privacy rights.
				</h6>
			</div>
			<div v-else class="py-2">
				<b-row>
					<b-col order="1" md="8">
						<div>
							<label class="form-control-label m-0 p-0">Audio Thumbnail</label>
							<h6 class="m-0 text-muted font-weight-normal">
								Upload a a picture that shows what's in your audio. A good thumbnail
								stands out and draws viewers' attention.
							</h6>
							<div class="my-3" style="max-width: 200px">
								<BaseDropbox ar="1:1" @onFile="onFileChange"> </BaseDropbox>
							</div>
						</div>
						<BaseInput
							label="Audio Title"
							id="Audio Title"
							name="Audio Title"
							placeholder="Enter Audio Title"
							required
							type="text"
							v-model="audioData.audioTitle"
						></BaseInput>

						<BaseInput
							label="Audio Artist"
							id="Audio Artist"
							name="Audio Artist"
							placeholder="Enter Audio Artist"
							required
							type="text"
							v-model="audioData.audioArtist"
						></BaseInput>

						<BaseInput
							label="Audio Description"
							id="Audio Description"
							name="Audio Description"
							placeholder="Enter your description..."
							type="textarea"
							required
							rows="6"
							:max-length="350"
							v-model="audioData.audioDescription"
						/>
					</b-col>
					<b-col order-sm="1">
						<Card no-body style="overflow: hidden">
							<div class="p-2">
								<h6 class="heading-small text-muted mb-0">Audio File</h6>
								<audio
									id="audioItemPlayer"
									:src="getAudioFileSrc()"
									controls
									style="width: 100%"
									controlsList="nodownload"
								></audio>
								<hr class="my-2" />
								<h6 class="heading-small text-muted mb-0">Audio Source Name</h6>
								<h4 class="m-0">{{ getAudioFileName() }}</h4>
								<hr class="my-2" />
								<h6 class="heading-small text-muted mb-0">Audio Duration</h6>
								<h4 class="m-0">{{ getAudioFileDuration() }}</h4>
								<div class="playground"></div>
							</div>
						</Card>
					</b-col>
				</b-row>
				<b-row class="mr-0 mt-3 mb-3">
					<div style="flex-grow: 1"></div>
					<BaseButton type="primary" :shake="shake" @click="submit"
						>Add Audio</BaseButton
					>
				</b-row>
			</div>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../../components/Modals/Modal.vue';
import FileInput from '../../../components/Inputs/FileInput.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import BaseButton from '../../../components/BaseButton.vue';
import helpers from '../../../helpers/helpers';
import Card from '../../../components/Cards/Card.vue';
import { he } from 'date-fns/locale';
import BaseDropbox from '../../../components/Inputs/BaseDropbox.vue';

export default {
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		Modal,
		VueDropZone,
		BaseInput,
		FileInput,
		BaseButton,
		Card,
		BaseDropbox,
	},
	data() {
		return {
			shake: false,
			audioItem: null,
			audioThumbnail: null,
			errorMessage: null,
			audioData: {
				audioTitle: null,
				audioArtist: null,
				audioDescription: null,
				audioDuration: 0,
			},
		};
	},
	methods: {
		openFiles() {
			document.getElementById('fileUploader').click();
		},
		onFileChange(file) {
			this.audioThumbnail = file;
		},
		onFileChanged(event) {
			this.audioItem = event.target.files[0];
			this.audioData.audioTitle = this.audioItem.name.replace(/\.[^/.]+$/, '');
		},
		getAudioFileSrc() {
			return this.audioItem ? window.URL.createObjectURL(this.audioItem) : null;
		},
		getAudioFileName() {
			return this.audioItem ? this.audioItem.name : null;
		},
		getAudioFileDuration() {
			const hackDate = new Date(0);
			hackDate.setSeconds(this.audioData.audioDuration);
			return hackDate.toISOString().substring(11, 19);
		},
		submit() {
			this.shake = false;
			if (this.audioItem == null) {
				this.errorMessage = 'Please select a audio file.';
				this.shake = true;
				return;
			} else if (this.audioThumbnail == null) {
				this.errorMessage = 'Please select a audio thumbnail.';
				this.shake = true;
				return;
			} else if (helpers.isBlank(this.audioData.audioTitle)) {
				this.errorMessage = 'Please enter a audio title.';
				this.shake = true;
				return;
			} else if (helpers.isBlank(this.audioData.audioArtist)) {
				this.errorMessage = 'Please enter a audio artist.';
				this.shake = true;
				return;
			} else if (helpers.isBlank(this.audioData.audioDescription)) {
				this.errorMessage = 'Please enter a audio description.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;
				this.addAudioItem();
			}
		},
		addAudioItem() {
			this.$emit('uploadData', {
				audioItem: this.audioItem,
				audioThumbnail: this.audioThumbnail,
				audioData: this.audioData,
			});
			this.closeModal();
		},
		resetView() {
			this.audioItem = null;
			this.audioThumbnail = null;
			this.audioData.audioTitle = null;
			this.audioData.audioArtist = null;
			this.audioData.audioDescription = null;
			this.audioData.audioDuration = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
			//this.resetView();
		},
	},
};
</script>
<style></style>
