import {
	collection,
	doc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
	where,
} from 'firebase/firestore';
import { firestore } from '../../resources/firebase';
import FirebaseManager from '../Firebase/FirebaseManager';
import store from '../../store/store';
import helpers from '../../helpers/helpers';

export default class NotificationItemManager {
	static itemFetchCount = 10;

	static getOrgNotificationRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/notifications`
		);
	}

	static getOrgAdminNotificationRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/adminNotifications`
		);
	}

	static getNotifications(lastCursor) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			var ref = this.getOrgNotificationRef(orgId);
			var queryRef = query(
				ref,
				orderBy('created', 'desc'),
				limit(this.itemFetchCount)
			);
			if (lastCursor) {
				queryRef = query(
					ref,
					orderBy('created', 'desc'),
					startAfter(lastCursor),
					limit(this.itemFetchCount)
				);
			}
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument = snapshot.docs.lastItem;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var notificationData = document.data();
							items.push(notificationData);
							if (counter == total - 1) {
								return resolve({ items: items, lastCursor: lastDocument });
							}
							counter += 1;
						});
					} else {
						return resolve({ items: [], lastCursor: null });
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getScheduledNotifications(lastCursor) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			var ref = this.getOrgNotificationRef(orgId);
			var queryRef = query(
				ref,
				orderBy('scheduleDate', 'desc'),
				where('sent', '==', false),
				limit(this.itemFetchCount)
			);
			if (lastCursor) {
				queryRef = query(
					ref,
					orderBy('scheduleDate', 'desc'),
					where('sent', '==', false),
					startAfter(lastCursor),
					limit(this.itemFetchCount)
				);
			}
			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument = snapshot.docs.lastItem;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var notificationData = document.data();
							items.push(notificationData);
							if (counter == total - 1) {
								return resolve({ items: items, lastCursor: lastDocument });
							}
							counter += 1;
						});
					} else {
						return resolve({ items: [], lastCursor: null });
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getAdminNotifications(lastCursor) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			var ref = this.getOrgAdminNotificationRef(orgId);

			var queryRef = query(
				ref,
				orderBy('created', 'desc'),
				limit(this.itemFetchCount)
			);

			if (lastCursor) {
				queryRef = query(
					ref,
					orderBy('created', 'desc'),
					startAfter(lastCursor),
					limit(this.itemFetchCount)
				);
			}

			getDocs(queryRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument = snapshot.docs.lastItem;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var notificationData = document.data();
							items.push(notificationData);
							if (counter == total - 1) {
								return resolve({ items: items, lastCursor: lastDocument });
							}
							counter += 1;
						});
					} else {
						return resolve({ items: [], lastCursor: null });
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static sendNotificationToDB(message, scheduledDate) {
		return new Promise((resolve, reject) => {
			const currentUser = store.state.user;
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const notificationRef = this.getOrgNotificationRef(orgId);
			const newItemRef = doc(notificationRef);
			var data = {
				notificationTitle: organization.orgName,
				notificationText: message,
				notificationId: newItemRef.id,
				authorId: orgId,
				orgId: orgId,
				sentBy: currentUser.uid,
				sent: false,
				sentVia: 'adminPortal',
			};
			if (scheduledDate) {
				data['scheduleDate'] = new Date(scheduledDate);
			} else {
				data['created'] = helpers.returnTimestamp();
			}
			setDoc(newItemRef, data)
				.then(() => {
					return resolve(data);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
