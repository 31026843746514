<template>
	<Modal
		:show="showModal"
		@close="closeModal"
		hideFooter
		size="xl"
		@bv::modal::hidden="closeModal"
	>
		<template v-slot:header>
			<h5 class="modal-title" id="modalTitle">Create Post</h5>
		</template>
		<b-overlay :show="isLoading" rounded="sm">
			<b-container class="m-0 p-0">
				<b-row>
					<b-col lg="7">
						<input
							type="file"
							ref="imageFile"
							style="display: none"
							accept="image/*"
							@change="handleImage"
						/>
						<input
							type="file"
							ref="videoFile"
							style="display: none"
							accept="video/mp4"
							@change="handleVideo"
						/>
						<b-row>
							<b-col>
								<Card class="mb-0">
									<BaseInput
										class="b-0"
										label="Post Description"
										v-model="description"
										type="textarea"
										rows="9"
										:maxLength="500"
										:withPadding="false"
										placeholder="Enter your message..."
									>
									</BaseInput>
									<div class="mt-1">
										<h6 class="text-right">
											{{ description != null ? description.length : 0 }}/500
										</h6>
									</div>
									<div v-if="false">
										<BaseButton
											type="link"
											class="p-0"
											v-b-popover.hover.top="'Popover content'"
											title="Popover title"
											><p class="h3 m-0"><icon class="far fa-smile"></icon></p>
										</BaseButton>
										<BaseButton type="link" class="p-0 mx-2"
											><p class="h3 m-0"><icon class="fa fa-map-pin"></icon></p>
										</BaseButton>
										<BaseButton type="link" class="p-0 mx-2"
											><p class="h3 m-0"><icon class="fa fa-link"></icon></p>
										</BaseButton>
									</div>
									<hr class="my-2" />
									<div class="py-1">
										<label class="mb-0 form-control-label">Media</label>
										<h6 class="mb-2">Share photos or a video.</h6>
										<b-row v-if="gotContent()" class="my-2">
											<b-col>
												<b-avatar
													v-if="imageContent"
													thumbnail
													fluid
													rounded
													size="5rem"
													:src="imageContent"
												></b-avatar>
												<b-container
													style="
														background-color: black;
														border-radius: 5px;
														overflow: hidden;
													"
												>
													<b-embed v-if="videoContent" type="video" height="5rem" controls>
														<source :src="videoContent" />
													</b-embed>
												</b-container>
											</b-col>
											<b-col class="text-right">
												<BaseButton
													icon="fa fa-edit"
													size="sm"
													type="secondary"
													@click="
														videoContent != null
															? $refs.videoFile.click()
															: $refs.imageFile.click()
													"
												></BaseButton>
												<BaseButton
													icon="fa fa-trash"
													size="sm"
													type="secondary"
													@click="removeContent()"
												></BaseButton>
											</b-col>
										</b-row>
										<div v-if="!gotContent()">
											<BaseButton
												icon="fa fa-image"
												size="sm"
												@click="$refs.imageFile.click()"
												>Add Photo</BaseButton
											>
											<BaseButton
												icon="fa fa-video"
												size="sm"
												class="mx-2"
												disabled
												@click="$refs.videoFile.click()"
												>Upload Video (soon)</BaseButton
											>
										</div>
									</div>
									<hr class="my-2" />
									<div class="py-1">
										<b-row class="mt-0 align-items-center">
											<b-col cols="auto" class="mr-auto">
												<label class="mb-0 form-control-label">Schedule Post (soon)</label>
												<h6 class="mb-2">
													You can schedule your post with the options below.
												</h6>
											</b-col>
											<b-col cols="auto">
												<BaseSwitch v-model="isScheduled" isDisabled></BaseSwitch>
											</b-col>
										</b-row>
										<div v-if="isScheduled" class="my-2">
											<BaseInput
												class="mb-0"
												type="datetime-local"
												v-model="scheduleDate"
												:required="isScheduled"
												id="schedule time"
												:min="minDate"
											>
											</BaseInput>
											<h6 class="mt-0 pt-0">
												TIP: Please allow an additional 10-15 minutes for schedule
												notifications to trigger
											</h6>
										</div>
									</div>
								</Card>
							</b-col>
						</b-row>
					</b-col>
					<b-col class="d-lg-block d-none" lg="5">
						<Card
							v-if="!showPreview"
							style="background-color: #dedede; min-height: 100%"
							:style="[{ position: !showPreview ? 'relative' : '' }]"
						>
							<div
								class="text-center"
								style="
									position: absolute;
									top: 50%;
									left: 50%;
									transform: translate(-50%, -50%);
								"
							>
								<h4 class="mb-0"><strong>Preview not available</strong></h4>
								<h6>Add media or text to preview your post</h6>
							</div>
						</Card>
						<Card v-else style="background-color: #dedede; min-height: 100%">
							<FeedItem
								:description="description"
								:videoContent="videoContent"
								:imageContent="imageContent"
								isNew
							/>
						</Card>
					</b-col>
				</b-row>
			</b-container>
			<b-row class="mr-0 mt-3 mb-3">
				<div style="flex-grow: 1"></div>
				<BaseButton
					type="primary"
					:loading="isLoading"
					:shake="shake"
					@click="sendPost"
					:disabled="!isReadyToSend()"
					icon="fa fa-paper-plane"
				>
					Send Post</BaseButton
				>
			</b-row>
		</b-overlay>
	</Modal>
</template>
<script>
import { mapActions } from 'vuex';
import BaseButton from '../../../components/BaseButton.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import Card from '../../../components/Cards/Card.vue';
import FeedItem from '../../../components/Feed/FeedItem.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import Modal from '../../../components/Modals/Modal.vue';
import PostItemManager from '../../../models/PostItem/PostItemManager';

export default {
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: { Modal, BaseInput, BaseButton, Card, FeedItem, BaseSwitch },
	data() {
		return {
			isLoading: false,
			shake: false,
			description: null,
			imageContent: null,
			imageFile: null,
			videoContent: null,
			videoFile: null,
			videoThumbnailContent: null,
			showPreview: false,
			isScheduled: false,
			scheduleDate: null,
			minDate: new Date(),
		};
	},
	watch: {
		showModal: {
			handler(val) {
				return this.resetData();
			},
		},
		description: {
			handler(val) {
				return this.getPreview();
			},
		},
		imageContent: {
			handler(val) {
				this.videoContent = null;
				this.videoFile = null;
				this.videoThumbnailContent = null;
				return this.getPreview();
			},
		},
		videoContent: {
			handler(val) {
				this.imageContent = null;
				this.imageFile = null;
				return this.getPreview();
			},
		},
	},
	methods: {
		...mapActions(['showNotification']),
		sendPost() {
			this.isLoading = true;
			PostItemManager.addPostItem(
				this.description,
				this.imageContent != null ? this.imageFile : null,
				this.videoContent != null ? this.videoFile : null
			)
				.then(() => {
					this.isLoading = false;
					this.showNotification({
						type: 'success',
						message: 'Post Added.',
					});
					this.$emit('onNewPost');
					this.closeModal();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		isReadyToSend() {
			if (this.$helpers.isBlank(this.description) && !this.gotContent()) {
				return false;
			} else if (this.isScheduled && this.scheduleDate == null) {
				return false;
			}
			return true;
		},
		gotContent() {
			return this.imageContent != null || this.videoContent != null;
		},
		removeContent() {
			this.imageContent = null;
			this.imageFile = null;
			this.videoContent = null;
			this.videoFile = null;
			this.getPreview();
		},
		getPreview() {
			this.showPreview =
				!this.$helpers.isBlank(this.description) || this.gotContent();
		},
		handleImage(event) {
			const files = event.target.files;
			if (!files.length) {
				this.imageContent = null;
				this.imageFile = null;
				return;
			}
			this.imageFile = files[0];
			this.createThumbnail(files[0]);
		},
		handleVideo(event) {
			const files = event.target.files;
			if (!files.length) {
				this.videoContent = null;
				this.videoFile = null;
				return;
			}
			this.videoFile = files[0];
			this.createVideoPreview(files[0]);
		},
		createThumbnail(file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				this.imageContent = e.target.result;
			};
			reader.readAsDataURL(file);
		},
		createVideoPreview(file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				this.videoContent = e.target.result;
				this.createVideoThumbnail((image) => {
					console.log('image, ', image);
				});
			};
			reader.readAsDataURL(file);
		},

		openImagePicker() {
			this.$refs.formFile.$el.click();
		},
		resetData() {
			this.isLoading = false;
			this.description = null;
			this.imageContent = null;
			this.imageFile = null;
			this.videoContent = null;
			this.videoFile = null;
			this.videoThumbnailContent = null;
			this.showPreview = false;
			this.isScheduled = false;
			this.scheduleDate = null;
			this.minDate = new Date();
		},
		closeModal() {
			//if (!this.isLoading) {
			this.resetData();
			this.$emit('update:show', false);
			this.$emit('close');

			//}
		},
	},
};
</script>
