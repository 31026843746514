<template>
	<div>
		<VueAspectRatio
			class="form-control form-control-auto p-0 m-0"
			:ar="ar"
			style="overflow: hidden"
			:style="{
				borderRadius: circle ? '50%' : '',
			}"
		>
			<input
				id="fileUploader"
				type="file"
				:accept="acceptedFiles"
				@change="onFileChanged($event)"
				hidden
			/>
			<BaseButton
				v-if="!thumbnail"
				style="height: 100%"
				block
				class="text-muted"
				type="null"
				@click="openFiles()"
				><i class="fa fa-file-upload"></i>{{ uploadDefaultText }}
			</BaseButton>
			<div
				@mouseover="imageHover = true"
				@mouseleave="imageHover = false"
				class="b-img-helpers"
			>
				<div
					class="b-img-helpers card-img-top"
					:style="{ opacity: imageHover ? 0.4 : 1 }"
				>
					<div v-if="!isFile">
						<b-img-lazy fluid-grow :src="thumbnail" class="b-img-background-blur" />
						<div class="b-img-overlay"></div>
						<b-img-lazy
							fluid-grow
							:src="thumbnail"
							class="b-img-base"
							:style="{
								objectFit: ifImageFill ? 'cover' : 'contain',
							}"
						/>
					</div>
					<b-container v-else class="b-img-base text-center text-primary">
						<div
							style="
								min-width: 100%;
								top: 50%;
								left: 0;
								margin: 0;
								position: absolute;
								-ms-transform: translateY(-50%);
								transform: translateY(-50%);
							"
						>
							<h1 class="m-0 p-0 text-primary">
								<i class="fa fa-check-circle"></i>
							</h1>
							<h4 class="m-0 p-0 text-primary font-weight-bold">File Uploaded</h4>
						</div>
					</b-container>
				</div>
				<div
					v-if="imageHover"
					style="
						height: 100%;
						width: 100%;
						border: dashed 1px black;
						display: flex;
						flex-wrap: wrap;
						align-content: center;
					"
					class="justify-content-center card-img-top b-img-base"
				>
					<BaseButton type="medium" size="sm" block squared @click="openFiles()"
						>Edit</BaseButton
					>
				</div>
			</div>
			<img
				v-if="thumbnail"
				style="width: 100%; height: 100%; object-fit: cover"
				:src="thumbnail"
			/>
		</VueAspectRatio>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import VueDropZone from 'vue2-dropzone';
import BaseButton from '../BaseButton.vue';
export default {
	props: {
		circle: Boolean,
		ar: {
			type: String,
			default: '16:9',
		},
		acceptedFiles: {
			type: String,
			default: 'image/*',
		},
		uploadDefaultText: {
			type: String,
			default: 'UPLOAD IMAGE',
		},
		isFile: {
			type: Boolean,
			default: false,
		},
		ifImageFill: {
			type: Boolean,
			default: false,
		},
	},
	components: { VueAspectRatio, VueDropZone, BaseButton },
	data() {
		return {
			thumbnail: null,
			imageHover: false,
		};
	},
	methods: {
		onFileChanged(event) {
			this.thumbnail = window.URL.createObjectURL(event.target.files[0]);
			this.$emit('onFile', event.target.files[0]);
		},
		openFiles() {
			document.getElementById('fileUploader').click();
		},
	},
};
</script>
