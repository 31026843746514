<template>
	<div v-if="eventData">
		<DashboardHeader
			:title="eventData.eventTitle"
			:buttonItems="buttonItems"
			@childClick="childClicked" 
		/>
		<BaseContent>
			<EventUserTable
				:headerTitle="'Attendees'"
				:isLoading="isLoading"
				:tableData="users"
				:total="eventData.eventUsers != null ? eventData.eventUsers : 0"
				@currentPage="currentPage"
				@viewClicked="viewClicked"
			/>
		</BaseContent>
	</div>
</template>
<script>
import BaseContent from '../../../components/BaseContent.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import AppUserManager from '../../../models/AppUser/AppUserManager';
import EventItemManager from '../../../models/EventItem/EventItemManager';
import { DashboardChildren } from '../../../routes/routesNames';
import EventUserTable from '../../SecondaryComponents/EventViews/EventUserTable.vue';

export default {
	props: {
		eventId: {
			type: String,
			default: null,
		},
	},
	components: { DashboardHeader, BaseContent, EventUserTable },
	data() {
		return {
			isLoading: false,
			eventData: null,
			users: [],
			usersCount: 0,
			lastFetched: null,
			selectedId: null,
			addAttendeesId: 0,
			exportDataId: 1,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		getEventUsers(maxCount) {
			this.isLoading = true;
			EventItemManager.getEventUsers(this.eventId, this.lastFetched, maxCount)
				.then(([users, lastDocument]) => {
					this.users.push.apply(this.users, users);
					this.lastFetched = lastDocument;
					this.isLoading = false;
					this.setButtons();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		currentPage(index) {
			const minCount = AppUserManager.limitFetch * (index - 1);
			const maxCount = AppUserManager.limitFetch * index;
			const inBetween = maxCount - this.users.length;
			if (minCount >= this.users.length && this.lastFetched != null) {
				const value =
					inBetween > AppUserManager.limitFetch
						? inBetween
						: AppUserManager.limitFetch;
				this.getEventUsers(value);
			}
		},
		getEventData() {
			this.isLoading = true;
			EventItemManager.getEventById(this.eventId).then((result) => {
				if (result) {
					this.isLoading = false;
					this.eventData = result;
					this.getEventUsers(null);
				} else {
					this.navigateToEvents();
				}
			});
		},
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: this.addAttendeesId,
					title: 'Add Attendees',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.exportDataId,
					title: 'Export Data',
				},
			];
		},
		childClicked(childClicked) {
			this.selectedId = childClicked.index;
		},
		viewClicked(data) {
			const userData = data.userData;
			this.$router.push({
				name: DashboardChildren.memberView.name,
				params: {
					userData: userData,
				},
				query: {
					uid: userData.uid,
				},
			});
		},
		navigateToEvents() {
			this.$router.replace({
				name: DashboardChildren.events.name,
				params: { refresh: true },
			});
		},
	},

	mounted() {
		if (!this.eventId) {
			this.navigateToEvents();
		} else if (this.$route.params.eventData) {
			this.eventData = this.$route.params.eventData;
			this.getEventUsers(null);
		} else {
			this.getEventData();
		}
	},
};
</script>
