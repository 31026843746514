import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';

Vue.use(Vuex);

const initialState = () => {
	return {
		user: null,
		organization: null,
		notification: null,
		lastLoggedIn: null,
	};
};

export default new Vuex.Store({
	state: initialState(),
	mutations: mutations,
	getters: getters,
	actions: actions,
});
