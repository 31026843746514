import {
	collection,
	doc,
	getDoc,
	getDocs,
	query,
	where,
} from 'firebase/firestore';
import { firestore, functions } from '../../resources/firebase';
import FirebaseManager from '../Firebase/FirebaseManager';
import store from '../../store/store';
import { MySundayInformation } from '../../resources/MySundayInformation';
import { httpsCallable } from 'firebase/functions';

export default class AppOrganizationBillingManager {
	static createBillingPortal() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const createBillingPortal = httpsCallable(functions, 'createBillingPortal');
			const dataInfo = {
				orgId: orgId,
			};
			createBillingPortal(dataInfo)
				.then((result) => {
					return resolve(result.data ? result.data.url : null);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static createPaymentMethod(paymentMethodId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const billingRef = doc(
				firestore,
				`${FirebaseManager.organizationBillingRef().path}/${orgId}`
			);
			getDoc(billingRef)
				.then((snapshot) => {
					if (snapshot.exists()) {
						const billingData = snapshot.data();
						const createOrgPaymentMethod = httpsCallable(
							functions,
							'createOrgPaymentMethod'
						);
						const dataInfo = {
							paymentMethodId: paymentMethodId,
							customerId: billingData.customer,
							orgId: orgId,
							isTestMode: MySundayInformation.isTestMode,
						};
						createOrgPaymentMethod(dataInfo)
							.then((result) => {
								return resolve();
							})
							.catch((error) => {
								return reject(error.message);
							});
					} else {
						return reject('Unable to fetch billing account');
					}
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getOrgPlanOption() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const billingRef = doc(
				firestore,
				`${FirebaseManager.organizationBillingRef().path}/${orgId}`
			);
			getDoc(billingRef)
				.then((snapshot) => {
					if (snapshot.exists()) {
						const planId = snapshot.data().planId;
						const productRef = FirebaseManager.accountProductsRef();
						const queryRef = query(productRef, where('planId', '==', planId));
						getDocs(queryRef)
							.then((planSnapshot) => {
								const planData = planSnapshot.docs[0].data();
								return resolve(planData);
							})
							.catch((error) => {
								return resolve(null);
							});
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static getAllOrgPlans() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const productRef = FirebaseManager.accountProductsRef();
			getDocs(productRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					var counter = 0;
					var items = [];
					if (counter == total) {
						return resolve(items);
					}
					documents.forEach((document) => {
						var planData = document.data();
						items.push(planData);
						if (counter == total - 1) {
							return resolve(items);
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getDefaultPaymentMethod() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const billingRef = doc(
				firestore,
				`${FirebaseManager.organizationBillingRef().path}/${orgId}`
			);
			getDoc(billingRef)
				.then((snapshot) => {
					return resolve(snapshot.exists() ? snapshot.data().defaultPayment : null);
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}
}
