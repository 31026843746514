import { format } from 'date-fns-tz';
import { Timestamp } from 'firebase/firestore';

export default {
	isBlank(str) {
		return !str || /^\s*$/.test(str);
	},
	isBlankObject(obj){
		return Object.keys(obj).length === 0
	},
	returnTimestamp() {
		return Timestamp.now();
	},
	returnFirebaseDate(value) {
		if (value instanceof Timestamp) {
			return value.toDate();
		} else if (typeof value == 'number' || value instanceof number) {
			return new Date(value);
		}
		return null;
	},
	capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	},
	amountToString(amount, noDivide) {
		return Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
		}).format(noDivide ? amount : amount / 100);
	},
	openUrl(link) {
		var open = window.open(link, '_blank');
		if (open == null || typeof open == 'undefined') {
			alert('Turn off your pop-up blocker');
		} else {
			open.focus();
		}
	},
	getNullOrValue(value) {
		if (value === undefined || value === null || this.isBlank(value)) {
			return null;
		}
		return value;
	},
	urlify(text) {
		var urlRegex = /(https?:\/\/[^\s]+)/g;
		return text.replace(urlRegex, function (url) {
			return '<a target="_blank" href="' + url + '">' + url + '</a>';
		});
		// or alternatively
		// return text.replace(urlRegex, '<a href="$1">$1</a>')
	},
	formatDate(value, formatString) {
		if (value == null) {
			return null;
		}
		if (value instanceof Timestamp) {
			value = value.toDate();
		} else if (!value instanceof Date) {
			return null;
		}
		var dateString = formatString;
		if (dateString == null) {
			dateString = 'MMMM dd, yyyy';
		}
		return format(value, dateString);
	},
	replaceAllNullValues(obj, replaceValue) {
		Object.entries(obj).forEach(([key, value]) => {
			if (value === undefined || value === null || this.isBlank(value)) {
				obj[key] = replaceValue;
			} else if (!!value && typeof value === 'object') {
				this.replaceAllNullValues(value, replaceValue);
			}
		});
		return obj;
	},
	removeAllNullValues(obj) {
		const CLEANER_VALUES = [null, undefined, ''];
		const cleanedObj = { ...obj };
		Object.keys(cleanedObj).forEach((key) => {
			if (CLEANER_VALUES.includes(cleanedObj[key])) {
				delete cleanedObj[key];
			}
		});
		return cleanedObj;
	},
	timeSince(date) {
		var seconds = Math.floor((new Date() - date) / 1000);

		var interval = seconds / 31536000;

		if (interval > 1) {
			const years = Math.floor(interval);
			if (years == 1) {
				return years + ' year ago';
			}
			return years + ' years ago';
		}
		interval = seconds / 2592000;
		if (interval > 1) {
			const months = Math.floor(interval);
			if (months == 1) {
				return months + ' month ago';
			}
			return months + ' months ago';
		}
		interval = seconds / 86400;
		if (interval > 1) {
			const days = Math.floor(interval);
			if (days == 1) {
				return days + ' day ago';
			}
			return days + ' days ago';
		}
		interval = seconds / 3600;
		if (interval > 1) {
			const hours = Math.floor(interval);
			if (hours == 1) {
				return hours + ' hour ago';
			}
			return hours + ' hours ago';
		}
		interval = seconds / 60;
		if (interval > 1) {
			const minutes = Math.floor(interval);
			if (minutes == 1) {
				return minutes + ' minute ago';
			}
			return minutes + ' minutes ago';
		}
		interval = seconds;
		if (interval >= 0) {
			const seconds = Math.floor(interval);
			if (seconds == 1) {
				return seconds + ' sec ago';
			}
			return seconds + ' sec ago';
		} else {
			return this.formatDate(date, "MMMM dd, yyyy 'at' hh:mm a");
		}
	},
	getVueDropZoneOptions() {
		const dropzoneOptions = {
			url: 'https://httpbin.org/post',
			thumbnailWidth: 100,
			height: 100,
			autoProcessQueue: false,
			previewsContainer: false,
			addRemoveLinks: true,
			maxFiles: 1,
			acceptedFiles: 'image/*',
			dictDefaultMessage: "<i class='fa fa-cloud-upload'></i> UPLOAD IMAGE",
		};
		return dropzoneOptions;
	},
};
