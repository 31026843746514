<template>
	<div>
		<Card no-body class="m-0">
			<div
				class="form-control-auto form-control"
				style="background-color: #f8f8f8"
			>
				<b-row>
					<b-col>
						<BaseInput
							id="filter-input"
							type="search"
							class="m-0 p-0"
							placeholder="Type to Search"
							v-model="filter"
							:placeholder="searchPlaceholder"
							@keyup="filterIcons($event)"
						></BaseInput>
					</b-col>
				</b-row>
				<hr class="my-1" />
				<b-row class="p-2 iconPicker__body">
					<b-col>
						<div class="iconPicker__icons">
							<a
								href="#"
								@click.stop.prevent="getIcon(icon.value, icon.name)"
								:class="`item ${selected === icon.name ? 'selected' : ''}`"
								v-for="icon in isSearching ? filteredIcons : icons"
								:key="icon.value"
							>
								<i :class="'fas fa-' + icon.name"></i>
							</a>
						</div>
					</b-col>
				</b-row>
			</div>
		</Card>
	</div>
</template>
<script>
import semver from 'semver';
import Card from '../Cards/Card.vue';
import BaseInput from '../Inputs/BaseInput.vue';
import rawIcons from './icons.json';

export default {
	name: 'fontAwesomePicker',
	props: ['seachbox'],
	data() {
		return {
			isSearching: false,
			filter: null,
			selected: '',
			icons: [],
			filteredIcons: [],
		};
	},
	computed: {
		searchPlaceholder() {
			return this.seachbox || 'search box';
		},
	},
	methods: {
		getIcon(icon, key) {
			this.selected = key;
			// NEED TO FIX CONVERT METHOD
			// this.convert(icon);
			this.selectIcon(icon.toUpperCase());
		},
		convert(value) {
			const newValue = value.charCodeAt(1).toString(10).replace(/\D/g, '');
			let hexValue = Number(newValue).toString(16);
			while (hexValue.length < 4) {
				hexValue = `0${hexValue}`;
			}
		},
		selectIcon(value) {
			const result = {
				name: this.selected,
				hardValue: value,
			};
			this.$emit('selectIcon', result);
		},
		filterIcons(event) {
			const search = event.target.value.trim();
			this.isSearching = search.length > 0;
			if (this.isSearching) {
				this.filteredIcons = this.icons.filter((item) => {
					return item.name.includes(search);
				});
			}
		},
	},
	mounted() {
		Object.entries(rawIcons).forEach((entry) => {
			const key = entry[0];
			const value = entry[1];
			if (value.styles[0] == 'solid') {
				const versions = value.changes;
				var satifys = false;
				versions.forEach((version) => {
					const cleaned = semver.clean(version);
					if (semver.satisfies(cleaned, '1.0.0 || <=5.8.0')) {
						satifys = true;
					}
				});
				if (satifys) {
					this.icons.push({
						name: key,
						value: value.unicode,
					});
				}
			}
		});
	},
	components: { BaseInput, Card },
};
</script>
<style>
#iconPicker {
	position: relative;
	max-width: 292px;
	background: #f8f8f8;
}
.iconPicker__header {
	padding: 1em;
	border-radius: 8px 8px 0 0;
	border: 1px solid #ccc;
}
.iconPicker__header input {
	width: 100%;
}
.iconPicker__body {
	position: relative;
	max-height: 250px;
	overflow: auto;
}
.iconPicker__icons {
	display: table;
}
.iconPicker__icons .item {
	float: left;
	width: 40px;
	height: 40px;
	padding: 12px;
	margin: 0 12px 12px 0;
	text-align: center;
	border-radius: 3px;
	font-size: 14px;
	box-shadow: 0 0 0 1px #ddd;
	color: inherit;
}
.iconPicker__icons .item.selected {
	background: #ccc;
}
.iconPicker__icons .item i {
	box-sizing: content-box;
}
</style>

EXAMPLE: const icons = [ { name: 'address-book', value: 'f2b9', }, ]
