<template>
	<div>
		<BaseTable
			:isLoading="isLoading"
			:isFixed="false"
			:filterOption="audioData.length > 0"
			:algoliaSearch="false"
			:tableHeaders="tableHeaders"
			:tableData="audioData"
			:total="audioData.length"
			@currentPage="currentPage"
			@viewClicked="viewClicked"
			@actionDropdownClicked="actionDropdownClicked"
		/>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseTable from '../../../components/Tables/BaseTable.vue';

export default {
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		audioData: {
			type: Array,
			default: [],
		},
	},
	components: { Card, BaseTable },
	data() {
		return {
			tableHeaders: [
				{
					key: 'namePhotoRatio',
					label: 'Title',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.audioTitle;
					},
					formatter: (value, key, item) => {
						return {
							name: item.audioTitle,
							photo: item.imageItem ? item.imageItem[0].imageUrl : null,
							ar: '1:1',
							size: 'sm',
						};
					},
				},
				{
					key: 'audioArtist',
					label: 'Artist',
					sortable: true,
					tdClass: 'align-middle',
				},
				{
					key: 'actions',
					label: '',
					thStyle: { width: '5%' },
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return [
							{
								title: 'View Audio',
								click: 'viewClicked',
							},
							{
								isDivider: true,
							},
							{
								title: 'Delete Audio',
								click: 'deleteClicked',
								variant: 'danger',
							},
						];
					},
				},
			],
		};
	},
	methods: {
		actionDropdownClicked(item) {
			const click = item.item.click;
			const audioItem = item.row;
			if (click == 'viewClicked') {
				this.viewClicked(audioItem);
			} else if (click == 'deleteClicked') {
				this.deleteClicked(audioItem);
			}
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		currentPage(index) {
			this.$emit('currentPage', index);
		},
	},
};
</script>
