<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<BaseContent>
			<GroupTable
				:isLoading="isLoading"
				:groupsData="groups"
				@currentPage="currentPage"
				@viewClicked="viewClicked"
			/>
		</BaseContent>
	</div>
</template>
<script>
import BaseContent from '../../../components/BaseContent.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import { DashboardChildren } from '../../../routes/routesNames';
import GroupTable from '../../SecondaryComponents/GroupViews/GroupTable.vue';
import GroupItemManager from '../../../models/GroupItem/GroupItemManager';
import { mapActions } from 'vuex';

export default {
	components: { DashboardHeader, BaseContent, GroupTable },
	data() {
		return {
			isLoading: false,
			showModal: false,
			groups: [],
			lastFetched: null,
			buttonItems: [
				{
					index: 0,
					title: 'New Group',
					icon: 'fa fa-plus',
					type: 'primary',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getGroups(maxCount) {
			this.isLoading = true;
			GroupItemManager.getOrgGroups(this.lastFetched, maxCount).then((items) => {
				this.groups.push.apply(this.groups, items.items);
				this.lastFetched = items.lastCursor;
				this.isLoading = false;
			});
		},
		currentPage(index) {
			const minCount = GroupItemManager.limitFetch * (index - 1);
			const maxCount = GroupItemManager.limitFetch * index;
			const inBetween = maxCount - this.groups.length;
			if (minCount >= this.groups.length && this.lastFetched != null) {
				const value =
					inBetween > GroupItemManager.limitFetch
						? inBetween
						: GroupItemManager.limitFetch;
				this.getGroups(value);
			}
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.openNewGroup();
			}
		},
		openNewGroup() {
			this.$router.push({
				name: DashboardChildren.newGroupView.name,
			});
		},
		viewClicked(groupData) {
			this.$router.push({
				name: DashboardChildren.groupView.name,
				params: {
					groupId: groupData.groupId,
					groupData: groupData,
				},
			});
		},
	},
	mounted() {
		this.getGroups(null);
	},
};
</script>
