<template>
	<b-row v-if="notificationData">
		<b-col cols="auto">
			<b-img
				alt="Image placeholder"
				:src="organization.orgLogo"
				class="avatar"
				rounded="circle"
			/>
		</b-col>
		<b-col class="text-left p-0">
			<div class="d-flex align-items-center">
				<div>
					<h4 class="mb-0 mr-1 text-sm">{{ organization.orgName }}</h4>
				</div>
				<div class="text-right text-muted">
					<small>· {{ notificationTimestamp() }} </small>
				</div>
			</div>
			<span style="white-space: pre-line">
				<div
					v-if="notificationText()"
					class="text-sm"
					v-html="notificationText()"
				></div>
			</span>
		</b-col>
	</b-row>
</template>
<script>
import { mapGetters } from 'vuex';
import Card from '../../../components/Cards/Card.vue';

export default {
	props: {
		notificationData: {
			type: Object,
			default: null,
		},
	},
	components: { Card },
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	methods: {
		notificationTimestamp() {
			return this.notificationData.created
				? this.$helpers.timeSince(this.notificationData.created.toDate())
				: this.notificationData.scheduleDate
				? this.$helpers.timeSince(this.notificationData.scheduleDate.toDate())
				: null;
		},
		notificationText() {
			return this.notificationData.notificationText;
		},
	},
};
</script>
