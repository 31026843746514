import {
	collection,
	deleteDoc,
	doc,
	getCountFromServer,
	getDocs,
	setDoc,
} from 'firebase/firestore';
import { firestore, storage } from '../../resources/firebase';
import FirebaseManager from '../Firebase/FirebaseManager';
import store from '../../store/store';
import { deleteObject, listAll, ref } from 'firebase/storage';

export default class FeaturedItemManager {
	static getOrgFeaturedRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/featured`
		);
	}

	static canUploadFeatured() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const featuredRef = this.getOrgFeaturedRef(organization.orgId);
			getCountFromServer(featuredRef)
				.then((snapshot) => {
					//TO DO: check if organization's billing account allows more...
					const maxAllowed = 8;
					return resolve(snapshot.data().count < maxAllowed);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static canUploadFeatureButton() {
		return new Promise((resolve, reject) => {});
	}

	static getOrgFeatured() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const featuredRef = this.getOrgFeaturedRef(organization.orgId);
			getDocs(featuredRef)
				.then((snapshot) => {
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						var counter = 0;
						var items = [];
						documents.forEach((document) => {
							var featuredData = document.data();
							items.push(featuredData);
							if (counter == total - 1) {
								items.sort((item1, item2) => item1.index - item2.index);
								return resolve(items);
							}
							counter += 1;
						});
					} else {
						return resolve(null);
					}
				})
				.catch((error) => {
					return reject(error.messege);
				});
		});
	}

	static addFeaturedItem(featuredFile, featuredIndex, featuredLink) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const newRef = doc(this.getOrgFeaturedRef(orgId));
			const storageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/featured/${
					newRef.id
				}/image:${newRef.id}.jpeg`
			);

			FirebaseManager.uploadImage(storageRef, featuredFile)
				.then((downloadURL) => {
					var featuredData = {
						created: FirebaseManager.timestamp(),
						featuredId: newRef.id,
						index: featuredIndex - 1,
						orgId: orgId,
						imageItem: {
							imageUrl: downloadURL,
						},
					};
					if (featuredLink) {
						featuredData['featuredLink'] = featuredLink;
					}
					setDoc(newRef, featuredData)
						.then(() => {
							return resolve(featuredData);
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((errorMessage) => {
					return reject(errorMessage);
				});
		});
	}

	static deleteFeaturedItem(featuredId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const storageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/featured/${featuredId}`
			);

			FirebaseManager.deleteStorageRef(storageRef).finally(() => {
				return this.completeFeatureDelete(featuredId)
					.then(() => {
						return resolve();
					})
					.catch((errorMessage) => {
						return reject(errorMessage);
					});
			});
		});
	}

	static completeFeatureDelete(featuredId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const featuredRef = doc(
				firestore,
				`${FirebaseManager.organizationRef().path}/${orgId}/featured/${featuredId}`
			);
			deleteDoc(featuredRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
