<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<BaseContent>
			<Card no-body>
				<b-tabs
					active-nav-item-class="bg-white font-weight-bold description"
					nav-class="bg-white description"
					card
					no-body
				>
					<b-tab title="Admin" v-if="false">
						<b-list-group>
							<b-list-group-item v-for="notificationData in adminNotifications">
								<NotificationCard :notificationData="notificationData" />
							</b-list-group-item>
						</b-list-group>
					</b-tab>
					<b-tab :title="this.organization.orgName" active>
						<b-list-group>
							<b-list-group-item v-for="notificationData in allNotifications">
								<NotificationCard :notificationData="notificationData" />
							</b-list-group-item>
						</b-list-group>
					</b-tab>

					<b-tab title="Scheduled">
						<b-list-group>
							<b-list-group-item v-for="notificationData in allScheduledNotification">
								<NotificationCard :notificationData="notificationData" />
							</b-list-group-item>
						</b-list-group>
					</b-tab>
				</b-tabs>
			</Card>
		</BaseContent>
		<NewNotificationModal
			:headerTitle="buttonItems[0].title"
			:showModal="showModal"
			@close="showModal = false"
			@newNotification="onNewNotification"
		/>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseContent from '../../../components/BaseContent.vue';
import Card from '../../../components/Cards/Card.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import NotificationItemManager from '../../../models/NotificationItem/NotificationManager';
import NewNotificationModal from '../../SecondaryComponents/NotificationViews/NewNotificationModal.vue';
import NotificationCard from '../../SecondaryComponents/NotificationViews/NotificationCard.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: {
		DashboardHeader,
		BaseContent,
		NotificationCard,
		NewNotificationModal,
		Card,
	},
	data() {
		return {
			isLoading: true,
			isScheduleLoading: true,
			showModal: false,
			adminNotifications: [],
			allNotifications: [],
			allScheduledNotification: [],
			lastCursor: null,
			lastScheduledCursor: null,
			lastAdminCursor: null,
			buttonItems: [
				{
					index: 0,
					title: 'New Notification',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			],
		};
	},
	methods: {
		getNotifications() {
			NotificationItemManager.getAdminNotifications(this.lastAdminCursor)
				.then((data) => {
					this.isLoading = false;
					this.adminNotifications = data.items;
					this.lastAdminCursor = data.lastCursor;
				})
				.catch((error) => {
					this.isLoading = false;
				});
			NotificationItemManager.getNotifications(this.lastCursor)
				.then((data) => {
					this.isLoading = false;
					this.allNotifications = data.items;
					this.lastCursor = data.lastCursor;
				})
				.catch((error) => {
					this.isLoading = false;
				});
		},
		getScheduledNotifications() {
			this.isScheduleLoading = true;
			NotificationItemManager.getScheduledNotifications(this.lastScheduledCursor)
				.then((data) => {
					this.isScheduleLoading = false;
					this.allScheduledNotification = data.items;
					this.lastScheduledCursor = data.lastCursor;
				})
				.catch((error) => {
					this.isScheduleLoading = false;
				});
		},
		onNewNotification(data) {
			this.showModal = false;
			if (data.created) {
				this.allNotifications.unshift(data);
			} else if (data.scheduleDate) {
				this.allScheduledNotification.unshift(data);
			}
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.showModal = !this.showModal;
			}
		},
	},
	mounted() {
		this.getNotifications();
		this.getScheduledNotifications();
	},
};
</script>
