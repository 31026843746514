<template>
	<div>
		<DashboardHeader :title="$route.name" />
	</div>
</template>
<script>
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';

export default { components: { DashboardHeader } };
</script>
