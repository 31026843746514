import Login from '../views/BaseViews/Login.vue';
import Dashboard from '../views/DashboardViews/Dashboard.vue';
import Home from '../views/BaseViews/Home.vue';
import Posts from '../views/BaseViews/Posts/Posts.vue';
import Events from '../views/BaseViews/Events/Events.vue';
import EventUserView from '../views/BaseViews/Events/EventUserView';
import EventView from '../views/BaseViews/Events/EventView.vue';
import Audios from '../views/BaseViews/Audios/Audios.vue';
import Videos from '../views/BaseViews/Videos.vue';
import Groups from '../views/BaseViews/Groups/Groups.vue';
import GroupUserView from '../views/BaseViews/Groups/GroupUserView';
import GroupView from '../views/BaseViews/Groups/GroupView.vue';
import Files from '../views/BaseViews/Files/Files.vue';
import Members from '../views/BaseViews/Members/Members.vue';
import MemberView from '../views/BaseViews/Members/MemberView.vue';
import Settings from '../views/BaseViews/Settings.vue';
import Notifications from '../views/BaseViews/Notifications/Notifications.vue';
import Marketing from '../views/BaseViews/Marketing/Marketing.vue'
import Register from '../views/BaseViews/Register.vue';
import NotFoundPage from '../views/BaseViews/NotFoundPage.vue';
import NewEventView from '../views/BaseViews/Events/NewEventView.vue';
import NewGroupView from '../views/BaseViews/Groups/NewGroupView.vue';

export const LoginPaths = {
	login: {
		id: 'login',
		name: 'Login',
		path: '/login',
		view: Login,
		meta: { alreadyAuth: true },
	},
	register: {
		id: 'register',
		name: 'Register',
		path: '/register/:token',
		view: Register,
		props: { default: (route) => ({ token: route.params.token }) },
	},
};

export const DashboardPaths = {
	home: {
		id: 'home',
		name: 'Dashboard',
		path: '/',
		redirect: '/dashboard',
		view: Dashboard,
		meta: { requiresAuth: true },
	},
	notFound: {
		id: 'home',
		name: 'Page Not Found',
		path: '*',
		view: NotFoundPage,
	},
};

export const DashboardChildren = {
	home: {
		id: 'home',
		name: 'Home',
		icon: 'fas fa-home',
		path: '/dashboard',
		view: Home,
	},

	giving: {
		id: 'giving',
		name: 'Giving',
		icon: 'fas fa-hand-holding-usd',
		path: '/dashboard/giving',
		isComingSoon: true,
	},
	events: {
		id: 'events',
		name: 'Events',
		icon: 'fas fa-calendar-alt',
		path: '/dashboard/events',
		view: Events,
	},
	eventView: {
		id: 'event',
		name: 'Event',
		path: '/dashboard/events/:eventId/',
		view: EventView,
		props: { default: (route) => ({ eventId: route.params.eventId }) },
	},
	eventUserView: {
		id: 'eventUserView',
		name: 'Event Users View',
		path: '/dashboard/events/:eventId/users',
		view: EventUserView,
		props: {
			default: (route) => ({
				eventId: route.params.eventId,
			}),
		},
	},
	audios: {
		id: 'audios',
		name: 'Audios',
		icon: 'fas fa-headphones',
		path: '/dashboard/audios',
		view: Audios,
	},
	videos: {
		id: 'videos',
		name: 'Videos',
		icon: 'fas fa-video',
		path: '/dashboard/videos',
		view: Videos,
	},
	groups: {
		id: 'groups',
		name: 'Groups',
		icon: 'fas fa-chalkboard-teacher',
		path: '/dashboard/groups',
		view: Groups,
	},
	groupView: {
		id: 'group',
		name: 'Group',
		path: '/dashboard/groups/:groupId/',
		view: GroupView,
		props: { default: (route) => ({ groupId: route.params.groupId }) },
	},
	groupUserView: {
		id: 'groupUserView',
		name: 'Group Users View',
		path: '/dashboard/groups/:groupId/users',
		view: GroupUserView,
		props: {
			default: (route) => ({
				groupId: route.params.groupId,
			}),
		},
	},
	files: {
		id: 'files',
		name: 'Files',
		icon: 'fas fa-file-contract',
		path: '/dashboard/files',
		view: Files,
	},
	community: {
		id: 'community',
		name: 'Community',
		path: '/dashboard/community',
		view: Posts,
	},
	members: {
		id: 'members',
		name: 'Members',
		icon: 'fas fa-user-friends',
		path: '/dashboard/members',
		view: Members,
	},
	memberView: {
		id: 'profile',
		name: 'Profile',
		path: '/dashboard/members/profile',
		view: MemberView,
		props: { default: (route) => ({ uid: route.query.uid }) },
	},

	reports: {
		id: 'reports',
		name: 'Reports',
		icon: 'fas fa-chart-bar',
		path: '/dashboard/reports',
		isComingSoon: true,
	},
	analytics: {
		id: 'analytics',
		name: 'Analytics',
		icon: 'fas fa-chart-line',
		path: '/dashboard/analytics',
		isComingSoon: true,
	},
	notifications: {
		id: 'notifications',
		name: 'Notifications',
		icon: 'fas fa-bell',
		path: '/dashboard/notifications',
		view: Notifications,
	},
	/*
	marketing: {
		id: 'marketing',
		name: 'Marketing',
		icon: 'fas fa-bell',
		path: '/dashboard/marketing',
		view: Marketing,
	},
	*/
	settings: {
		id: 'settings',
		name: 'Settings',
		icon: 'fas fa-cog',
		path: '/dashboard/settings',
		view: Settings,
	},
	newEventView: {
		id: 'new-event',
		name: 'New Event',
		path: '/dashboard/new-event',
		meta: { checkUpload: true },
		view: NewEventView,
	},
	newGroupView: {
		id: 'new-group',
		name: 'New Group',
		path: '/dashboard/new-group',
		meta: { checkUpload: true },
		view: NewGroupView,
	},
	/*
	
	
	schedule: {
		id: 'schedule',
		name: 'Schedule',
		icon: 'fas fa-calendar-alt',
		path: '/dashboard/schedule',
		view: Schedule,
		props: true,
	},
	eventView: {
		id: 'eventView',
		name: 'Event View',
		path: '/dashboard/schedule/event/',
		view: EventItemView,
		props: {
			default: (route) => ({
				eventId: route.query.eventId,
				eventStart: route.query.eventStart,
			}),
		},
	},
	eventViewUsers: {
		id: 'eventViewUsers',
		name: 'Event View Users',
		path: '/dashboard/schedule/event/:eventId/users',
		view: EventItemUsersView,
		props: {
			default: (route) => ({
				eventId: route.params.eventId,
				eventStart: route.query.eventStart,
			}),
		},
	},
	
	shop: {
		id: 'shop',
		name: 'Shop',
		icon: 'fas fa-shopping-bag',
		path: '/dashboard/shop',
		view: Shop,
	},
	shopView: {
		id: 'shopView',
		name: 'Shop View',
		path: '/dashboard/shop/product',
		view: ShopView,
		props: {
			default: (route) => ({
				productId: route.query.productId,
			}),
		},
	},
	classes: {
		id: 'classes',
		name: 'Classes',
		icon: 'fas fa-chalkboard-teacher',
		path: '/dashboard/classes',
		view: Classes,
	},
	classView: {
		id: 'classView',
		name: 'Class View',
		path: '/dashboard/classes/',
		view: ClassItemView,
		props: {
			default: (route) => ({
				classId: route.query.classId,
			}),
		},
	},
	classViewUsers: {
		id: 'classViewUsers',
		name: 'Class View Users',
		path: '/dashboard/classes/:classId/users',
		view: ClassItemUsersView,
		props: {
			default: (route) => ({
				classId: route.params.classId,
			}),
		},
	},
	newClassView: {
		id: 'newClass',
		name: 'New Class',
		path: '/dashboard/classes/newclass',
		view: NewClassView,
	},
	videos: {
		id: 'videos',
		name: 'Videos',
		icon: 'fas fa-video',
		path: '/dashboard/videos',
		view: Videos,
	},
	documents: {
		id: 'documents',
		name: 'Documents',
		icon: 'fas fa-file-contract',
		path: '/dashboard/documents',
		view: Documents,
	},
	billing: {
		id: 'billing',
		name: 'Billing',
		icon: 'fas fa-wallet',
		path: '/dashboard/billing',
		view: Billing,
	},
	attendance: {
		id: 'attendance',
		name: 'Attendance',
		icon: 'fas fa-calendar',
		path: '/dashboard/attendance',
		isHidden: true,
		isComingSoon: true,
		children: [
			{
				id: 'dailyAttendance',
				name: 'Daily Attendance',
				path: '/dashboard/attendance/daily',
				view: Classes,
			},
			{
				id: 'attendanceReport',
				name: 'Attendance Report',
				path: '/dashboard/attendance/report',
				view: Classes,
			},
		],
		view: Classes,
	},
	forms: {
		id: 'forms',
		name: 'Forms',
		icon: 'fas fa-edit',
		path: '/dashboard/forms',
		isComingSoon: true,
	},
	reports: {
		id: 'reports',
		name: 'Reports',
		icon: 'fas fa-bar-chart',
		path: 'dashboard/reports',
		isComingSoon: true,
	},
	payment: {
		id: 'payment',
		name: 'POS Payments',
		icon: 'fas fa-credit-card',
		path: 'dashboard/payments',
		isComingSoon: true,
	},
	members: {
		id: 'members',
		name: 'Members',
		icon: 'fas fa-user-friends',
		path: '/dashboard/members',
		view: Members,
	},
	memberProfile: {
		id: 'profile',
		name: 'Profile',
		path: '/dashboard/members/profile',
		view: MemberView,
		props: { default: (route) => ({ uid: route.query.uid }) },
	},
	notifications: {
		id: 'notifications',
		name: 'Notifications',
		icon: 'fas fa-bell',
		path: '/dashboard/notifications',
		view: Notifications,
	},
	adminNotifications: {
		id: 'adminNotifications',
		name: 'Admin Notifications',
		path: '/dashboard/admin-notifications',
		view: AdminNotifications,
	},

	settings: {
		id: 'settings',
		name: 'Settings',
		icon: 'fas fa-cog',
		path: '/dashboard/settings',
		view: Settings,
		isHidden: true,
	},
	*/
};
