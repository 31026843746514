<template>
	<div>
		<div style="position: relative">
			<VueAspectRatio ar="16:9">
				<b-img
					fluid
					src="https://upload.wikimedia.org/wikipedia/commons/thumb/f/f8/Aspect-ratio-16x9.svg/1200px-Aspect-ratio-16x9.svg.png"
				/>
			</VueAspectRatio>
			<BaseButton
				style="position: absolute; top: 0; right: 0"
				class="m-2"
				size="sm"
				icon="fas fa-edit"
				>edit</BaseButton
			>
		</div>

		<div class="py-2 mx-3">
			<b-row style="margin-top: -50px">
				<b-col cols="auto">
					<b-avatar
						size="100px"
						:src="organization.orgLogo"
						variant="dark"
					></b-avatar>
				</b-col>

				<b-col style="top: 50px" class="p-2 m-1">
					<div class="text-center">
						<h5 class="mb-0">{{ organization.orgMembers }}</h5>
						<h5 class="mb-0">Members</h5>
					</div>
				</b-col>
				<b-col style="top: 50px" class="p-2 m-1">
					<div class="text-center">
						<h5 class="mb-0">{{ organization.orgFollowers }}</h5>
						<h5 class="mb-0">Followers</h5>
					</div>
				</b-col>
				<b-col style="top: 50px" class="p-2 m-1">
					<div class="text-center">
						<h5 class="mb-0">{{ organization.orgConnections }}</h5>
						<h5 class="mb-0">Connections</h5>
					</div>
				</b-col>
			</b-row>
			<h2 class="mt-1 mb-0">{{ organization.orgName }}</h2>
			<h5 class="mb-0 text-muted">
				{{ organization.orgType ? organization.orgType[0] : null }}
			</h5>
			<h5 class="text-muted">
				<i class="mr-1 fas fa-map-marker-alt" />{{
					organization.orgLocations ? organization.orgLocations[0].description : null
				}}
			</h5>
			<p class="small mb-0">{{ organization.orgBio }}</p>
			<b-row class="my-1">
				<b-col>
					<BaseButton type="primary" block>Following</BaseButton>
				</b-col>
				<b-col>
					<BaseButton block style="background-color: gray; border: 0"
						>Give</BaseButton
					>
				</b-col>
				<b-col>
					<BaseButton block style="background-color: gray; border: 0"
						>Message</BaseButton
					>
				</b-col>
			</b-row>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import VueAspectRatio from 'vue-aspect-ratio';
import BaseButton from '../BaseButton.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: { VueAspectRatio, BaseButton },
};
</script>
