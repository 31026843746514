<template>
	<Card v-if="userData">
		<b-row class="align-items-center">
			<b-col cols="auto">
				<b-avatar
					:badge-variant="isUserFlagged() ? '' : isAdminUser() ? 'success' : ''"
					variant="dark"
					class="avatar-xl"
					:text="this.userData.firstName"
					:src="this.userData.profilePhotoURL"
				>
					<template #badge
						><Badge v-if="isUserFlagged()" type="danger">BLOCKED</Badge
						><b-icon
							v-if="!isUserFlagged() && isAdminUser()"
							icon="star-fill"
							font-scale="1.25"
						></b-icon
					></template>
				</b-avatar>
			</b-col>
			<b-col class="text-left p-0">
				<h3 class="oneLine m-0">
					{{ userData.firstName }} {{ userData.lastName }}
				</h3>
				<h6 class="oneLine m-0">
					Username: @{{ userData.username.toLowerCase() }}
				</h6>
				<h6 class="oneLine m-0">Email: {{ userData.email.toLowerCase() }}</h6>
				<h6 v-if="userData.created" class="oneLine text-muted mb-0">
					Member Since:
					{{
						this.$helpers.formatDate(
							this.$helpers.returnFirebaseDate(userData.created)
						)
					}}
				</h6>

				<h6 v-if="userData.lastLoggedIn" class="oneLine text-muted mb-0">
					Last Seen:
					{{
						this.$helpers.formatDate(
							this.$helpers.returnFirebaseDate(userData.lastLoggedIn)
						)
					}}
				</h6>
			</b-col>
			<b-col cols="auto" class="text-right">
				<div class="d-flex align-items-center">
					<label class="form-control-label m-0 mr-2">Active</label>
					<BaseSwitch :value="isUserActive()" isDisabled type="primary" />
				</div>
			</b-col>
		</b-row>
	</Card>
</template>
<script>
import { mapGetters } from 'vuex';
import Badge from '../../../components/Badge.vue';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import Card from '../../../components/Cards/Card.vue';
import AppUserManager from '../../../models/AppUser/AppUserManager';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {
		userData: null,
	},
	components: { Card, BaseSwitch, Badge },
	methods: {
		isUserActive() {
			return AppUserManager.isUserActive(this.userData);
		},
		isAdminUser() {
			return AppUserManager.isAdminUser(this.userData);
		},
		isUserFlagged() {
			return AppUserManager.isUserFlagged(this.userData);
		},
	},
};
</script>
