<template>
	<div v-if="postData || isNew">
		<b-row v-if="postData" class="">
			<b-col v-if="returnLikesComments(postData.likes)" cols="auto">
				<BaseButton icon="fas fa-heart" type="null" size="sm" disabled link
					>{{ returnLikesComments(postData.likes) }} Likes</BaseButton
				>
			</b-col>
			<b-col v-if="returnLikesComments(postData.comments)" cols="auto">
				<BaseButton icon="fas fa-comment" type="null" size="sm" disabled link
					>{{ returnLikesComments(postData.comments) }} Comments</BaseButton
				>
			</b-col>
		</b-row>
		<hr class="my-1 mx-3" />
		<b-row class="text-center">
			<b-col>
				<BaseButton class="oneLine" icon="far fa-heart" type="null" disabled link
					>Like</BaseButton
				>
			</b-col>
			<b-col>
				<BaseButton class="oneLine" icon="far fa-comment" type="null" disabled link
					>Comment</BaseButton
				>
			</b-col>
			<b-col>
				<BaseButton class="oneLine" icon="fas fa-share" type="null" disabled link
					>Share</BaseButton
				>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import BaseButton from '../BaseButton.vue';

export default {
	props: {
		postData: {
			type: Object,
			default: null,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	components: { BaseButton },
	methods: {
		returnLikesComments(count) {
			if (count) {
				if (count > 0) {
					return count;
				}
			}
			return null;
		},
	},
};
</script>
