<template>
	<div>
		<b-form-group>
			<slot name="label">
				<label class="form-control-label" v-if="label">
					{{ label }}
				</label>
			</slot>
			<input
				class="form-control"
				type="text"
				:disabled="disabled"
				:required="required"
				v-model="displayValue"
				@blur="isInputActive = false"
				@focus="isInputActive = true"
			/>
		</b-form-group>
	</div>
</template>
<script>
export default {
	inheritAttrs: false,
	props: {
		value: {
			type: Number,
			default: 0,
			description: 'Input value',
		},
		label: {
			type: String,
			description: 'Input label (text before input)',
		},
		disabled: {
			type: Boolean,
			description: 'Input is disabled',
		},
		required: {
			type: Boolean,
			descripton: 'Input is required',
		},
	},
	data() {
		return {
			isInputActive: false,
			focused: false,
		};
	},
	computed: {
		displayValue: {
			get: function () {
				if (this.isInputActive) {
					// Cursor is inside the input field. unformat display value for user
					return this.value.toString();
				} else {
					// User is not modifying now. Format display value for user interface
					return (
						'$ ' +
						this.value.toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
					);
				}
			},
			set: function (modifiedValue) {
				// Recalculate value after ignoring "$" and "," in user input
				let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ''));
				// Ensure that it is not NaN
				if (isNaN(newValue)) {
					newValue = 0;
				}
				// Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
				// $emit the event so that parent component gets it
				this.$emit('input', newValue);
			},
		},
	},
	methods: {},
	mounted() {},
};
</script>
