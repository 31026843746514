<template>
	<div>
		<div>
			<b-row class="mb-3 justify-content-center">
				<b-col>
					<BaseDropbox class="px-7 px-lg-9" ar="1:1" @onFile="onFileChange">
					</BaseDropbox>
				</b-col>
			</b-row>
			<BaseInput
				label="Organization Name"
				placeholder="Organzization Name"
				required
				name="organization name"
				v-model="orgData.orgName"
			></BaseInput>
			<BaseInput
				label="Organization Username"
				placeholder="Enter a username"
				required
				name="organization username"
				v-model.trim="orgData.orgUsername"
			></BaseInput>

			<BaseInput
				type="textarea"
				label="Organization Bio"
				placeholder="Enter a your organization's bio..."
				:maxLength="250"
				showMaxLabel
				name="organization bio"
				required
				v-model="orgData.orgBio"
			></BaseInput>
		</div>
	</div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import BaseDropbox from '../../../components/Inputs/BaseDropbox.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';

export default {
	props: {
		tempOrganization: {
			type: Object,
			default: null,
		},
	},
	watch: {
		orgData: {
			handler(val) {
				return this.$emit('orgData', val);
			},
			deep: true,
		},
	},
	data() {
		return {
			orgData: {
				orgName: null,
				orgLogo: null,
				orgUsername: null,
				orgBio: '',
			},
		};
	},
	methods: {
		onFileChange(file) {
			this.orgData.orgLogo = file;
		},
	},
	mounted() {
		if (this.tempOrganization) {
			this.orgData.orgName = this.tempOrganization.orgName;
		}
	},
	components: { BaseButton, BaseInput, BaseDropbox },
};
</script>
