<template>
	<div>
		<Notifications />
		<!-- Page content -->
		<b-container class="my-8">
			<b-row class="justify-content-center">
				<b-col lg="6">
					<Card>
						<b-row class="justify-content-center my-4 mx-4">
							<b-col class="px-5">
								<b-img fluid src="/img/AppLogoHorizontalWTEXT.png" />
							</b-col>
						</b-row>
						<validation-observer v-slot="{ handleSubmit }" ref="formValidator">
							<b-form @submit.prevent="handleSubmit(onSubmit)">
								<BaseInput
									name="Email"
									:rules="{ required: true }"
									placeholder="Email"
									required
									v-model="model.email"
								/>
								<BaseInput
									name="Password"
									:rules="{ required: true, min: 6 }"
									placeholder="Password"
									required
									type="password"
									v-model="model.password"
								/>
								<b-row class="my-2 mb-0">
									<b-col>
										<BaseButton type="button" class="p-0" @click="onForgotPassword" link>
											<small>Forgot Password?</small>
										</BaseButton>
									</b-col>
								</b-row>
								<BaseButton type="primary" native-type="submit" block
									>Sign In</BaseButton
								>
							</b-form>
						</validation-observer>
						<hr />
						<b-row class="justify-content-center">
							<a>
								<small class="text-light">Not a registered admin user?&nbsp;</small></a
							>
							<a
								:href="'mailto:' + MySundayInformation.mysundaySupportEmail"
								target="_blank"
								><small>Contact Us</small></a
							>
						</b-row>
					</Card>
				</b-col>
			</b-row>
			<b-row class="justify-content-center">
				<a
					:href="MySundayInformation.mysundayWebsite + '/service-agreement'"
					target="_blank"
					class="text-light"
					><small>Terms of Service&nbsp;·&nbsp;</small></a
				>
				<a
					:href="MySundayInformation.mysundayWebsite + '/privacy-policy'"
					class="text-light"
					target="_blank"
					><small>&nbsp;Privacy Policy&nbsp;·&nbsp;</small></a
				>
				<a
					:href="MySundayInformation.mysundaySupport"
					class="text-light"
					target="_blank"
					><small>&nbsp;Support</small></a
				>
			</b-row>
		</b-container>
		<!--
<ForgotPassword
			:show-modal="showForgotPassword"
			@close="showForgotPassword = false"
		/>
		-->
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Notifications from '../../components/NotificationPlugin/Notifications.vue';
import Card from '../../components/Cards/Card.vue';
import BaseInput from '../../components/Inputs/BaseInput.vue';
import BaseButton from '../../components/BaseButton.vue';
import { MySundayInformation } from '../../resources/MySundayInformation';

export default {
	name: 'login',
	components: {
		Notifications,
		Card,
		BaseInput,
		BaseButton,
	},
	data() {
		return {
			isLoading: false,
			MySundayInformation,
			showForgotPassword: false,
			model: {
				email: '',
				password: '',
				rememberMe: false,
			},
		};
	},
	methods: {
		...mapActions(['login']),
		onForgotPassword() {
			this.showForgotPassword = true;
		},
		onSubmit() {
			this.isLoading = true;
			this.login({
				email: this.model.email,
				password: this.model.password,
			})
				.then(() => {
					this.isLoading = false;
					this.$router.push('/');
				})
				.catch((error) => {
					this.isLoading = false;
					this.$notify({
						verticalAlign: 'top',
						horizontalAlign: 'center',
						type: 'danger',
						message: 'The username or password you entered is incorrect.',
					});
				});
		},
	},
};
</script>
