<template>
	<div v-if="eventData">
		<Card no-body style="overflow: hidden">
			<VueAspectRatio ar="16:9">
				<div
					@mouseover="imageHover = true"
					@mouseleave="imageHover = false"
					class="b-img-helpers"
				>
					<div
						class="b-img-helpers card-img-top"
						:style="{ opacity: imageHover ? 0.4 : 1 }"
					>
						<b-img-lazy
							fluid-grow
							:src="eventData.imageItem[0].imageUrl"
							class="b-img-background-blur"
						/>
						<div class="b-img-overlay"></div>
						<b-img-lazy
							fluid-grow
							:src="eventData.imageItem[0].imageUrl"
							class="b-img-base"
						/>
					</div>
					<div
						v-if="imageHover"
						style="
							height: 100%;
							width: 100%;
							border: dashed 1px black;
							display: flex;
							flex-wrap: wrap;
							align-content: center;
						"
						class="justify-content-center card-img-top b-img-base"
					>
						<BaseButton type="medium" size="sm" block @click="imageClicked"
							>Edit</BaseButton
						>
					</div>
				</div>
			</VueAspectRatio>
			<div class="p-3">
				<h6 class="mb-0 text-muted">
					{{ getFormattedDate() }}
				</h6>
				<h4 class="my-1">{{ eventData.eventTitle }}</h4>
				<ReadMore :text="eventData.eventDescription" />
				<Badge :type="getEventBadge().type">{{ getEventBadge().name }}</Badge>
				<hr class="my-2" />
				<div class="my-2">
					<IconTextCombo
						class="py-1"
						icon="fa fa-clock"
						:titleLabel="getFormattedDate()"
						:bottom-label="getEndDate()"
					/>
					<IconTextCombo
						v-if="eventData.location"
						class="py-1"
						icon="fa fa-location-arrow"
						:titleLabel="eventData.location.title"
						:bottom-label="eventData.location.description"
					/>
					<IconTextCombo
						v-if="eventData.price ? (eventData.price.amount ? true : false) : false"
						class="py-1"
						icon="fa fa-dollar-sign"
						:titleLabel="this.$helpers.amountToString(eventData.price.amount)"
						:bottom-label="eventData.price.quantity"
					/>
					<IconTextCombo
						v-if="eventData.eventMaxUsers"
						class="py-1"
						icon="fa fa-user-friends"
						:titleLabel="getMaxUsers()"
					/>
					<IconTextCombo
						v-if="eventData.ageItem"
						class="py-1"
						icon="fa fa-user-shield"
						:titleLabel="getAgeLimit()"
					/>
					<IconTextCombo
						class="py-1"
						:icon="`fa ${getPrivacy().icon}`"
						:titleLabel="getPrivacy().name"
					/>
					<IconTextCombo
						class="py-1"
						:icon="`fa ${getCommunityView().icon}`"
						:titleLabel="getCommunityView().name"
					/>
				</div>
				<hr class="my-2" />
				<BaseButton disabled type="dark" block>View Event</BaseButton>
			</div>
		</Card>
		<ChangeImageForm
			:show-modal="showImageModal"
			@updatedImage="newImageFile"
			@close="showImageModal = false"
		/>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import Card from '../../../components/Cards/Card.vue';
import ReadMore from '../../../components/ReadMore.vue';
import BaseButton from '../../../components/BaseButton.vue';
import IconTextCombo from '../../../components/IconTextCombo.vue';
import ChangeImageForm from '../BaseNewViews/ChangeImageForm.vue';
import Badge from '../../../components/Badge.vue';

export default {
	props: {
		eventData: {
			type: Object,
			default: null,
		},
	},
	components: {
		Card,
		VueAspectRatio,
		ReadMore,
		BaseButton,
		IconTextCombo,
		ChangeImageForm,
		Badge,
	},
	data() {
		return {
			imageHover: false,
			showImageModal: false,
		};
	},
	methods: {
		imageClicked() {
			this.showImageModal = !this.showImageModal;
		},
		getFormattedDate() {
			return this.$helpers.formatDate(
				this.eventData.eventStart,
				"MMMM dd, yyyy 'at' hh:mm a"
			);
		},
		getEndDate() {
			return this.eventData.eventEnd;
		},
		getMaxUsers() {
			return this.eventData.eventMaxUsers != null
				? `${this.eventData.eventMaxUsers} Max Available`
				: 'Unlimited Availability';
		},
		getAgeLimit() {
			const ageItem = this.eventData.ageItem;
			const startAge = ageItem.startAge;
			if (ageItem.endAge) {
				return `Ages: ${startAge} - ${ageItem.endAge}`;
			} else {
				return `Ages: ${startAge}+`;
			}
		},
		getPrivacy() {
			const isPrivate =
				this.eventData.isPrivate != null ? this.eventData.isPrivate : false;
			return {
				name: isPrivate
					? 'Private · Users must request access'
					: 'Public · Anyone can join',
				icon: isPrivate ? 'fa-lock' : 'fa-unlock',
			};
		},
		getCommunityView() {
			const isMembersOnly =
				this.eventData.isMembersOnly != null ? this.eventData.isMembersOnly : false;
			return {
				name: isMembersOnly
					? 'This event is for members only.'
					: 'This event is open to the public.',
				icon: isMembersOnly ? 'fa-eye-slash' : 'fa-eye',
			};
		},
		getEventBadge() {
			const isCancelled =
				this.eventData.isCancelled != null ? this.eventData.isCancelled : false;
			const isPrevious = this.eventData.eventStart.toDate() < new Date();
			return {
				type: isPrevious ? 'warning' : isCancelled ? 'danger' : 'success',
				name: isPrevious ? 'Expired' : isCancelled ? 'Cancelled' : 'On Time',
			};
		},
		newImageFile(file) {
			this.$emit('updateImage', file);
		},
	},
};
</script>
