<template>
	<div class="mx-2" v-if="postData || isNew">
		<div class="d-flex justify-content-between my-1">
			<div v-if="headerData" class="d-flex align-items-center">
				<b-avatar :src="headerData.logo" variant="dark" class="mr-2" />
				<div>
					<div class="d-flex align-items-center">
						<h5 class="m-0">{{ headerData.name }}</h5>
						<h6 class="ml-1 mb-0 text-muted">@{{ headerData.username }}</h6>
					</div>

					<h6 class="m-0 text-muted">
						{{ headerData.timestamp }}
						{{ !getApproved() ? '· Approval Pending' : '' }}
					</h6>
					<h6
						v-if="getAnonymous()"
						class="m-0 text-primary"
						style="font-weight: bold"
					>
						<i> Posted Anonymously * </i>
					</h6>
				</div>
			</div>
			<b-dropdown
				id="actions"
				variant="null"
				dropleft
				no-caret
				class="m-0 p-0"
				v-if="options.length > 0"
			>
				<template #button-content><i class="fas fa-ellipsis-h"></i> </template>
				<div v-for="item in options">
					<b-dropdown-item
						v-if="item.title"
						:variant="item.variant"
						@click="actionDropdownClicked(item)"
						>{{ item.title }}</b-dropdown-item
					>
					<b-dropdown-divider v-if="item.isDivider"></b-dropdown-divider>
				</div>
			</b-dropdown>
			<BaseButton
				v-if="false"
				icon="fas fa-ellipsis-h"
				type="neutral"
				link
				@click="optionClicked"
			></BaseButton>
		</div>
		<span style="white-space: pre-line">
			<div
				v-if="postCaptionData()"
				class="description mb-0"
				v-html="postCaptionData()"
			></div>
		</span>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import BaseButton from '../BaseButton.vue';
import AppUserManager from '../../models/AppUser/AppUserManager';

export default {
	props: {
		postData: {
			type: Object,
			default: null,
		},
		timestamp: {
			type: String,
			default: 'null',
		},
		description: {
			type: String,
			default: null,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: { BaseButton },
	data() {
		return {
			headerData: null,
			options: [],
		};
	},
	methods: {
		setOptions() {
			if (!this.getApproved()) {
				this.options.push({
					title: 'Approve Post',
					click: 'approvedClicked',
					variant: 'success',
				});
				this.options.push({
					isDivider: true,
				});
			}
			this.options.push({
				title: 'Delete Post',
				click: 'deleteClicked',
				variant: 'danger',
			});
		},
		getApproved() {
			if (this.postData) {
				if (this.postData.isApproved !== null) {
					return this.postData.isApproved;
				}
			}
			return true;
		},
		getAnonymous() {
			if (this.postData) {
				if (this.postData.isAnonymous !== null) {
					return this.postData.isAnonymous;
				}
			}
			return false;
		},
		getHeaderData() {
			if (this.isNew) {
				this.headerData = {
					logo: this.organization.orgLogo,
					name: this.organization.orgName,
					username: this.organization.orgUsername,
					timestamp: '1 min ago',
				};
				return this.$emit('headerData', this.headerData);
			} else if (this.postData.authorId == this.organization.orgId) {
				this.headerData = {
					logo: this.organization.orgLogo,
					name: this.organization.orgName,
					username: this.organization.orgUsername,
					timestamp: this.postData.created
						? this.$helpers.timeSince(this.postData.created.toDate())
						: null,
				};
				return this.$emit('headerData', this.headerData);
			} else {
				AppUserManager.getUserByUid(this.postData.authorId).then((userData) => {
					if (userData) {
						this.headerData = {
							logo: userData.profilePhotoURL,
							name: `${userData.firstName} ${userData.lastName}`,
							username: userData.username,
							timestamp: this.postData.created
								? this.$helpers.timeSince(this.postData.created.toDate())
								: null,
						};
						return this.$emit('headerData', this.headerData);
					} else {
						this.headerData = null;
						return this.$emit('headerData', this.headerData);
					}
				});
			}
		},
		postCaptionData() {
			if (this.isNew) {
				return this.description;
			} else {
				return this.postData.postCaption
					? this.$helpers.urlify(this.postData.postCaption)
					: null;
			}
		},
		actionDropdownClicked(item) {
			if (item.click == 'approvedClicked') {
				this.approvedClicked();
			} else if (item.click == 'deleteClicked') {
				this.deleteClicked();
			}
		},
		approvedClicked() {
			this.$emit('approvedClicked');
		},
		deleteClicked() {
			this.$emit('deleteClicked');
		},
		optionClicked() {
			this.$emit('optionClicked');
		},
	},
	mounted() {
		this.getHeaderData();
		this.setOptions();
	},
};
</script>
