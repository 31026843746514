<template>
	<Card>
		<CardHeader
			slot="header"
			:title="titleLabel"
			:tooltip="titleLabelTooltip"
			:end-button="
				showUpdateButton ? { title: 'Update', disabled: !needsUpdate } : null
			"
			@click="onUpdate"
		/>
		<b-row v-if="maxUsersOptions.length > 0">
			<b-col>
				<ButtonRadioGroup
					:showBorder="false"
					:options="maxUsersOptions"
					@change="onUserRadioChanged"
				>
					<div
						v-if="maxUsersOptions[1].value == selectedUserOption"
						:slot="maxUsersOptions[1].value"
						class="mt-0"
					>
						<b-row class="mx-1">
							<b-col>
								<BaseInput
									label="Quantity Available"
									id="quantity available"
									placeholder="Quantity Available"
									type="number"
									name="quantity available"
									:min-length="1"
									:max-length="maxUsersAllowed"
									v-model="updatedData.maxUsers"
								/>
							</b-col>
						</b-row>
					</div>
				</ButtonRadioGroup>
			</b-col>
		</b-row>
	</Card>
</template>
<script>
import ButtonRadioGroup from '../../../components/ButtonRadioGroup.vue';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';

export default {
	props: {
		titleLabel: {
			type: String,
			default: 'Registration Attendance',
		},
		titleLabelTooltip: {
			type: String,
			default: 'The maximum amount of users that can be attend this event.',
		},
		showUpdateButton: {
			type: Boolean,
			default: false,
		},
		maxUsersAllowed: {
			type: Number,
			default: null,
		},
		maxUsersValue: {
			type: Number,
			default: null,
		},
	},
	components: { Card, CardHeader, ButtonRadioGroup, BaseInput },
	data() {
		return {
			needsUpdate: false,
			maxUsersOptions: [],
			selectedUserOption: null,
			updatedData: {
				maxUsers: null,
			},
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				this.needsUpdate = this.checkUpdate();
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		setOptions(selectedValue) {
			var options = [
				{
					value: 0,
					name: 'Unlimited number of availability',
					disabled: this.maxUsersAllowed !== null,
				},
				{
					value: 1,
					name: 'Limited number of availability',
				},
			];
			options[0].selected = selectedValue == 0;
			options[1].selected = selectedValue == 1;
			this.maxUsersOptions = options;
		},
		onUserRadioChanged(value) {
			if (value == 0) {
				this.updatedData.maxUsers = null;
			} else if (value == 1) {
				this.updatedData.maxUsers =
					this.maxUsersValue != null ? this.maxUsersValue : 1;
			}
			this.selectedUserOption = value;
		},
		checkUpdate() {
			if (this.updatedData.maxUsers != this.maxUsersValue) {
				return true;
			}
			return false;
		},
		onUpdate() {
			this.$emit('onUpdate', this.updatedData);
			this.needsUpdate = false;
		},
		onChange() {
			this.$emit('onChange', this.updatedData);
		},
	},
	mounted() {
		if (this.maxUsersValue) {
			this.setOptions(1);
			this.updatedData.maxUsers = this.maxUsersValue;
		} else {
			this.setOptions(this.maxUsersAllowed ? 1 : 0);
		}
	},
};
</script>
