import {
	collection,
	deleteDoc,
	doc,
	getCountFromServer,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
} from 'firebase/firestore';
import { firestore, storage } from '../../resources/firebase';
import store from '../../store/store';
import FirebaseManager from '../Firebase/FirebaseManager';
import mimiDB from 'mime-db';
import { ref } from 'firebase/storage';
import helpers from '../../helpers/helpers';
import { PricingInformation } from '../../resources/PricingInformation';

export default class AudioItemManager {
	static limitFetch = 15;

	static getOrgAudiosRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/audios`
		);
	}

	static canUpload() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const ref = this.getOrgAudiosRef(organization.orgId);
			getCountFromServer(ref)
				.then((snapshot) => {
					const planInfo = PricingInformation.find(
						(e) => e.billingType == organization.billingType
					);
					const maxAllowed = planInfo.billingType !== 'basic';
					return resolve(maxAllowed);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getOrgAudios(lastDocument, maxCount) {
		return new Promise((resolve, reject) => {
			const fetchCount =
				maxCount != null
					? maxCount > AudioItemManager.limitFetch
						? maxCount
						: AudioItemManager.limitFetch
					: AudioItemManager.limitFetch;
			const organization = store.state.organization;
			const audioRef = this.getOrgAudiosRef(organization.orgId);
			var queryRef = query(
				audioRef,
				orderBy('created', 'desc'),
				limit(fetchCount)
			);
			if (lastDocument) {
				queryRef = query(
					audioRef,
					orderBy('created', 'desc'),
					startAfter(lastDocument),
					limit(fetchCount)
				);
			}

			getDocs(queryRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					const lastDocument = snapshot.docs.lastItem;
					var counter = 0;
					var items = [];
					if (counter == total) {
						return resolve({ items: items, lastCursor: lastDocument });
					}
					documents.forEach((document) => {
						var audioData = document.data();
						items.push(audioData);
						if (counter == total - 1) {
							return resolve({ items: items, lastCursor: lastDocument });
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getAudioById(audioId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const eventIdRef = doc(
				firestore,
				`${this.getOrgAudiosRef(organization.orgId).path}/${audioId}`
			);
			getDoc(eventIdRef)
				.then((snapshot) => {
					return resolve(snapshot.exists ? snapshot.data() : null);
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static addAudioToDB(
		data,
		file,
		fileThumbnail,
		fileName,
		fileType,
		loadingCallback
	) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const user = store.state.user;
			const orgId = organization.orgId;
			const newRef = doc(this.getOrgAudiosRef(orgId));
			const cleanFileName = fileName.replace(/\s+/g, '');
			const imageStorageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/audios/${
					newRef.id
				}/image:${cleanFileName}.jpeg`
			);
			const storageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/audios/${
					newRef.id
				}/audio:${cleanFileName}.${mimiDB[fileType].extensions[0]}`
			);

			FirebaseManager.uploadImage(imageStorageRef, fileThumbnail, false)
				.then((thumbnailURL) => {
					FirebaseManager.uploadFile(storageRef, file, fileType, (progress) => {
						loadingCallback(progress);
					})
						.then((downloadURL) => {
							FirebaseManager.createDynamicLink(
								newRef.id,
								null,
								FirebaseManager.DataType.audioItem,
								data.audioTitle,
								data.audioDescription,
								thumbnailURL
							).then((shareLink) => {
								var audioData = helpers.removeAllNullValues(data);
								audioData.shareLink = shareLink;
								audioData.imageItem = [{ imageUrl: thumbnailURL }];
								audioData.audioLink = downloadURL;
								audioData.audioId = newRef.id;
								audioData.orgId = orgId;
								audioData.created = FirebaseManager.timestamp();
								audioData.createdBy = user.uid;
								setDoc(newRef, audioData)
									.then(() => {
										return resolve(audioData);
									})
									.catch((error) => {
										return reject(error.message);
									});
							});
						})
						.catch((error) => {
							return reject(error.message);
						});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static deleteAudioItem(audioId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const storageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/audios/${audioId}`
			);

			FirebaseManager.deleteStorageRef(storageRef).finally(() => {
				return this.completeDelete(audioId)
					.then(() => {
						return resolve();
					})
					.catch((errorMessage) => {
						return reject(errorMessage);
					});
			});
		});
	}

	static completeDelete(audioId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const audioRef = doc(
				firestore,
				`${FirebaseManager.organizationRef().path}/${orgId}/audios/${audioId}`
			);
			deleteDoc(audioRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
