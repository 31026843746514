<template>
	<div>
		<Modal :show="showModal" @close="closeModal" hide-footer>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">
					{{ groupData ? `Add File · ${groupData.groupTitle}` : 'New File' }}
				</h5>
			</template>
			<hr class="m-0 p-0" />
			<b-overlay :show="isLoading" rounded="sm">
				<div>
					<h6
						class="py-2"
						v-if="errorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form" class="py-2">
					<label class="form-control-label m-0 p-0">Select File</label>
					<div class="my-3">
						<BaseDropbox
							is-file
							ar="4:1"
							:accepted-files="'application/pdf, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.presentation'"
							@onFile="onFileChange"
						>
						</BaseDropbox>
					</div>

					<BaseInput
						label="File Title"
						id="File Title"
						name="File Title"
						placeholder="Enter File Title"
						required
						type="text"
						v-model="fileData.fileTitle"
					></BaseInput>

					<BaseInput
						label="File Description (optional)"
						id="File Description"
						name="File Description"
						placeholder="Enter your description..."
						type="textarea"
						v-model="fileData.fileDescription"
					/>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add File</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../../components/Modals/Modal.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import BaseButton from '../../../components/BaseButton.vue';
import helpers from '../../../helpers/helpers';
import BaseDropbox from '../../../components/Inputs/BaseDropbox.vue';

export default {
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
		groupData: {
			type: Object,
			default: null,
		},
	},
	components: { Modal, VueDropZone, BaseInput, BaseButton, BaseDropbox },
	data() {
		return {
			shake: false,
			isLoading: false,
			fileItem: null,
			errorMessage: null,
			fileData: {
				fileTitle: null,
				fileDescription: null,
				fileContentType: null,
			},
		};
	},
	methods: {
		onFileChange(file) {
			this.fileItem = file;
			this.fileData.fileTitle = this.fileItem.name.replace(/\s+/g, '');
			this.fileData.fileContentType = this.fileItem.type;
		},
		submit() {
			this.shake = false;

			if (this.fileItem == null) {
				this.errorMessage = 'Please select a file.';
				this.shake = true;
				return;
			} else if (helpers.isBlank(this.fileData.fileTitle)) {
				this.errorMessage = 'Please enter a file title.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;
				this.addFileItem();
			}
		},
		addFileItem() {
			this.isLoading = true;
			this.$emit('uploadData', {
				fileItem: this.fileItem,
				fileData: this.fileData,
			});
			this.closeModal();
		},
		resetView() {
			this.fileItem = null;
			this.fileData.fileTitle = null;
			this.fileData.fileDescription = null;
			this.fileData.fileContentType = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
	},
};
</script>
<style></style>
