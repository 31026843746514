<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<BaseContent>
			<LoadingProgress :is-loading="isLoading" :progress="isLoadingProgress">
				<VideoTable
					:isLoading="isLoading"
					:videosData="videos"
					@currentPage="currentPage"
					@viewClicked="viewClicked"
					@deleteClicked="askDeleteQuestion"
				/>
			</LoadingProgress>
		</BaseContent>
		<NewVideoModal
			:show-modal="showModal"
			@close="showModal = false"
			@uploadData="uploadData"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="showQuestion"
			@yesClicked="deleteVideo"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import DashboardHeader from '../../components/Dashboard/DashboardHeader.vue';
import VideoItemManager from '../../models/VideoItem/VideoItemManager';
import VideoTable from '../SecondaryComponents/VideoViews/VideoTable.vue';
import BaseContent from '../../components/BaseContent.vue';
import NewVideoModal from '../SecondaryComponents/VideoViews/NewVideoModal.vue';
import LoadingProgress from '../../components/LoadingProgress.vue';
import QuestionModal from '../../components/Modals/QuestionModal.vue';

export default {
	components: {
		DashboardHeader,
		BaseContent,
		VideoTable,
		NewVideoModal,
		LoadingProgress,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			isLoadingProgress: 0,
			showModal: false,
			showQuestionModal: false,
			showQuestion: null,
			selectedVideo: null,
			videos: [],
			lastFetched: null,
			buttonItems: [
				{
					index: 0,
					title: 'New Video',
					icon: 'fa fa-plus',
					type: 'primary',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getVideos(maxCount) {
			this.isLoading = true;
			VideoItemManager.getOrgVideos(this.lastFetched, maxCount).then((items) => {
				this.videos.push.apply(this.videos, items.items);
				this.lastFetched = items.lastCursor;
				this.isLoading = false;
			});
		},
		currentPage(index) {
			const minCount = VideoItemManager.limitFetch * (index - 1);
			const maxCount = VideoItemManager.limitFetch * index;
			const inBetween = maxCount - this.videos.length;
			if (minCount >= this.videos.length && this.lastFetched != null) {
				const value =
					inBetween > VideoItemManager.limitFetch
						? inBetween
						: VideoItemManager.limitFetch;
				this.getVideos(value);
			}
		},
		checkIfCanUpload() {
			VideoItemManager.canUpload().then((canUpload) => {
				if (canUpload) {
					this.showModal = true;
				} else {
					this.showError('You have reached your limit on your current plan.');
				}
			});
		},
		uploadData(data) {
			const videoItem = data.videoItem;
			const videoThumbnail = data.videoThumbnail;
			const videoData = data.videoData;
			this.isLoading = true;
			VideoItemManager.addVideoToDB(
				videoData,
				videoItem,
				videoThumbnail,
				videoData.videoTitle,
				videoData.videoExternalLink ? null : videoItem.type,
				videoData.videoExternalLink,
				(progress) => {
					this.isLoadingProgress = progress;
				}
			)
				.then((videoData) => {
					this.isLoading = false;
					this.videos.push(videoData);
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		deleteVideo() {
			this.isLoading = true;
			VideoItemManager.deleteVideoItem(this.selectedVideo.videoId)
				.then(() => {
					this.videos = this.videos.filter(
						(e) => e.videoId !== this.selectedVideo.videoId
					);
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		askDeleteQuestion(videoItem) {
			this.selectedVideo = videoItem;
			this.showQuestion = 'Are you sure you want to delete this video?';
			this.showQuestionModal = true;
		},
		showError(message) {
			this.showNotification({
				type: 'danger',
				message: message,
			});
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.checkIfCanUpload();
			}
		},
		viewClicked(videoData) {},
	},
	mounted() {
		this.getVideos(null);
	},
};
</script>
