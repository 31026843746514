<template>
	<div v-if="groupData">
		<Card>
			<CardHeader
				slot="header"
				title="Files"
				:end-button="{ title: 'Add File' }"
				@click="checkIfCanUpload()"
			/>
			<LoadingProgress :is-loading="isLoading" :progress="isLoadingProgress">
				<FileTable
					:isLoading="isLoading"
					:filesData="files"
					@currentPage="currentPage"
					@viewClicked="viewClicked"
					@deleteClicked="askDeleteQuestion"
				/>
			</LoadingProgress>
		</Card>
		<NewFileModal
			:show-modal="showModal"
			:groupData="groupData"
			@close="showModal = false"
			@uploadData="uploadData"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="showQuestion"
			@yesClicked="deleteFile"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import LoadingProgress from '../../../components/LoadingProgress.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import FileItemManager from '../../../models/FileItem/FileItemManager';
import FileTable from '../FileViews/FileTable.vue';
import NewFileModal from '../FileViews/NewFileModal.vue';

export default {
	props: {
		groupData: {
			type: Object,
			default: null,
		},
	},
	components: {
		Card,
		CardHeader,
		CardFooter,
		LoadingProgress,
		FileTable,
		NewFileModal,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			isLoadingProgress: 0,
			showModal: false,
			showQuestionModal: false,
			showQuestion: null,
			selectedFile: null,
			lastFetched: null,
			files: [],
		};
	},
	methods: {
		getGroupFiles(maxCount) {
			this.isLoading = true;
			FileItemManager.getOrgFiles(
				this.lastFetched,
				maxCount,
				this.groupData.groupId
			).then((items) => {
				this.files.push.apply(this.files, items.items);
				this.lastFetched = items.lastCursor;
				this.isLoading = false;
			});
		},
		currentPage(index) {
			const minCount = FileItemManager.limitFetch * (index - 1);
			const maxCount = FileItemManager.limitFetch * index;
			const inBetween = maxCount - this.files.length;
			if (minCount >= this.files.length && this.lastFetched != null) {
				const value =
					inBetween > FileItemManager.limitFetch
						? inBetween
						: FileItemManager.limitFetch;
				this.getGroupFiles(value);
			}
		},
		checkIfCanUpload() {
			this.showModal = true;
		},
		uploadData(data) {
			const fileItem = data.fileItem;
			const fileData = data.fileData;
			this.isLoading = true;
			FileItemManager.addFileToDB(
				fileData,
				fileItem,
				fileData.fileTitle,
				fileData.fileContentType,
				this.groupData.groupId,
				(progress) => {
					this.isLoadingProgress = progress;
				}
			)
				.then((fileData) => {
					this.isLoading = false;
					this.files.push(fileData);
					//this.getFiles(null);
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		deleteFile() {
			this.isLoading = true;
			FileItemManager.deleteFileItem(
				this.selectedFile.fileId,
				this.groupData.groupId
			)
				.then(() => {
					this.files = this.files.filter(
						(e) => e.fileId !== this.selectedFile.fileId
					);
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		askDeleteQuestion(fileItem) {
			this.selectedFile = fileItem;
			this.showQuestion = 'Are you sure you want to delete this file?';
			this.showQuestionModal = true;
		},
		showError(message) {
			this.showNotification({
				type: 'danger',
				message: message,
			});
		},
		viewClicked(fileData) {
			this.$helpers.openUrl(fileData.fileLink);
			//console.log(fileData);
		},
	},
	mounted() {
		this.getGroupFiles(null);
	},
};
</script>
