<template>
	<Card>
		<CardHeader
			slot="header"
			:title="headerLabel"
			v-if="headerLabel"
			:end-button="
				showUpdateButton ? { title: 'Update', disabled: !needsUpdate } : null
			"
			@click="onUpdate"
		/>
		<CardFooter
			v-if="showImage"
			slot="footer"
			title="Please note: For best results, please upload an image with the ratio dimensions of 16:9. The title, description, and image are all required fields to proceed. "
		/>
		<b-row>
			<b-col
				:sm="showImage ? '6' : '12'"
				:lg="showImage ? '8' : '12'"
				order="2"
				order-sm="1"
			>
				<BaseInput
					:label="titleLabel"
					:id="titleLabel"
					:placeholder="titleLabel"
					required
					type="text"
					:name="titleLabel"
					v-model="updatedData.title"
				/>
				<BaseInput
					:label="descriptionLabel"
					:id="descriptionLabel"
					:name="descriptionLabel"
					:max-length="700"
					placeholder="Enter your description..."
					rows="5"
					required
					type="textarea"
					v-model="updatedData.description"
				/>
			</b-col>
			<b-col v-if="showImage" sm="6" lg="4" order="1" order-sm="2">
				<label class="form-control-label">{{ imageLabel }}</label>
				<BaseDropbox @onFile="onFileChange"> </BaseDropbox>
			</b-col>
		</b-row>
	</Card>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import CardFooter from '../../../components/Cards/CardFooter.vue';
import DropzoneFileUpload from '../../../components/Inputs/DropzoneFileUpload.vue';
import BaseDropbox from '../../../components/Inputs/BaseDropbox.vue';

export default {
	components: {
		BaseInput,
		VueDropZone,
		Card,
		CardHeader,
		CardFooter,
		DropzoneFileUpload,
		BaseDropbox,
	},
	props: {
		headerLabel: {
			type: String,
			default: null,
		},
		titleLabel: {
			type: String,
			default: 'Title',
		},
		titleValue: {
			type: String,
			default: null,
		},
		descriptionLabel: {
			type: String,
			default: 'Description',
		},
		descriptionValue: {
			type: String,
			default: null,
		},
		imageLabel: {
			type: String,
			default: 'Image',
		},
		showUpdateButton: {
			type: Boolean,
			default: false,
		},
		showImage: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			needsUpdate: false,
			thumbnail: null,
			updatedData: {
				title: null,
				description: null,
				image: null,
			},
		};
	},
	watch: {
		updatedData: {
			handler(val) {
				this.needsUpdate = this.checkUpdate();
				return this.onChange();
			},
			deep: true,
		},
	},
	methods: {
		onFileChange(file) {
			this.updatedData.image = file;
		},
		checkUpdate() {
			if (this.$helpers.isBlank(this.updatedData.title)) {
				return false;
			}
			if (this.$helpers.isBlank(this.updatedData.description)) {
				return false;
			}
			if (this.updatedData.title != this.titleValue) {
				return true;
			}
			if (this.updatedData.description != this.descriptionValue) {
				return true;
			}
			return false;
		},
		onUpdate() {
			this.$emit('onUpdate', this.updatedData);
			this.needsUpdate = false;
		},
		onChange() {
			this.$emit('onChange', this.updatedData);
		},
	},
	mounted() {
		if (this.titleValue) {
			this.updatedData.title = this.titleValue;
		}
		if (this.descriptionValue) {
			this.updatedData.description = this.descriptionValue;
		}
	},
};
</script>
