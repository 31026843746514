<template>
	<div v-if="eventData">
		<DashboardHeader
			:title="eventData.eventTitle"
			:buttonItems="buttonItems"
			@childClick="childClicked"
		/>
		<BaseContent>
			<b-overlay :show="isLoading" rounded="sm">
				<b-row>
					<b-col md="4" order-md="2">
						<EventCard :eventData="eventData" @updateImage="updateImage" />
					</b-col>
					<b-col order="1">
						<Card no-body>
							<b-tabs
								active-nav-item-class="bg-white font-weight-bold description"
								nav-class="bg-white description"
								card
								no-body
							>
								<b-tab title="Event Details" active>
									<TitleDescriptionView
										:showImage="false"
										:headerLabel="'Event Information'"
										:showUpdateButton="true"
										:title-label="'Event Title'"
										:titleValue="eventData.eventTitle"
										:description-label="'Event Description'"
										:descriptionValue="eventData.eventDescription"
										@onUpdate="onTitleDescriptionUpdate"
									/>
									<DateTimeView
										:showUpdateButton="true"
										:startDateValue="eventData.eventStart.toDate()"
										:durationValue="eventData.eventDuration"
										@onUpdate="onDateTimeUpdate"
									/>
									<LocationView
										:showUpdateButton="true"
										:headerLabel="'Event Location'"
										:titleValue="eventData.location ? eventData.location.title : null"
										:descriptionValue="
											eventData.location ? eventData.location.description : null
										"
										@onUpdate="onLocationUpdate"
									/>
								</b-tab>
								<b-tab title="Registration">
									<PriceView
										:showUpdateButton="true"
										:priceValue="eventData.price ? eventData.price.amount / 100 : null"
										@onUpdate="onPriceUpdate"
									/>
									<RegistrationAttendanceView
										:showUpdateButton="true"
										:maxUsersValue="eventData.eventMaxUsers"
										@onUpdate="onAttendanceUpdate"
									/>
									<RegistrationButtonView
										:showUpdateButton="true"
										:requires-link="false"
										:default-title-value="eventData.eventButtonTitle"
										:default-link-value="eventData.eventButtonLink"
										:defaultButtonFooterLabel="'By adding a button link, you will not be able to accept registration for this event.'"
										@onUpdate="onButtonUpdate"
									/>
								</b-tab>
								<b-tab title="Additional Settings">
									<AgeLimitView
										:showUpdateButton="true"
										:startAgeValue="eventData.ageItem ? eventData.ageItem.startAge : null"
										:endAgeValue="
											eventData.ageItem
												? eventData.ageItem.endAge
													? eventData.ageItem.endAge
													: null
												: null
										"
										:defaultAgeEnforced="
											eventData.ageItem
												? eventData.ageItem.enforced
													? eventData.ageItem.enforced
													: false
												: false
										"
										@onUpdate="onAgeUpdate"
									/>
									<PrivacyView
										:title-label="'Private Event'"
										:privacyValue="
											eventData.isPrivate != null ? eventData.isPrivate : false
										"
										@onUpdate="onPrivacyUpdate"
									/>
									<PrivacyView
										:title-label="'Member Event'"
										:title-label-tooltip="'Decide if this item can been seen by users outside of the MySunday.'"
										:privacyValue="
											eventData.isMemberOnly != null ? eventData.isMemberOnly : false
										"
										@onUpdate="onMemberPrivacyUpdate"
									/>
								</b-tab>
							</b-tabs>
						</Card>
					</b-col>
				</b-row>
			</b-overlay>
		</BaseContent>
		<QuestionModal
			:show="showQuestionModal"
			@close="showQuestionModal = false"
			:question="question"
			@yesClicked="yesClicked"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import VueAspectRatio from 'vue-aspect-ratio';
import ReadMore from '../../../components/ReadMore.vue';
import BaseContent from '../../../components/BaseContent.vue';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import EventItemManager from '../../../models/EventItem/EventItemManager';
import { DashboardChildren } from '../../../routes/routesNames';
import AgeLimitView from '../../SecondaryComponents/BaseNewViews/AgeLimitView.vue';
import DateTimeView from '../../SecondaryComponents/BaseNewViews/DateTimeView.vue';
import LocationView from '../../SecondaryComponents/BaseNewViews/LocationView.vue';
import PriceView from '../../SecondaryComponents/BaseNewViews/PriceView.vue';
import RegistrationButtonView from '../../SecondaryComponents/BaseNewViews/RegistrationButtonView.vue';
import TitleDescriptionView from '../../SecondaryComponents/BaseNewViews/TitleDescriptionView.vue';
import RegistrationAttendanceView from '../../SecondaryComponents/BaseNewViews/RegistrationAttendanceView.vue';
import PrivacyView from '../../SecondaryComponents/BaseNewViews/PrivacyView.vue';
import BaseButton from '../../../components/BaseButton.vue';
import EventCard from '../../SecondaryComponents/EventViews/EventCard.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';

export default {
	components: {
		VueAspectRatio,
		DashboardHeader,
		BaseContent,
		Card,
		TitleDescriptionView,
		DateTimeView,
		LocationView,
		PriceView,
		RegistrationButtonView,
		AgeLimitView,
		CardHeader,
		RegistrationAttendanceView,
		PrivacyView,
		ReadMore,
		BaseButton,
		EventCard,
		QuestionModal,
	},
	props: {
		eventId: {
			type: String,
			default: null,
		},
		eventStart: {
			type: [String, Number],
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			needsUpdate: false,
			imageHover: false,

			eventData: null,
			showQuestionModal: false,
			question: null,
			selectedId: null,
			viewAttendeesId: 0,
			sendNotificationId: 1,
			shareEventId: 2,
			cancelEventId: 3,
			deleteEventId: 4,
			terminalId: 5,
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),

		onTitleDescriptionUpdate(updatedData) {
			const title = updatedData.title;
			const description = updatedData.description;
			this.updateEventData({
				eventTitle: title,
				eventDescription: description,
			});
		},
		onDateTimeUpdate(updatedData) {
			const startDate = updatedData.startDate; //date ref
			const endDate = updatedData.endDate;
			const duration = updatedData.duration; //seconds
			this.updateEventData({
				eventStart: new Date(startDate),
				eventEnd: endDate != null ? new Date(endDate) : null,
				eventDuration: duration,
			});
		},
		onLocationUpdate(updatedData) {
			const title = updatedData.title;
			const description = updatedData.description;
			this.updateEventData({
				location: {
					title: title,
					description: description,
				},
			});
		},
		onPriceUpdate(updatedData) {
			const price =
				updatedData.price >= 0 ? parseInt(updatedData.price * 100) : null;
			this.updateEventData({
				price: {
					amount: price,
					quantity: this.eventData.eventMaxUsers,
				},
			});
		},
		onAgeUpdate(updatedData) {
			const startAge =
				updatedData.startAge != null ? parseInt(updatedData.startAge) : null;
			const endAge =
				updatedData.endAge != null ? parseInt(updatedData.endAge) : null;
			const ageEnforced = updatedData.ageEnforced;
			if (startAge) {
				this.updateEventData({
					ageItem: {
						startAge: startAge,
						endAge: endAge,
						enforced: ageEnforced,
					},
				});
			} else {
				this.updateEventData({
					ageItem: null,
				});
			}
		},
		onAttendanceUpdate(updatedData) {
			const maxUsers =
				updatedData.maxUsers != null ? parseInt(updatedData.maxUsers) : null;
			this.updateEventData({
				eventMaxUsers: maxUsers,
			});
		},
		onButtonUpdate(updatedData) {
			const isCustomDefaultButton = updatedData.isCustomDefaultButton;
			const defaultButtonTitle = updatedData.defaultButtonTitle;
			const defaultButtonLink = updatedData.defaultButtonLink;
			this.updateEventData({
				eventButtonTitle: isCustomDefaultButton ? defaultButtonTitle : null,
				eventButtonLink: isCustomDefaultButton ? defaultButtonLink : null,
			});
		},
		onPrivacyUpdate(updatedData) {
			const isPrivate = updatedData.isPrivate;
			this.updateEventData({
				isPrivate: isPrivate,
			});
		},
		onMemberPrivacyUpdate(updatedData) {
			const isMemberOnly = updatedData.isPrivate;
			this.updateEventData({
				isMemberOnly: isMemberOnly,
			});
		},
		askCancelEvent() {},
		cancelEvent() {
			const isCancelled =
				this.eventData.isCancelled != null ? this.eventData.isCancelled : false;
			this.updateEventData({
				isCancelled: !isCancelled,
			});
			this.setButtons();
		},
		shareEvent() {
			if (this.eventData.shareLink) {
				navigator.clipboard.writeText(this.eventData.shareLink);
				this.showNotification({
					type: 'success',
					message: 'Link Copied',
				});
			} else {
				this.showNotification({
					type: 'danger',
					message: 'Unable To Copy Link',
				});
			}
		},
		updateEventData(data) {
			this.isLoading = true;
			EventItemManager.updateEventDB(data, this.eventId)
				.then(() => {
					this.isLoading = false;
					this.getEventData();
					this.showNotification({
						type: 'success',
						message: 'Event Updated',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
		updateImage(file) {
			this.isLoading = true;
			EventItemManager.updateEventImage(file, this.eventId)
				.then(() => {
					this.isLoading = false;
					this.getEventData();
					this.showNotification({
						type: 'success',
						message: 'Event Updated',
					});
				})
				.catch((error) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: error.message,
					});
				});
		},
		yesClicked() {
			if (this.selectedId == this.deleteEventId) {
				this.deleteEvent();
			}
		},
		askDeleteQuestion() {
			this.selectedId = this.deleteEventId;
			this.question =
				'Are you sure you want to delete this event? All data associcated with this event will be deleted.';
			this.showQuestionModal = true;
		},
		deleteEvent() {
			this.isLoading = true;
			EventItemManager.deleteEventItem(this.eventId)
				.then(() => {
					this.navigateToEvents();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		getEventData() {
			this.isLoading = true;
			EventItemManager.getEventById(this.eventId).then((result) => {
				if (result) {
					this.eventData = result;
					this.setButtons();
					this.isLoading = false;
				} else {
					this.navigateToEvents();
				}
			});
		},
		childClicked(childClicked) {
			switch (childClicked.index) {
				case this.viewAttendeesId:
					this.navigateToEventUsers();
					break;
				case this.shareEventId:
					this.shareEvent();
					break;
				case this.cancelEventId:
					this.cancelEvent();
					break;
				case this.deleteEventId:
					this.askDeleteQuestion();
					break;
				default:
					break;
			}
		},
		setButtons() {
			var children = [
				{
					index: this.viewAttendeesId,
					title: 'View Attendees',
				},
				/*
				{
					index: this.sendNotificationId,
					title: 'Send Notification',
				},
				*/
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.shareEventId,
					title: 'Share Event',
				},
				{
					index: this.cancelEventId,
					title:
						this.eventData.isCancelled != null
							? this.eventData.isCancelled
								? 'Re-Open Event'
								: 'Cancel Event'
							: 'Cancel Event',
				},
				{
					index: -2,
					title: '',
					isDivider: true,
				},
				{
					index: this.deleteEventId,
					title: 'Delete Event',
					type: 'danger',
				},
			];
			this.buttonItems[0].children = children;
		},
		navigateToEventUsers() {
			this.$router.push({
				name: DashboardChildren.eventUserView.name,
				params: {
					eventData: this.eventData,
					eventId: this.eventId,
				},
			});
		},
		navigateToEvents() {
			this.$router.replace({
				name: DashboardChildren.events.name,
				params: { refresh: true },
			});
		},
	},
	mounted() {
		if (!this.eventId) {
			this.navigateToEvents();
		} else if (this.$route.params.eventData) {
			this.eventData = this.$route.params.eventData;
			this.setButtons();
		} else {
			this.getEventData();
		}
	},
};
</script>
