<template>
	<div>
		<BaseTable
			:isLoading="isLoading"
			:isFixed="false"
			:filterOption="filesData.length > 0"
			:algoliaSearch="false"
			:tableHeaders="tableHeaders"
			:tableData="filesData"
			:total="filesData.length"
			@currentPage="currentPage"
			@viewClicked="viewClicked"
			@actionDropdownClicked="actionDropdownClicked"
		/>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseTable from '../../../components/Tables/BaseTable.vue';

export default {
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		filesData: {
			type: Array,
			default: [],
		},
	},
	components: { Card, BaseTable },
	data() {
		return {
			tableHeaders: [
				{
					key: 'documentPhoto',
					label: 'Title',
					sortable: true,
					tdClass: 'align-middle',
					sortByFormatted: (value, key, item) => {
						return item.fileTitle;
					},
					formatter: (value, key, item) => {
						return {
							name: item.fileTitle,
							type: item.fileContentType ? item.fileContentType : null,
						};
					},
				},
				{
					key: 'actions',
					label: '',
					thStyle: { width: '5%' },
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return [
							{
								title: 'View File',
								click: 'viewClicked',
							},
							{
								isDivider: true,
							},
							{
								title: 'Delete File',
								click: 'deleteClicked',
								variant: 'danger',
							},
						];
					},
				},
			],
		};
	},
	methods: {
		actionDropdownClicked(item) {
			const click = item.item.click;
			const fileItem = item.row;
			if (click == 'viewClicked') {
				this.viewClicked(fileItem);
			} else if (click == 'deleteClicked') {
				this.deleteClicked(fileItem);
			}
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		currentPage(index) {
			this.$emit('currentPage', index);
		},
	},
};
</script>
