import Vue from 'vue';
import './resources/firebase';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import InstantSearch from 'vue-instantsearch';
import VueMeta from 'vue-meta';
import router from './routes/router';
import helpers from './helpers/helpers';
import store from './store/store';
import { auth } from './resources/firebase';
import { MySundayInformation } from './resources/MySundayInformation';

Vue.config.productionTip = false;

const helperPlugin = {
	install() {
		Vue.helpers = helpers;
		Vue.store = store;
		Vue.prototype.$store = store;
		Vue.prototype.$helpers = helpers;
		Vue.prototype.$mysunday = MySundayInformation;
	},
};

Vue.use(helperPlugin);
Vue.use(DashboardPlugin);
Vue.use(InstantSearch);
Vue.use(VueMeta, {
	refreshOnceOnNavigation: true,
});

auth.onAuthStateChanged((user) => {
	if (user) {
		return store
			.dispatch('getUser', user)
			.then(() => {
				openApp(router, App);
			})
			.catch(() => {
				store.dispatch('logOut');
				openApp(router, App);
			});
	} else {
		store.commit('setUser', null);
		openApp(router, App);
	}
});

function openApp(router, App) {
	new Vue({
		el: '#app',
		router,
		render: (h) => h(App),
	});
}
