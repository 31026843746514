<template>
	<div v-if="userData">
		<DashboardHeader
			:title="`${userData.firstName} ${userData.lastName}`"
			:buttonItems="buttonItems"
			@childClick="childClicked"
		/>
		<BaseContent>
			<b-overlay :show="isLoading" rounded="sm">
				<b-row class="align-items-center">
					<b-col>
						<ProfileHeaderCard :userData="userData" />
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<Card no-body>
							<b-tabs
								active-nav-item-class="bg-white font-weight-bold description"
								nav-class="bg-white description"
								card
								no-body
							>
								<b-tab title="Profile" active>
									<ProfileAccountCard :userData="userData" @onUpdate="getUserData" />
								</b-tab>

								<b-tab title="Events" @click="tabClicked(1)">
									<ProfileEventCard
										:userData="userData"
										:refresh="activeTabs.includes(1)"
									/>
								</b-tab>
								<b-tab title="Groups" @click="tabClicked(2)">
									<ProfileGroupCard
										:userData="userData"
										:refresh="activeTabs.includes(2)"
									/>
								</b-tab>
								<b-tab title="Relationships" @click="tabClicked(3)">
									<ProfileRelationshipsCard
										:userData="userData"
										:refresh="activeTabs.includes(3)"
									/>
								</b-tab>
								<b-tab title="Admin" @click="tabClicked(4)">
									<ProfileNotesCard
										:userData="userData"
										:refresh="activeTabs.includes(4)"
									/>
								</b-tab>

								<b-tab title="Giving (coming soon)" disabled></b-tab>
							</b-tabs>
						</Card>
					</b-col>
				</b-row>
			</b-overlay>
		</BaseContent>
		<QuestionModal
			:show="showQuestionModal"
			:question="question"
			@close="showQuestionModal = false"
			@yesClicked="yesClicked"
		/>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import BaseContent from '../../../components/BaseContent.vue';
import Card from '../../../components/Cards/Card.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import AppUserManager from '../../../models/AppUser/AppUserManager';
import { DashboardChildren } from '../../../routes/routesNames';
import ProfileAccountCard from '../../SecondaryComponents/MemberViews/ProfileAccountCard.vue';
import ProfileEventCard from '../../SecondaryComponents/MemberViews/ProfileEventCard.vue';
import ProfileGroupCard from '../../SecondaryComponents/MemberViews/ProfileGroupCard.vue';
import ProfileHeaderCard from '../../SecondaryComponents/MemberViews/ProfileHeaderCard.vue';
import ProfileNotesCard from '../../SecondaryComponents/MemberViews/ProfileNotesCard.vue';
import ProfileRelationshipsCard from '../../SecondaryComponents/MemberViews/ProfileRelationshipsCard.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	components: {
		DashboardHeader,
		BaseContent,
		Card,
		ProfileAccountCard,
		ProfileHeaderCard,
		ProfileGroupCard,
		ProfileNotesCard,
		ProfileRelationshipsCard,
		ProfileEventCard,
		QuestionModal,
	},
	props: {
		uid: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			isLoading: false,
			userData: null,
			modalQuestion: null,
			showQuestionModal: false,
			question: null,
			needUpdateProfile: false,
			selectedId: null,
			emailUserId: 0,
			textUserId: 1,
			resetUserPassId: 2,
			blockUserId: 3,
			adminUserId: 4,
			activeTabs: [],
			buttonItems: [
				{
					index: 0,
					title: 'Options',
					type: 'primary',
					children: [],
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getUserData() {
			this.isLoading = true;
			AppUserManager.getUserByUid(this.uid).then((userData) => {
				this.isLoading = false;
				if (userData) {
					this.userData = userData;
					this.setButtons();
				} else {
					this.navigateBack();
				}
			});
		},
		isAdminUser() {
			return AppUserManager.isAdminUser(this.userData);
		},
		isUserFlagged() {
			return AppUserManager.isUserFlagged(this.userData);
		},
		tabClicked(index) {
			if (!this.activeTabs.find((i) => i == index)) {
				this.activeTabs.push(index);
			}
		},
		setButtons() {
			this.buttonItems[0].children = [
				{
					index: this.textUserId,
					title: 'Send Text',
					isDisabled: true, //this.$helpers.getNullOrValue(this.userData.phoneNumber) == null,
				},
				{
					index: this.emailUserId,
					title: 'Send Email',
				},
				{
					index: this.resetUserPassId,
					title: 'Send Password Reset',
				},
				{
					index: -1,
					title: '',
					isDivider: true,
				},
				{
					index: this.adminUserId,
					title: this.isAdminUser() ? 'Remove Admin' : 'Make Admin',
				},
				{
					index: -2,
					title: '',
					isDivider: true,
				},

				{
					index: this.blockUserId,
					title: this.isUserFlagged() ? 'Unblock User' : 'Block User',
					type: 'danger',
				},
			];
		},
		sendEmail() {
			if (this.userData.email != null) {
				this.$helpers.openUrl(`mailto:${this.userData.email}`);
			}
		},
		sendPasswordReset() {
			AppUserManager.sendPasswordReset(this.userData.email)
				.then(() => {
					this.showNotification({
						type: 'success',
						message: `An email has been sent to: ${this.userData.email} to reset password.`,
					});
				})
				.catch((error) => {
					console.log(error);
				});
		},
		askAdminQuestion() {
			this.selectedId = this.adminUserId;
			this.question = this.isAdminUser()
				? "Do you want to remove this user's admin access?"
				: 'Do you want to make this user admin? They will have access to this admin portal.';
			this.showQuestionModal = true;
		},
		askBlockUserQuestion() {
			this.selectedId = this.blockUserId;
			this.question = this.isUserFlagged()
				? 'Do you want to unblock this user?'
				: 'Do you want to block this user?';
			this.showQuestionModal = true;
		},
		yesClicked() {
			if (this.selectedId == this.adminUserId) {
				this.adjustAdmin();
			} else if (this.selectedId == this.blockUserId) {
				this.blockUser();
			}
		},
		adjustAdmin() {
			this.isLoading = true;
			AppUserManager.adjustUserAdmin(this.userData, !this.isAdminUser())
				.then(() => {
					this.getUserData();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		blockUser() {
			this.isLoading = true;
			AppUserManager.updateUserField(this.userData.uid, {
				isFlagged: !this.isUserFlagged(),
			})
				.then(() => {
					this.getUserData();
				})
				.catch((errorMessage) => {
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		childClicked(childClicked) {
			switch (childClicked.index) {
				case this.resetUserPassId:
					this.sendPasswordReset();
					break;
				case this.emailUserId:
					this.sendEmail();
					break;
				case this.adminUserId:
					this.askAdminQuestion();
					break;
				case this.blockUserId:
					this.askBlockUserQuestion();
					break;
				default:
					break;
			}
		},
		navigateBack() {
			this.$router.replace({
				name: DashboardChildren.members.name,
			});
		},
	},
	mounted() {
		if (!this.uid) {
			this.navigateBack();
		} else if (this.$route.params.userData) {
			this.userData = this.$route.params.userData;
			this.setButtons();
		} else {
			this.getUserData();
		}
	},
};
</script>
