<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm">
			<Card body-classes="p-3" class="bg-gradient-primary">
				<div class="d-flex justify-content-between align-items-center">
					<div class="d-flex align-items-center mr-5">
						<div class="avatar-xl d-flex align-items-center">
							<img
								:src="`/img/icons/cards/${getCardImage()}`"
								alt="Image placeholder"
							/>
						</div>

						<h5 class="surtitle text-white">
							{{ getCardNumber() }}
						</h5>
					</div>
					<div v-if="creditCard">
						<Badge type="secondary"> Active</Badge>
					</div>
				</div>
			</Card>
		</b-overlay>
	</div>
</template>
<script>
import Badge from '../Badge.vue';
import Card from './Card.vue';

export default {
	props: {
		isLoading: false,
		creditCard: {
			type: Object,
			default: null,
		},
	},
	components: { Badge, Card },
	watch: {},
	methods: {
		getCardNumber() {
			if (this.creditCard != null) {
				if (this.creditCard.last4) {
					return `x${this.creditCard.last4}`;
				}
			}
			return 'No Payment Method';
		},
		getCardImage() {
			if (this.creditCard) {
				const brand = this.creditCard.brand;
				if (brand == 'visa') {
					return 'visa.png';
				} else if (brand == 'american express') {
					return 'amex.png';
				} else if (brand == 'amex') {
					return 'amex.png';
				} else if (brand == 'mastercard') {
					return 'mastercard.png';
				} else if (brand == 'discover') {
					return 'discover.png';
				}
			}
			return 'unknown.png';
		},
	},
};
</script>
