<template>
	<div>
		<Modal :show="showModal" @close="closeModal" size="xl" hide-footer>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">New Location</h5>
			</template>
			<hr class="m-0 p-0" />
			<b-overlay :show="isLoading" rounded="sm">
				<div>
					<h5
						class="py-2"
						v-if="errorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ errorMessage }}
					</h5>
				</div>
				<LocationView
					class="my-2"
					header-label="New Organization Location"
					title-label="Location Title"
					description-label="Location Address"
					:defaultValue="false"
					@onChange="onLocationData"
				/>
				<Card no-body>
					<CardHeader
						slot="header"
						:title="'Make Default Location'"
						:end-switch="{}"
						:end-switch-value="makeDefault"
						@change="onDefaulChange"
					/>
				</Card>

				<b-row class="mr-0 mt-3 mb-3">
					<div style="flex-grow: 1"></div>
					<BaseButton type="primary" :shake="shake" @click="submit"
						>Add Location</BaseButton
					>
				</b-row>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import BaseButton from '../../../components/BaseButton.vue';
import Modal from '../../../components/Modals/Modal.vue';
import LocationView from '../BaseNewViews/LocationView.vue';
import helpers from '../../../helpers/helpers';
import AppOrganizationManager from '../../../models/AppOrganization/AppOrganizationManager';
import { mapActions } from 'vuex';
import BaseSwitch from '../../../components/BaseSwitch.vue';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';

export default {
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			errorMessage: null,
			makeDefault: false,
			locationData: {
				title: null,
				description: null,
			},
		};
	},
	components: { LocationView, Modal, BaseButton, BaseSwitch, Card, CardHeader },
	methods: {
		...mapActions(['showNotification']),
		onLocationData(data) {
			this.locationData = data;
		},
		onDefaulChange(value) {
			this.makeDefault = value;
		},
		submit() {
			this.shake = false;
			if (helpers.isBlank(this.locationData.title)) {
				this.errorMessage = 'Please enter a location title.';
				this.shake = true;
				return;
			} else if (helpers.isBlank(this.locationData.description)) {
				this.errorMessage = 'Please enter a location address.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;
				this.addLocation();
			}
		},
		addLocation() {
			this.isLoading = true;
			AppOrganizationManager.addOrganizationLocation(
				this.locationData,
				this.makeDefault
			)
				.then((data) => {
					this.$emit('onNewLocation', data);
					this.isLoading = false;
					this.closeModal();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
			//this.resetView();
		},
	},
};
</script>
