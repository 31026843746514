<template>
	<div>
		<BaseTable
			v-if="userData"
			:isLoading="isLoading"
			:filterOption="allUserRelationships.length > 0"
			:algoliaSearch="false"
			:tableHeaders="userTitles"
			:tableData="allUserRelationships"
			:total="allUserRelationships.length"
			:enablePaging="false"
			@viewClicked="viewClicked"
		/>
	</div>
</template>
<script>
import Card from '../../../components/Cards/Card.vue';
import BaseTable from '../../../components/Tables/BaseTable.vue';
import AppUserManager from '../../../models/AppUser/AppUserManager';

export default {
	props: {
		userData: null,
		refresh: false,
	},
	components: { Card, BaseTable },
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getUserRelationships();
				}
			},
		},
	},
	data() {
		return {
			isLoading: false,
			allUserRelationships: [],
			userTitles: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					thStyle: { width: '60%' },
					formatter: (value, key, item) => {
						return {
							name: `${item.firstName} ${item.lastName}`,
							photo: item.profilePhotoURL,
						};
					},
					sortByFormatted: (value, key, item) => {
						return `${item.firstName} ${item.lastName}`;
					},
				},
				{
					key: 'relationshipStatus',
					label: 'Status',
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return item.isPending != null || item.isRequesting != null
							? 'Pending'
							: '';
					},
					sortByFormatted: (value, key, item) => {
						return item.isPending != null || item.isRequesting != null;
					},
				},
				{
					key: 'relationshipType',
					label: 'Relationship',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return value.charAt(0).toUpperCase() + value.slice(1);
					},
					sortByFormatted: (value, key, item) => {
						return value.charAt(0).toUpperCase() + value.slice(1);
					},
				},
			],
		};
	},
	methods: {
		getUserRelationships() {
			this.isLoading = true;
			AppUserManager.getUserRelationships(this.userData.uid)
				.then((users) => {
					this.isLoading = false;
					this.allUserRelationships = users;
				})
				.catch((error) => {
					this.isLoading = false;
				});
		},
		viewClicked(item) {
			console.log('selected relationship: ', item);
		},
	},
};
</script>
