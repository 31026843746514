<template>
	<BaseTable
		:headerTitle="headerTitle"
		:isLoading="isLoading"
		:filterOption="filterOption"
		:algoliaSearch="false"
		:tableHeaders="tableHeaders"
		:tableData="tableData"
		:recordsPerPage="getRecordsPerPage()"
		:total="total"
		@currentPage="currentPage"
		@viewClicked="viewClicked"
	/>
</template>
<script>
import BaseTable from '../../../components/Tables/BaseTable.vue';
import AppUserManager from '../../../models/AppUser/AppUserManager';

export default {
	components: { BaseTable },
	props: {
		isLoading: Boolean,
		headerTitle: {
			type: String,
			default: null,
		},
		filterOption: {
			type: Boolean,
			default: true,
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
		total: {
			type: Number,
			description: 'Total Number of data for table',
		},
	},
	data() {
		return {
			recordsPerPage: 0,
			tableHeaders: [
				{
					key: 'nameAndPhoto',
					label: 'Name',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return {
							name: `${item.userData.firstName} ${item.userData.lastName}`,
							photo: item.userData.profilePhotoURL,
							isAdmin: AppUserManager.isAdminUser(item.userData),
							isFlagged: AppUserManager.isUserFlagged(item.userData),
						};
					},
					sortByFormatted: (value, key, item) => {
						return `${item.userData.firstName} ${item.userData.lastName}`;
					},
				},
				{
					key: 'username',
					label: 'Username',
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return `@${item.userData.username}`;
					},
				},
				{
					key: 'quantity',
					label: 'Quantity',
					sortable: true,
					tdClass: 'align-middle',
					formatter: (value, key, item) => {
						return item.eventData.quantity;
					},
				},
			],
		};
	},
	methods: {
		getRecordsPerPage() {
			return AppUserManager.limitFetch;
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		currentPage(index) {
			this.$emit('currentPage', index);
		},
	},
};
</script>
