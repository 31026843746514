<template>
	<div class="wrapper">
		<Notifications />
		<SideMenu />
		<div class="main-content">
			<Header></Header>
			<div class="my-2" @click="$sidebar.displaySidebar(false)">
				<fade-transition :duration="200" origin="center top" mode="out-in">
					<!-- your content here -->
					<router-view></router-view>
				</fade-transition>
			</div>
			<Footer></Footer>
		</div>
	</div>
</template>
<script>
/* eslint-disable no-new */
import { FadeTransition } from 'vue2-transitions';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import { mapActions, mapGetters } from 'vuex';
import Notifications from '../../components/NotificationPlugin/Notifications.vue';
import SideMenu from './SideMenu.vue';
import Header from './Header.vue';
import Footer from './Footer.vue';

function hasElement(className) {
	return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
	if (hasElement(className)) {
		new PerfectScrollbar(`.${className}`);
	} else {
		// try to init it later in case this component is loaded async
		setTimeout(() => {
			initScrollbar(className);
		}, 100);
	}
}

export default {
	metaInfo: {},
	components: {
		FadeTransition,
		Notifications,
		SideMenu,
		Header,
		Footer,
	},
	computed: {
		...mapGetters({
			notification: 'getNotification',
		}),
	},
	watch: {
		notification(newNotification, oldNotification) {
			if (newNotification) {
				this.$notify({
					verticalAlign: 'top',
					horizontalAlign: 'center',
					type: newNotification.type,
					message: newNotification.message,
				});
			}
		},
	},
	methods: {
		...mapActions(['showNotification', 'removeNotification']),
		closeNotificationModal() {
			this.removeNotification();
		},
		loadFreshDeskHelp() {
			const freshScript = document.createElement('script');
			freshScript.setAttribute(
				'src',
				'https://widget.freshworks.com/widgets/151000003588.js'
			);
			freshScript.setAttribute('type', 'text/javascript');
			document.head.appendChild(freshScript);
		},
		initScrollbar() {
			let isWindows = navigator.platform.startsWith('Win');
			if (isWindows) {
				initScrollbar('sidenav');
			}
		},
	},
	mounted() {
		this.loadFreshDeskHelp();
	},
};
</script>
<style lang="scss"></style>

<!--
//<script type='text/javascript' src='https://widget.freshworks.com/widgets/151000003588.js' async defer></script>

-->
