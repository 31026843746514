<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<BaseContent>
			<LoadingProgress :is-loading="isLoading" :progress="isLoadingProgress">
				<AudioTable
					:isLoading="isLoading"
					:audioData="audios"
					@currentPage="currentPage"
					@viewClicked="viewClicked"
					@deleteClicked="askDeleteQuestion"
				/>
			</LoadingProgress>
		</BaseContent>
		<NewAudioModal
			:show-modal="showModal"
			@close="showModal = false"
			@uploadData="uploadData"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="showQuestion"
			@yesClicked="deleteAudio"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import BaseContent from '../../../components/BaseContent.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import AudioItemManager from '../../../models/AudioItem/AudioItem.Manager';
import AudioTable from '../../SecondaryComponents/AudioViews/AudioTable.vue';
import LoadingProgress from '../../../components/LoadingProgress.vue';
import NewAudioModal from '../../SecondaryComponents/AudioViews/NewAudioModal.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';

export default {
	components: {
		DashboardHeader,
		BaseContent,
		AudioTable,
		LoadingProgress,
		NewAudioModal,
		QuestionModal,
	},
	data() {
		return {
			isLoading: false,
			isLoadingProgress: 0,
			showModal: false,
			showQuestionModal: false,
			showQuestion: null,
			selectedAudio: null,
			audios: [],
			lastFetched: null,
			buttonItems: [
				{
					index: 0,
					title: 'New Audio',
					icon: 'fa fa-plus',
					type: 'primary',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getAudios(maxCount) {
			this.isLoading = true;
			AudioItemManager.getOrgAudios(this.lastFetched, maxCount).then((items) => {
				this.audios.push.apply(this.audios, items.items);
				this.lastFetched = items.lastCursor;
				this.isLoading = false;
			});
		},
		currentPage(index) {
			const minCount = AudioItemManager.limitFetch * (index - 1);
			const maxCount = AudioItemManager.limitFetch * index;
			const inBetween = maxCount - this.audios.length;
			if (minCount >= this.audios.length && this.lastFetched != null) {
				const value =
					inBetween > AudioItemManager.limitFetch
						? inBetween
						: EventItemManager.limitFetch;
				this.getAudios(value);
			}
		},
		checkIfCanUpload() {
			AudioItemManager.canUpload().then((canUpload) => {
				if (canUpload) {
					this.showModal = true;
				} else {
					this.showError('You have reached your limit on your current plan.');
				}
			});
		},
		uploadData(data) {
			const audioItem = data.audioItem;
			const audioThumbnail = data.audioThumbnail;
			const audioData = data.audioData;
			this.isLoading = true;
			AudioItemManager.addAudioToDB(
				audioData,
				audioItem,
				audioThumbnail,
				audioData.audioTitle,
				audioItem.type,
				(progress) => {
					this.isLoadingProgress = progress;
				}
			)
				.then((audioData) => {
					this.isLoading = false;
					this.audios.push(audioData);
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		deleteAudio() {
			this.isLoading = true;
			AudioItemManager.deleteAudioItem(this.selectedAudio.audioId)
				.then(() => {
					this.audios = this.audios.filter(
						(e) => e.audioId !== this.selectedAudio.audioId
					);
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		askDeleteQuestion(audioItem) {
			this.selectedAudio = audioItem;
			this.showQuestion = 'Are you sure you want to delete this audio?';
			this.showQuestionModal = true;
		},
		showError(message) {
			this.showNotification({
				type: 'danger',
				message: message,
			});
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.checkIfCanUpload();
			}
		},
		viewClicked(audioData) {},
	},
	mounted() {
		this.getAudios(null);
	},
};
</script>
