export const MySundayInformation = {
	mysundayName: 'MySunday',
	mysundayFullName: 'MySunday, LLC',
	mysundayWebsite: 'https://www.mysundayteam.com',
	mysundayEmail: 'info@mysundayteam.com',
	mysundaySupportEmail: 'support@mysundayteam.com',
	mysundaySupport: 'https://support.mysundayteam.com/',
	mysundaySupportTicket: 'https://support.mysundayteam.com/support/tickets/new',
	mysundayBillingPortal:
		'https://billing.mysundayteam.com/p/login/3cs5lH9iq13sb7O7ss',
	isTestMode: true,
	mysundayStripePubKey: 'pk_live_RTmJ7pf9ptyZIJYHIQBEPuCW00q83RkLNa',
	mysundayStripeTestPubKey: 'pk_test_mZm7KAKe5dU2nbdvcaL4HqLx00g7DqiUAQ',
	appShareBase: `https://mysun.day`,
	iosBundleId: 'com.dominionitllc.mysundayapp',
	iosAppStoreId: '1483268591',
	androidPackage: 'com.dominionitllc.mysunday',
	appDescription: `MySunday is a church platform designed to connect your congregation throughout the week. Our software provides a user-friendly management system and centralizes all church communication.`,
};
