import {
	collection,
	doc,
	FieldValue,
	getDoc,
	serverTimestamp,
} from 'firebase/firestore';
import { FirebaseDynamicLinks } from 'firebase-dynamic-links';
import store from '../../store/store';
import { firebaseConfig, firestore } from '../../resources/firebase';
import {
	deleteObject,
	getDownloadURL,
	listAll,
	uploadBytes,
	uploadBytesResumable,
	uploadString,
} from 'firebase/storage';
import { MySundayInformation } from '../../resources/MySundayInformation';

export default class FirebaseManager {
	static DataType = {
		eventItem: 'eventItem',
		postItem: 'postItem',
		groupItem: 'groupItem',
		videoItem: 'videoItem',
		audioItem: 'audioItem',
		fileItem: 'fileItem',
		callItem: 'callItem',
		givingItem: 'givingItem',
		messageItem: 'messageItem',
	};

	static organizationRef() {
		return collection(firestore, 'organizations');
	}
	static organizationBillingRef() {
		return collection(firestore, 'organizations_billing');
	}
	static accountProductsRef() {
		return collection(firestore, 'accountProducts');
	}
	static tempOrganizationRef() {
		return collection(firestore, 'tempOrganizations');
	}
	static usersRef() {
		return collection(firestore, 'users');
	}
	/*
	static accountAppInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'appInformation');
	}
	static accountPaymentInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'paymentInformation');
	}
	static accountPublicInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'publicInformation');
	}
	static accountSocialInformationRef(firestore) {
		return doc(firestore, this.accountRef(firestore).id, 'socialInformation');
	}
	
	static allUserRef(firestore) {
		return collection(firestore, 'users');
	}
	static allReportsRef(firestore) {
		return collection(firestore, 'reports');
	}
	static allUsersReportsRef(firestore) {
		return collection(firestore, 'reports/users/reports');
	}
	static allEventReportsRef(firestore) {
		return collection(firestore, 'reports/events/reports');
	}
	static allMessageChannelRef(firestore) {
		return collection(firestore, 'messageChannels');
	}
	static allNotificationsRef(firestore) {
		return collection(firestore, 'notifications');
	}
	static allAdminNotificationsRef(firestore) {
		return collection(firestore, 'adminNotifications');
	}
	static allFeaturedRef(firestore) {
		return collection(firestore, 'featured');
	}
	static allCategoriesRef(firestore) {
		return collection(firestore, 'categories');
	}
	static allClassesRef(firestore) {
		return collection(firestore, 'classes');
	}
	static allEventsRef(firestore) {
		return collection(firestore, 'events');
	}
	static allProductRef(firestore) {
		return collection(firestore, 'products');
	}
	static allCalendarItemsRef(firestore) {
		return collection(firestore, 'calendarItems');
	}
	static allCouponsRef(firestore) {
		return collection(firestore, 'coupons');
	}
	static allVideosRef(firestore) {
		return collection(firestore, 'videos');
	}
	static allFilesRef(firestore) {
		return collection(firestore, 'files');
	}
*/
	static timestamp() {
		return serverTimestamp();
	}

	static uploadFile(storageRef, file, fileType, loadingCallback) {
		return new Promise((resolve, reject) => {
			const metaData = {
				contentType: fileType,
			};
			const uploadTask = uploadBytesResumable(storageRef, file, metaData);

			// Listen for state changes, errors, and completion of the upload.
			uploadTask.on(
				'state_changed',
				(snapshot) => {
					// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
					const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

					loadingCallback(progress);
					switch (snapshot.state) {
						case 'paused':
							console.log('Upload is paused');
							break;
						case 'running':
							console.log('Upload is running');
							break;
					}
				},
				(error) => {
					// A full list of error codes is available at
					// https://firebase.google.com/docs/storage/web/handle-errors
					return reject(error.message);
				},
				() => {
					// Upload completed successfully, now we can get the download URL
					getDownloadURL(uploadTask.snapshot.ref)
						.then((downloadURL) => {
							return resolve(downloadURL);
						})
						.catch((error) => {
							return reject(error.message);
						});
				}
			);
		});
	}

	static uploadImage(storageRef, imageFile, stringType, loadingCallback) {
		return new Promise((resolve, reject) => {
			const metaData = {
				contentType: 'image/jpeg',
			};
			if (stringType) {
				uploadString(storageRef, imageFile, 'data_url')
					.then((snapshot) => {
						getDownloadURL(snapshot.ref)
							.then((downloadURL) => {
								return resolve(downloadURL);
							})
							.catch((error) => {
								return reject(error.message);
							});
					})
					.catch((error) => {
						return reject(error.message);
					});
			} else {
				uploadBytes(storageRef, imageFile, metaData)
					.then((snapshot) => {
						getDownloadURL(snapshot.ref)
							.then((downloadURL) => {
								return resolve(downloadURL);
							})
							.catch((error) => {
								return reject(error.message);
							});
					})
					.catch((error) => {
						return reject(error.message);
					});
			}
		});
	}

	static deleteStorageRef(storageRef) {
		return new Promise((resolve, reject) => {
			listAll(storageRef).then((res) => {
				const items = res.items;
				const total = items.length;
				var counter = 0;
				if (total == counter) {
					return resolve();
				} else {
					items.forEach((storageR) => {
						deleteObject(storageR).finally(() => {
							if (counter == total - 1) {
								return resolve();
							}
							counter += 1;
						});
					});
				}
			});
		}).catch((error) => {
			return resolve();
		});
	}

	static createDynamicLink(id, groupId, type, title, description, imageURL) {
		return new Promise((resolve, reject) => {
			//DOCUMENTATION = https://firebase.google.com/docs/dynamic-links/create-manually
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const orgRef = doc(
				firestore,
				`${FirebaseManager.organizationRef().path}/${orgId}`
			);
			getDoc(orgRef)
				.then((snapshot) => {
					const orgData = snapshot.data();
					if (!snapshot.exists) {
						return resolve(null);
					}

					const socialTitle =
						title != null
							? title
							: `View this ${orgData.orgName} item on the ${MySundayInformation.mysundayName} App`;
					const socialDescription =
						description != null ? description : MySundayInformation.appDescription;
					const socialImageLink = imageURL != null ? imageURL : orgData.orgLogo;

					var linkString = `/${this.DataType[type]}?&id=${id}&orgId=${orgId}`;
					if (groupId != null) {
						linkString = `${linkString}&groupId=${groupId}`;
					}
					const oflLink = `${MySundayInformation.mysundayWebsite}/share${linkString}`;

					const firebaseDynamicLinks = new FirebaseDynamicLinks(
						firebaseConfig.apiKey
					);

					return firebaseDynamicLinks
						.createLink({
							longDynamicLink: `${MySundayInformation.appShareBase}/?link=${
								MySundayInformation.appShareBase
							}${encodeURIComponent(linkString)}&efr=1&apn=${
								MySundayInformation.androidPackage
							}&ibi=${MySundayInformation.iosBundleId}&isi=${
								MySundayInformation.iosAppStoreId
							}&ofl=${encodeURIComponent(
								oflLink
							)}&st=${socialTitle}&sd=${socialDescription}&si=${encodeURIComponent(
								socialImageLink
							)}`,
						})
						.then((linkResponse) => {
							return resolve(linkResponse.shortLink);
						})
						.catch((error) => {
							return resolve(null);
						});
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}
}
