<template>
	<div>
		<EventTable
			:isLoading="isLoading"
			:isUserTable="true"
			:eventData="events"
			@currentPage="currentPage"
			@viewClicked="viewClicked"
		/>
	</div>
</template>
<script>
import EventItemManager from '../../../models/EventItem/EventItemManager';
import { DashboardChildren } from '../../../routes/routesNames';
import EventTable from '../EventViews/EventTable.vue';

export default {
	props: {
		userData: null,
		refresh: false,
	},
	components: { EventTable },
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getUserEvents();
				}
			},
		},
	},
	data() {
		return {
			isLoading: false,
			events: [],
			lastFetched: null,
		};
	},
	methods: {
		getUserEvents(maxCount) {
			this.isLoading = true;
			EventItemManager.getUserEvents(this.userData.uid, this.lastFetched, maxCount)
				.then((items) => {
					this.events.push.apply(this.events, items.items);
					this.lastFetched = items.lastCursor;
					this.isLoading = false;
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
		currentPage(index) {
			const minCount = EventItemManager.limitFetch * (index - 1);
			const maxCount = EventItemManager.limitFetch * index;
			const inBetween = maxCount - this.events.length;
			if (minCount >= this.events.length && this.lastFetched != null) {
				const value =
					inBetween > EventItemManager.limitFetch
						? inBetween
						: EventItemManager.limitFetch;
				this.getUserEvents(value);
			}
		},
		viewClicked(data) {
			const eventData = data.eventData;
			this.$router.push({
				name: DashboardChildren.eventView.name,
				params: {
					eventId: eventData.eventId,
					eventData: eventData,
				},
			});
		},
	},
	mounted() {},
};
</script>
