<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
export default {
	metaInfo: {
		title: `Dashboard`,
		titleTemplate: `%s | MySunday`,
	},
};
</script>
