<template>
	<div>
		<GroupTable
			:isLoading="isLoading"
			:groupsData="groups"
			@currentPage="currentPage"
			@viewClicked="viewClicked"
		/>
	</div>
</template>
<script>
import GroupItemManager from '../../../models/GroupItem/GroupItemManager';
import { DashboardChildren } from '../../../routes/routesNames';
import GroupTable from '../GroupViews/GroupTable.vue';

export default {
	props: {
		userData: null,
		refresh: false,
	},
	components: { GroupTable },
	watch: {
		refresh: {
			handler(val) {
				if (val) {
					return this.getUserGroups();
				}
			},
		},
	},
	data() {
		return {
			isLoading: false,
			groups: [],
			lastFetched: null,
		};
	},
	methods: {
		getUserGroups(maxCount) {
			this.isLoading = true;
			GroupItemManager.getUserGroups(this.userData.uid, this.lastFetched, maxCount)
				.then((items) => {
					this.groups.push.apply(this.groups, items.items);
					this.lastFetched = items.lastCursor;
					this.isLoading = false;
				})
				.catch(() => {
					this.isLoading = false;
				});
		},
		currentPage(index) {
			const minCount = GroupItemManager.limitFetch * (index - 1);
			const maxCount = GroupItemManager.limitFetch * index;
			const inBetween = maxCount - this.groups.length;
			if (minCount >= this.groups.length && this.lastFetched != null) {
				const value =
					inBetween > GroupItemManager.limitFetch
						? inBetween
						: GroupItemManager.limitFetch;
				this.getUserGroups(value);
			}
		},
		viewClicked(groupData) {
			this.$router.push({
				name: DashboardChildren.groupView.name,
				params: {
					groupId: groupData.groupId,
					groupData: groupData,
				},
			});
		},
	},
	mounted() {},
};
</script>
