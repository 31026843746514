<template>
	<div>
		<b-overlay :show="isLoading" rounded="sm">
			<Card shadow no-body>
				<div
					class="p-3"
					style="border: 1px solid lightgray; border-radius: 5px"
					:style="{
						backgroundColor: isCurrent ? '#e8e8e8' : '',
					}"
				>
					<Badge class="my-1" :type="planData.billingTag ? 'success' : 'default'">{{
						planBadgeTitle
							? planBadgeTitle
							: planData.billingTag
							? planData.billingTag
							: planData.billingType
					}}</Badge>
					<b-row class="my-2">
						<b-col>
							<b-avatar
								size="6rem"
								variant=""
								rounded
								src="/img/AppIcon_Rounded.png"
							/>
						</b-col>
					</b-row>

					<h3 class="m-0">{{ planData.billingTitle }}</h3>
					<h6 class="my-2 font-weight-normal text-muted">
						{{ planData.billingDescription }}
					</h6>
					<b-row>
						<b-col class="d-flex align-items-baseline" cols="auto">
							<h1 class="m-0 p-0">
								{{ this.$helpers.amountToString(planData.amount) }}
							</h1>
							<h6 class="m-0 text-start text-muted">/mo</h6>
						</b-col>
						<b-col> </b-col>
					</b-row>
					<BaseButton
						v-if="isCurrent"
						class="my-2"
						block
						type="warning"
						@click="cancelClicked"
						>Cancel Plan</BaseButton
					>
					<BaseButton
						v-if="!isCurrent"
						class="my-2"
						block
						type="primary"
						@click="subscribeClicked"
						>Subscribe</BaseButton
					>
				</div>
				<div v-if="false">
					<CardHeader
						type="light"
						slot="header"
						:title="planData.billingTitle"
						:endButton="showUpdateButton ? { title: 'View Next Invoice' } : null"
					/>
					<h5 class="mb-0 text-muted">
						{{ planData.billingDescription }}
					</h5>
					<CardHeader
						slot="footer"
						title="Amount:"
						:end-title="this.$helpers.amountToString(planData.amount)"
					/>
				</div>
			</Card>
		</b-overlay>
	</div>
</template>
<script>
import Badge from '../../../components/Badge.vue';
import BaseButton from '../../../components/BaseButton.vue';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';

export default {
	components: { Card, CardHeader, Badge, BaseButton },
	props: {
		isLoading: {
			type: Boolean,
			default: false,
		},
		planData: {
			type: Object,
			default: null,
		},
		planBadgeTitle: {
			type: String,
			default: null,
		},
		showUpdateButton: {
			type: Boolean,
			default: false,
		},
		isCurrent: {
			type: Boolean,
			default: false,
		},
	},
	methods: {
		cancelClicked() {
			this.$emit('cancelClicked', this.planData);
		},
		subscribeClicked() {
			this.$emit('subscribeClicked', this.planData);
		},
	},
};
</script>
