<template>
	<div v-if="organization">
		<DashboardHeader :title="$route.name" />
		<BaseContent>
			<UserTable
				:headerTitle="'All Members'"
				:isLoading="isLoading"
				:tableData="users"
				:total="organization.orgMembers"
				@currentPage="currentPage"
				@viewClicked="viewClicked"
			/>
		</BaseContent>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';
import AppUserManager from '../../../models/AppUser/AppUserManager';
import BaseContent from '../../../components/BaseContent.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import UserTable from '../../../components/Tables/UserTable.vue';
import { DashboardChildren } from '../../../routes/routesNames';
import Badge from '../../../components/Badge.vue';

export default {
	components: { DashboardHeader, UserTable, BaseContent, Badge },
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	props: {},
	data() {
		return {
			isLoading: false,
			users: [],
			usersCount: 0,
			selectedCategory: [],
			allCategories: [],
			lastFetched: null,
		};
	},
	methods: {
		getUsers(maxCount) {
			this.isLoading = true;
			AppUserManager.getUsers(this.lastFetched, maxCount).then(
				([users, lastDocument]) => {
					this.users.push.apply(this.users, users);
					this.lastFetched = lastDocument;
					this.isLoading = false;
				}
			);
		},
		currentPage(index) {
			const minCount = AppUserManager.limitFetch * (index - 1);
			const maxCount = AppUserManager.limitFetch * index;
			const inBetween = maxCount - this.users.length;
			if (minCount >= this.users.length && this.lastFetched != null) {
				const value =
					inBetween > AppUserManager.limitFetch
						? inBetween
						: AppUserManager.limitFetch;
				this.getUsers(value);
			}
		},
		onCategoryClicked(categoryItem) {
			this.selectedCategory = categoryItem;
			if (this.selectedCategory.categoryId == this.defaultCategory.categoryId) {
				//this.filterClassItems = this.allClassItems;
			} else {
				/*
				this.filterClassItems = this.allClassItems.filter((e) => {
					e.categoryId ? e.categoryId : '' == this.selectedCategory.categoryId;
				});
				*/
			}
		},
		addUsers() {},
		viewClicked(userData) {
			this.$router.push({
				name: DashboardChildren.memberView.name,
				params: {
					userData: userData,
				},
				query: {
					uid: userData.uid,
				},
			});
		},
	},
	mounted() {
		this.getUsers(null);
	},
};
</script>
