<template>
	<div>
		<Modal :show="showModal" @close="closeModal" hide-footer>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">New Featured</h5>
			</template>
			<hr class="m-0 p-0" />
			<b-overlay :show="isLoading" rounded="sm">
				<div>
					<h6
						class="py-2"
						v-if="errorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ errorMessage }}
					</h6>
				</div>

				<form ref="form">
					<div class="my-3">
						<BaseDropbox @onFile="onFileChange" :ifImageFill="false"> </BaseDropbox>
					</div>

					<BaseInput
						v-if="false"
						label="Featured Web Link"
						type="url"
						v-model="featuredLink"
					></BaseInput>
					<BaseInput
						label="Featured Placement"
						type="number"
						id="placement"
						name="placement"
						min="1"
						max="10"
						required
						v-model="featuredIndex"
					></BaseInput>
					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton
							type="primary"
							:loading="isLoading"
							:shake="shake"
							@click="submit"
							>Add Featured</BaseButton
						>
					</b-row>
				</form>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import VueDropZone from 'vue2-dropzone';
import 'vue2-dropzone/dist/vue2Dropzone.min.css';
import Modal from '../../../components/Modals/Modal.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import BaseButton from '../../../components/BaseButton.vue';
import FeaturedItemManager from '../../../models/FeaturedItem/FeaturedItemManager';
import BaseDropbox from '../../../components/Inputs/BaseDropbox.vue';

export default {
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: { Modal, VueDropZone, BaseInput, BaseButton, BaseDropbox },
	data() {
		return {
			shake: false,
			isLoading: false,
			imageItem: null,
			featuredLink: null,
			featuredIndex: 1,
			errorMessage: null,
		};
	},
	methods: {
		onFileChange(file) {
			this.imageItem = file;
		},
		submit() {
			this.shake = false;

			if (this.imageItem == null) {
				this.errorMessage = 'Please select a file.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;
				this.addFeaturedItem();
			}
		},
		addFeaturedItem() {
			this.isLoading = true;
			FeaturedItemManager.addFeaturedItem(
				this.imageItem,
				this.featuredIndex,
				this.featuredLink
			)
				.then((featuredData) => {
					this.isLoading = false;
					//	this.resetView();
					this.$emit('newFeatured', featuredData);
					this.closeModal();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.errorMessage = errorMessage;
					this.shake = true;
				});
		},
		resetView() {
			this.imageItem = null;
			this.featuredLink = null;
			this.errorMessage = null;
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
	},
};
</script>
<style></style>
