import {
	collection,
	collectionGroup,
	deleteDoc,
	deleteField,
	doc,
	getCountFromServer,
	getDoc,
	getDocs,
	limit,
	orderBy,
	query,
	setDoc,
	startAfter,
	where,
} from 'firebase/firestore';
import { firestore, storage } from '../../resources/firebase';
import store from '../../store/store';
import FirebaseManager from '../Firebase/FirebaseManager';
import helpers from '../../helpers/helpers';
import { ref } from 'firebase/storage';
import AppUserManager from '../AppUser/AppUserManager';
import { PricingInformation } from '../../resources/PricingInformation';

export default class GroupItemManager {
	static limitFetch = 15;

	static getOrgGroupsRef(orgId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/groups`
		);
	}

	static getOrgGroupPostsRef(orgId, groupId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/groups/${groupId}/posts`
		);
	}

	static getOrgGroupFilesRef(orgId, groupId) {
		return collection(
			firestore,
			`${FirebaseManager.organizationRef().path}/${orgId}/groups/${groupId}/files`
		);
	}

	static canUpload() {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const ref = this.getOrgGroupsRef(organization.orgId);
			getCountFromServer(ref)
				.then((snapshot) => {
					const planInfo = PricingInformation.find(
						(e) => e.billingType == organization.billingType
					);
					const maxAllowed = planInfo.groups.count;
					return resolve(
						maxAllowed == null ? true : snapshot.data().count < maxAllowed
					);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getOrgGroups(lastDocument, maxCount) {
		return new Promise((resolve, reject) => {
			const fetchCount =
				maxCount != null
					? maxCount > GroupItemManager.limitFetch
						? maxCount
						: GroupItemManager.limitFetch
					: GroupItemManager.limitFetch;
			const organization = store.state.organization;
			const groupRef = this.getOrgGroupsRef(organization.orgId);
			var queryRef = query(
				groupRef,
				orderBy('created', 'desc'),
				limit(fetchCount)
			);
			if (lastDocument) {
				queryRef = query(
					groupRef,
					orderBy('created', 'desc'),
					startAfter(lastDocument),
					limit(fetchCount)
				);
			}

			getDocs(queryRef)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					const lastDocument = snapshot.docs.lastItem;
					var counter = 0;
					var items = [];
					if (total == counter) {
						return resolve({ items: items, lastCursor: lastDocument });
					}
					documents.forEach((document) => {
						var groupData = document.data();
						items.push(groupData);
						if (counter == total - 1) {
							return resolve({ items: items, lastCursor: lastDocument });
						}
						counter += 1;
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getGroupUsers(groupId, lastDocument, maxCount, isPending) {
		return new Promise((resolve, reject) => {
			const fetchCount =
				maxCount != null
					? maxCount > AppUserManager.limitFetch
						? maxCount
						: AppUserManager.limitFetch
					: AppUserManager.limitFetch;
			const organization = store.state.organization;
			const groupMembersRef = collection(
				firestore,
				`${this.getOrgGroupsRef(organization.orgId).path}/${groupId}/groupUsers`
			);
			var queryRef = query(
				groupMembersRef,
				orderBy('created', 'desc'),
				limit(fetchCount)
			);
			if (isPending) {
				queryRef = query(
					groupMembersRef,
					orderBy('created', 'desc'),
					where('isPending', '==', true),
					limit(fetchCount)
				);
			}
			if (lastDocument) {
				queryRef = query(
					groupMembersRef,
					orderBy('created', 'desc'),
					startAfter(lastDocument),
					limit(fetchCount)
				);
			}
			getDocs(queryRef)
				.then((snapshot) => {
					var items = [];
					if (!snapshot.empty) {
						const documents = snapshot.docs;
						const total = documents.length;
						const lastDocument =
							total >= fetchCount ? documents[documents.length - 1] : null;
						var counter = 0;

						documents.forEach((document) => {
							var memberData = document.data();
							if (memberData.uid) {
								AppUserManager.getUserByUid(memberData.uid).then((userData) => {
									if (userData) {
										items.push({
											userData: userData,
											groupData: memberData,
										});
									}
									if (counter == total - 1) {
										return resolve([items, lastDocument]);
									}
									counter += 1;
								});
							} else {
								if (counter == total - 1) {
									return resolve([items, lastDocument]);
								}
								counter += 1;
							}
						});
					} else {
						return resolve([items, null]);
					}
				})
				.catch((error) => {
					console.error(error.message);
					return reject(error.message);
				});
		});
	}

	static getUserGroups(uid, lastDocument, maxCount) {
		return new Promise((resolve, reject) => {
			const fetchCount =
				maxCount != null
					? maxCount > GroupItemManager.limitFetch
						? maxCount
						: GroupItemManager.limitFetch
					: GroupItemManager.limitFetch;
			const organization = store.state.organization;
			var ref = query(
				collectionGroup(firestore, 'groupUsers'),
				where('uid', '==', uid),
				limit(fetchCount)
			);

			if (lastDocument) {
				ref = query(
					collectionGroup(firestore, 'groupUsers'),
					where('uid', '==', uid),
					startAfter(lastDocument),
					limit(fetchCount)
				);
			}

			getDocs(ref)
				.then((snapshot) => {
					const documents = snapshot.docs;
					const total = documents.length;
					const lastDocument = snapshot.docs.lastItem;
					var counter = 0;
					var items = [];
					if (total == counter) {
						return resolve({ items: items, lastCursor: lastDocument });
					}
					documents.forEach((document) => {
						var groupData = document.data();
						const groupId = groupData.groupId ? groupData.groupId : null;
						const orgId = groupData.orgId ? groupData.orgId : null;
						if (groupId && orgId && orgId == organization.orgId) {
							return this.getGroupById(groupData.groupId).then((groupData) => {
								if (groupData) {
									items.push(groupData);
								}

								if (counter == total - 1) {
									return resolve({ items: items, lastCursor: lastDocument });
								}
								counter += 1;
							});
						} else {
							if (counter == total - 1) {
								return resolve({ items: items, lastCursor: lastDocument });
							}
							counter += 1;
						}
					});
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static getGroupById(groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const groupIdRef = doc(
				firestore,
				`${this.getOrgGroupsRef(organization.orgId).path}/${groupId}`
			);
			getDoc(groupIdRef)
				.then((snapshot) => {
					return resolve(snapshot.exists ? snapshot.data() : null);
				})
				.catch((error) => {
					return resolve(null);
				});
		});
	}

	static addGroupToDB(data, groupImage) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const user = store.state.user;
			const orgId = organization.orgId;
			const newRef = doc(this.getOrgGroupsRef(orgId));
			const storageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/groups/${
					newRef.id
				}/image:${newRef.id}.jpeg`
			);

			FirebaseManager.uploadImage(storageRef, groupImage)
				.then((downloadURL) => {
					FirebaseManager.createDynamicLink(
						newRef.id,
						null,
						FirebaseManager.DataType.groupItem,
						data.groupTitle,
						data.groupDescription,
						downloadURL
					).then((shareLink) => {
						var groupData = helpers.removeAllNullValues(data);
						groupData.shareLink = shareLink;
						groupData.imageItem = [{ imageUrl: downloadURL }];
						groupData.groupId = newRef.id;
						groupData.orgId = orgId;
						groupData.created = FirebaseManager.timestamp();
						groupData.createdBy = user.uid;
						setDoc(newRef, groupData)
							.then(() => {
								return resolve(groupData);
							})
							.catch((error) => {
								return reject(error.message);
							});
					});
				})
				.catch((errorMessage) => {
					return reject(errorMessage);
				});
		});
	}

	static addUserToGroup(uid, groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const groupUserRef = doc(
				firestore,
				`${this.getOrgGroupsRef(orgId).path}/${groupId}/groupUsers/${uid}`
			);
			setDoc(
				groupUserRef,
				{ isMember: true, isPending: deleteField() },
				{ merge: true }
			)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static removeUserFromGroup(uid, groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const groupUserRef = doc(
				firestore,
				`${this.getOrgGroupsRef(orgId).path}/${groupId}/groupUsers/${uid}`
			);
			deleteDoc(groupUserRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}

	static updateGroupImage(file, groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const storageRef = ref(
				storage,
				`${
					FirebaseManager.organizationRef().path
				}/${orgId}/groups/${groupId}/image:${groupId}.jpeg`
			);
			FirebaseManager.uploadImage(storageRef, file)
				.then((downloadURL) => {
					return this.updateGroupDB(
						{
							imageItem: [{ imageUrl: downloadURL }],
						},
						groupId
					)
						.then(() => {
							return resolve();
						})
						.catch(() => {
							return reject();
						});
				})
				.catch((errorMessage) => {
					return reject(errorMessage);
				});
		});
	}

	static updateGroupDB(data, groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const groupRef = doc(
				firestore,
				`${this.getOrgGroupsRef(orgId).path}/${groupId}`
			);
			var groupData = helpers.replaceAllNullValues(data, deleteField());
			setDoc(groupRef, groupData, { merge: true })
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject();
				});
		});
	}

	static deleteGroupItem(groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const storageRef = ref(
				storage,
				`${FirebaseManager.organizationRef().path}/${orgId}/groups/${groupId}`
			);

			FirebaseManager.deleteStorageRef(storageRef).finally(() => {
				return this.completeDelete(groupId)
					.then(() => {
						return resolve();
					})
					.catch((errorMessage) => {
						return reject(errorMessage);
					});
			});
		});
	}

	static completeDelete(groupId) {
		return new Promise((resolve, reject) => {
			const organization = store.state.organization;
			const orgId = organization.orgId;
			const groupRef = doc(
				firestore,
				`${FirebaseManager.organizationRef().path}/${orgId}/groups/${groupId}`
			);
			deleteDoc(groupRef)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	}
}
