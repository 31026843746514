<template>
	<div>
		<Modal :show="showModal" @close="closeModal" size="lg" hide-footer>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">Add Credit Card</h5>
			</template>
			<hr class="m-0 p-0" />
			<div>
				<h5
					class="py-2"
					v-if="errorMessage"
					style="color: red; flex: auto; text-align: center"
				>
					{{ errorMessage }}
				</h5>
			</div>

			<b-overlay :show="isLoading" rounded="sm">
				<b-row class="my-2">
					<b-col>
						<stripe-element-card
							v-if="publishableKey"
							alternative
							class="my-2"
							ref="stripeCardRef"
							:pk="publishableKey"
							:elementStyle="stripeInputStyle"
						/>
					</b-col>
				</b-row>
				<b-row class="mr-0 mt-3 mb-3">
					<div style="flex-grow: 1"></div>
					<BaseButton type="primary" :shake="shake" @click="generatePaymentMethod()"
						>Save Card</BaseButton
					>
				</b-row>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import Modal from '../../../components/Modals/Modal.vue';
import { StripeElementCard } from '@vue-stripe/vue-stripe';
import { MySundayInformation } from '../../../resources/MySundayInformation';
import BaseButton from '../../../components/BaseButton.vue';
import { mapActions } from 'vuex';

export default {
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	components: { Modal, StripeElementCard, BaseButton },
	data() {
		return {
			isLoading: false,
			shake: false,
			errorMessage: null,
			publishableKey: null,
			cardData: null,
			stripeInputStyle: {
				base: {
					color: '#525f7f',
					fontWeight: '400',
					fontFamily: 'Open Sans, sans-serif',
					fontSmoothing: 'antialiased',
					':-webkit-autofill': {
						color: '#525f7f',
					},
					'::placeholder': {
						color: '#8898aa',
					},
				},
			},
		};
	},
	methods: {
		...mapActions(['showNotification', 'forgotPassword']),
		generatePaymentMethod() {
			this.isLoading = true;
			const stripe = this.$refs.stripeCardRef.stripe;
			const card = this.$refs.stripeCardRef.element;
			stripe
				.createPaymentMethod({
					type: 'card',
					card: card,
				})
				.then((response) => {
					if (response.error) {
						this.isLoading = false;
						this.errorMessage = response.error.message;
					} else {
						const paymentMethod = response.paymentMethod;
						this.errorMessage = null;
						this.savePaymentMethod(paymentMethod.id);
					}
				});
		},
		savePaymentMethod(paymentMethodId) {
			this.isLoading = true;
			this.$emit('onPaymentId', paymentMethodId);
			this.closeModal();
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
	},
	mounted() {
		this.publishableKey = MySundayInformation.isTestMode
			? MySundayInformation.mysundayStripeTestPubKey
			: MySundayInformation.mysundayStripePubKey;
	},
};
</script>
