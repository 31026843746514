<template>
	<div v-if="groupData">
		<Card style="overflow: hidden">
			<CardHeader
				slot="header"
				title="Group Image"
				:end-button="{ title: 'Edit' }"
				@click="imageClicked"
			/>
			<VueAspectRatio ar="16:9">
				<div
					@mouseover="imageHover = true"
					@mouseleave="imageHover = false"
					class="b-img-helpers"
				>
					<div
						class="b-img-helpers card-img"
						:style="{ opacity: imageHover ? 0.4 : 1 }"
					>
						<b-img-lazy
							fluid-grow
							:src="groupData.imageItem[0].imageUrl"
							class="b-img-background-blur"
						/>
						<div class="b-img-overlay"></div>
						<b-img-lazy
							fluid-grow
							:src="groupData.imageItem[0].imageUrl"
							class="b-img-base"
						/>
					</div>
					<div
						v-if="imageHover"
						style="
							height: 100%;
							width: 100%;
							border: dashed 1px black;
							display: flex;
							flex-wrap: wrap;
							align-content: center;
						"
						class="justify-content-center card-img-top b-img-base"
					>
						<BaseButton squared type="medium" size="sm" block @click="imageClicked"
							>Edit</BaseButton
						>
					</div>
				</div>
			</VueAspectRatio>
		</Card>
		<ChangeImageForm
			:show-modal="showImageModal"
			@updatedImage="newImageFile"
			@close="showImageModal = false"
		/>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import BaseButton from '../../../components/BaseButton.vue';
import Card from '../../../components/Cards/Card.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import ChangeImageForm from '../BaseNewViews/ChangeImageForm.vue';
import NewFileModal from '../FileViews/NewFileModal.vue';

export default {
	components: {
		VueAspectRatio,
		BaseButton,
		Card,
		ChangeImageForm,
		CardHeader,
		NewFileModal,
		QuestionModal,
	},
	props: {
		groupData: {
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			imageHover: false,
			showImageModal: false,
		};
	},
	methods: {
		imageClicked() {
			this.showImageModal = !this.showImageModal;
		},
		newImageFile(file) {
			this.$emit('updateImage', file);
		},
	},
};
</script>
