import {
	sendPasswordResetEmail,
	signInWithEmailAndPassword,
	signOut,
} from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import AppUserManager from '../models/AppUser/AppUserManager';
import FirebaseManager from '../models/Firebase/FirebaseManager';
import { auth } from '../resources/firebase';

const actions = {
	getUser({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			AppUserManager.getUserByUid(data.uid)
				.then((userData) => {
					if (userData.adminRoles) {
						const usersAdmins = userData.adminRoles[0];
						dispatch('getOrganization', usersAdmins)
							.then((orgData) => {
								if (orgData) {
									commit('setUser', userData);
									commit('setOrganization', orgData);
									commit('setLastLoggedIn', new Date());
									return resolve();
								} else {
									return reject('no attached admin organization');
								}
							})
							.catch((error) => {
								return reject(error);
							});
					} else {
						return reject('no attached admin organization');
					}
				})
				.catch(() => {
					return reject('no user');
				});
		});
	},
	getOrganization({ commit }, data) {
		return new Promise((resolve, reject) => {
			if (data.orgId) {
				const orgRef = doc(FirebaseManager.organizationRef(), data.orgId);
				getDoc(orgRef).then((snapshot) => {
					if (snapshot.exists()) {
						const orgData = snapshot.data();
						if (orgData.isActive && orgData.billingType) {
							return resolve(snapshot.data());
						}
					}
					return resolve(null);
				});
			} else {
				return reject('No Organization');
			}
		});
	},
	showNotification({ commit }, data) {
		commit('setNotification', data);
	},
	removeNotification({ commit }) {
		commit('setNotification', null);
	},
	forgotPassword({ commit }, data) {
		return new Promise((resolve, reject) => {
			return sendPasswordResetEmail(auth, data.email)
				.then(() => {
					return resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	},
	login({ commit, dispatch }, data) {
		return new Promise((resolve, reject) => {
			signInWithEmailAndPassword(auth, data.email, data.password)
				.then((response) => {
					return dispatch('getUser', response.user)
						.then(() => {
							return resolve();
						})
						.catch(() => {
							dispatch('logOut');
							return reject();
						});
					//resolve(response);
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	},
	logOut({ commit }) {
		return new Promise((resolve, reject) => {
			signOut(auth)
				.then(() => {
					return resolve(commit('setUser', null));
					resolve();
				})
				.catch((error) => {
					return reject(error.message);
				});
		});
	},
};

export default actions;
