<template>
	<div>
		<Card no-body class="overflow-auto">
			<CardFooter v-if="footerTitle" slot="footer" :title="footerTitle" />
			<CardHeader v-if="headerTitle" slot="header" :title="headerTitle" />

			<BaseInput
				v-if="filterOption"
				id="filter-input"
				v-model="filter"
				type="search"
				class="m-2"
				placeholder="Type to Search"
				prependIcon="fa fa-search"
			></BaseInput>

			<b-table
				:fields="tableHeaders"
				:items="isFiltering ? filteredData : tableData"
				:busy="isLoading"
				:filter="filterOption && !algoliaSearch ? filter : null"
				small
				:fixed="isFixed"
				striped
				outlined
				hover
				show-empty
				:current-page="currentPage"
				:per-page="recordsPerPage"
				@row-clicked="rowClicked"
			>
				<template #empty="data">
					<div class="p-5 text-center text-muted">
						<b-icon icon="file-earmark-text" font-scale="2.5"></b-icon>
						<h5 class="mt-1 mb-0 text-muted">{{ emptyStateTitle }}</h5>
						<h6 class="text-muted">
							{{ emptyStateDescription }}
						</h6>
					</div>
				</template>
				<template #table-busy>
					<b-overlay :show="isLoading" rounded="sm" no-wrap />
				</template>
				<template #cell()="data">
					<div class="oneLine">
						{{ data.value }}
					</div>
				</template>
				<template #cell(nameAndPhoto)="data">
					<div class="oneLine" style="overflow: hidden">
						<b-avatar
							:badge-variant="
								data.value.isFlagged ? '' : data.value.isAdmin ? 'success' : ''
							"
							variant="dark"
							class="mr-2"
							:text="data.value.name"
							:src="data.value.photo"
						>
							<template #badge
								><Badge v-if="data.value.isFlagged" type="danger">BLOCKED</Badge
								><b-icon
									v-if="!data.value.isFlagged && data.value.isAdmin"
									icon="star-fill"
								></b-icon
							></template>
						</b-avatar>
						{{ data.value.name }}
					</div>
				</template>
				<template #cell(namePhotoRatio)="data">
					<div class="d-flex align-items-center justify-content-start oneLine">
						<VueAspectRatio
							class="d-none d-lg-block mr-2"
							:ar="data.value.ar"
							:style="{
								minWidth:
									data.value.size != null
										? data.value.size == 'sm'
											? '40px'
											: '80px'
										: '80px',
							}"
						>
							<Card no-body class="h-100">
								<b-img
									fluid-grow
									rounded
									:text="data.value.name"
									:src="data.value.photo"
									style="
										height: 100%;
										width: 100%;
										object-fit: cover;
										border: 1 solid gray;
									"
								/>
							</Card>
						</VueAspectRatio>
						<div
							style="
								overflow: hidden !important;
								overflow-wrap: normal;
								text-overflow: ellipsis;
								white-space: nowrap !important;
							"
						>
							{{ data.value.name }}
						</div>
					</div>
					<b-row style="background-color: blue">
						<b-col cols="auto" class="" style="background-color: orange"></b-col>
						<b-col cols="auto" class="" style="width: auto; background-color: yellow">
						</b-col>
					</b-row>
				</template>
				<template #cell(documentPhoto)="data">
					<div class="d-flex align-items-center oneLine">
						<h2 class="mb-0 mr-2">
							<i :class="returnDocumentIcon(data.value.type)"></i>
						</h2>
						{{ data.value.name }}
					</div>
				</template>
				<template #cell(badgeView)="data">
					<Badge :type="data.value.type">{{ data.value.name }}</Badge>
				</template>
				<template #cell(actions)="data">
					<b-row>
						<b-col>
							<b-dropdown
								id="actions"
								variant="null"
								dropleft
								no-caret
								class="m-0 p-0"
								v-if="data.value"
							>
								<template #button-content><i class="fas fa-ellipsis-v"></i> </template>
								<div v-for="item in data.value">
									<b-dropdown-item
										v-if="item.title"
										:variant="item.variant"
										@click="actionDropdownClicked({ item: item, row: data.item })"
										>{{ item.title }}</b-dropdown-item
									>
									<b-dropdown-divider v-if="item.isDivider"></b-dropdown-divider>
								</div>
							</b-dropdown>
						</b-col>
					</b-row>
				</template>
				<template #cell(pendingActions)="data">
					<b-row class="align-items-center justify-content-end">
						<b-col>
							<BaseButton
								block
								size="sm"
								type="success"
								@click="acceptClicked(data.item)"
								>Accept</BaseButton
							>
						</b-col>
						<b-col>
							<BaseButton
								block
								size="sm"
								outline
								type="danger"
								@click="declineClicked(data.item)"
								>Decline</BaseButton
							>
						</b-col>
					</b-row>
				</template>
				<template #cell(groupActions)="data"> </template>
				<template #cell(isActive)="data">
					<BaseSwitch :value="data.value" />
				</template>
				<template #cell(isPrivate)="data">
					<BaseSwitch :value="data.value" />
				</template>
			</b-table>
			<b-row class="mx-2 my-3 align-items-center">
				<b-col
					v-if="false"
					class="d-flex align-items-center justify-content-right text-right"
				>
					<h6 class="mb-0 mr-2 text-muted" style="width: 100%">Records Per Page</h6>
					<b-dropdown
						id="dropdown-records"
						:text="`${recordsPerPage}`"
						size="sm"
						variant="null"
						style="border: 1px solid lightgray; border-radius: 5px"
					>
						<b-dropdown-item
							v-for="record in recordsPerPageOptions"
							@click="recordsChanged(record)"
							>{{ record }}</b-dropdown-item
						>
					</b-dropdown>
				</b-col>

				<b-col v-if="enablePaging && total > recordsPerPage">
					<b-pagination
						class="m-0 p-0"
						v-model="currentPage"
						:total-rows="total"
						:per-page="recordsPerPage"
						hide-goto-end-buttons
						size="sm"
						:align="'left'"
					>
					</b-pagination>
				</b-col>
				<b-col class="justify-content-right text-right">
					<h6 class="m-0 text-muted">Total: {{ total }}</h6>
				</b-col>
			</b-row>
		</Card>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import algoliasearch from 'algoliasearch';
import Badge from '../Badge.vue';
import BaseButton from '../BaseButton.vue';
import BaseSwitch from '../BaseSwitch.vue';
import Card from '../Cards/Card.vue';
import CardFooter from '../Cards/CardFooter.vue';
import CardHeader from '../Cards/CardHeader.vue';
import BaseInput from '../Inputs/BaseInput.vue';
import helpers from '../../helpers/helpers';
import AppUserManager from '../../models/AppUser/AppUserManager';
import { mapGetters } from 'vuex';

export default {
	components: {
		Card,
		CardFooter,
		CardHeader,
		Badge,
		BaseInput,
		BaseButton,
		BaseSwitch,
		VueAspectRatio,
	},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
		sortOptions() {
			// Create an options list from our fields
			return this.fields
				.filter((f) => f.sortable)
				.map((f) => {
					return { text: f.label, value: f.key };
				});
		},
	},
	watch: {
		currentPage: {
			handler: function (value) {
				this.pageChanged(value);
			},
		},
		filter: {
			handler: function (value) {
				if (helpers.isBlank(value)) {
					this.isFiltering = false;
				} else if (this.algoliaSearch) {
					this.searchClient(value);
				}
			},
		},
	},
	props: {
		isLoading: false,
		isFixed: {
			type: Boolean,
			default: true,
		},
		enablePaging: {
			type: Boolean,
			default: true,
		},
		filterOption: {
			type: Boolean,
			default: false,
		},
		algoliaSearch: {
			type: Boolean,
			default: false,
		},
		isSelecting: false,
		headerTitle: {
			type: String,
			default: null,
		},
		footerTitle: {
			type: String,
			default: null,
		},
		emptyStateTitle: {
			type: String,
			default: 'No Data',
		},
		emptyStateDescription: {
			type: String,
			default: 'We have no data to show. Please try again or revise your search.',
		},
		headerButtonData: {
			type: Object,
			default: null,
		},
		tableHeaders: {
			type: Array,
			description: 'The fields or table headers',
		},
		tableData: {
			type: Array,
			description: 'Data for table',
		},
		recordsPerPage: {
			type: Number,
			default: 10,
		},
		total: {
			type: Number,
			description: 'Total Number of data for table',
		},
	},
	data() {
		return {
			filter: null,
			isFiltering: false,
			endReached: false,
			currentPage: 1,
			recordsPerPageOptions: [10, 25, 100],
			filteredData: {
				type: Array,
				default: null,
			},
		};
	},
	methods: {
		pageChanged(currentPage) {
			this.$emit('currentPage', currentPage);
		},
		recordsChanged(record) {
			this.recordsPerPage = record;

			console.log(
				`Records changed: ${this.currentPage} && size = ${this.recordsPerPage}`
			);
		},
		headerButtonClicked() {
			this.$emit('headerButtonClicked', this.selected);
		},
		searchClient(searchText) {
			this.isFiltering = true;
			this.isLoading = true;
			const users_index = algoliasearch(
				'7PSWWX6Z4I',
				'06e12506af222c0fc910b85d3ba54c48'
			).initIndex('users');
			users_index
				.search(searchText, { filters: `orgId:${this.organization.orgId}` })
				.then(({ hits }) => {
					this.isLoading = false;
					this.filteredData = hits.map((data) => {
						return AppUserManager.returnUserData(data);
					});
				})
				.catch((error) => {});
		},
		switchChanged(value, data) {
			this.$emit('onChanged', {
				value: value,
				data: data.item,
			});
		},
		attendanceQuantityChange(item) {
			this.$emit('attendanceQuantityChange', item);
		},
		selectedItem(event, userData) {
			const isChecked = event.target.checked;
			if (isChecked) {
				if (!this.selected.find((e) => e.uid == userData.uid)) {
					this.selected.push(userData);
				}
			} else {
				this.selected = this.selected.filter((e) => e.uid !== userData.uid);
			}
		},
		returnDocumentIcon(type) {
			if (
				type == 'application/msword' ||
				type ==
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
			) {
				return 'fa fa-file-word';
			} else if (
				type == 'application/vnd.ms-excel' ||
				type == 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
			) {
				return 'fa fa-file-excel';
			} else if (
				type == 'application/vnd.ms-powerpoint' ||
				type ==
					'application/vnd.openxmlformats-officedocument.presentationml.presentation'
			) {
				return 'fa fa-file-powerpoint';
			} else if (type == 'application/pdf') {
				return 'fa fa-file-pdf';
			} else {
				return 'fa fa-file';
			}
		},
		viewClicked(item) {
			this.$emit('viewClicked', item);
		},
		actionDropdownClicked(item) {
			this.$emit('actionDropdownClicked', item);
		},
		pendingClicked(item) {
			this.$emit('pendingClicked', item);
		},
		acceptClicked(item) {
			this.$emit('acceptClicked', item);
		},
		declineClicked(item) {
			this.$emit('declineClicked', item);
		},
		deleteClicked(item) {
			this.$emit('deleteClicked', item);
		},
		rowClicked(item) {
			this.$emit('viewClicked', item);
		},
	},
};
</script>
<style></style>
