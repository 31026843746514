<template>
	<footer class="footer px-4 mx-2">
		<b-row align-v="center" class="justify-content-center text-center">
			<b-col>
				<a :href="MySundayInformation.mysundayWebsite" target="_blank">
					<h5>
						© {{ new Date().getFullYear() }}
						{{ MySundayInformation.mysundayFullName }}
					</h5>
				</a>
			</b-col>
		</b-row>
		<b-row class="text-center">
			<b-col>
				<h6 class="text-light">Version {{ returnPackageVersion() }}</h6>
			</b-col>
		</b-row>
	</footer>
</template>
<script>
import { MySundayInformation } from '../../resources/MySundayInformation';
import pck from '../../../package.json'
import Logo from 'logo/AppLogoHorizontal.png';

export default {
	data() {
		return {
			MySundayInformation,
			Logo,
		};
	},
	methods: {
		returnPackageVersion(){
			return pck.version
		}
	}
};
</script>
