<template>
	<div>
		<DashboardHeader
			v-if="false"
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<b-container fluid>
			<b-row>
				<b-col style="max-width: 1000px">
					<NewPostCard @newPostClicked="newPostClicked" />
					<AllFeaturedCard
						:all-featured="allFeatured"
						@updateFeatured="getFeatured()"
						@newFeatured="onNewFeature"
					/>

					<Card no-body>
						<OrganizationHeader v-if="false" />
						<b-tabs
							fill
							class="m-2"
							active-nav-item-class="bg-white font-weight-bold description"
							nav-class="bg-white description"
						>
							<b-tab title="Community" active>
								<b-list-group class="py-2 p-0">
									<b-list-group-item
										class="m-0 p-0"
										v-for="postData in allPosts"
										:key="postData.postId"
									>
										<FeedItem :postData="postData" @deletePost="deletePost" />
									</b-list-group-item>
									<BaseButton
										v-if="canLoadAllPosts"
										class="my-2"
										type="primary"
										outline
										@click="getPosts()"
										>Load More</BaseButton
									>
								</b-list-group>
							</b-tab>
							<b-tab title="Events" v-if="false"></b-tab>
							<b-tab title="Audios" v-if="false"></b-tab>
							<b-tab title="Videos" v-if="false"></b-tab>
							<b-tab title="Groups" v-if="false"></b-tab>
							<b-tab title="Files" v-if="false"></b-tab>
						</b-tabs>
					</Card>
				</b-col>
				<b-col class="d-none d-md-inline" md="6">
					<b-row v-if="false">
						<b-col>
							<Card>
								<CardHeader
									slot="header"
									:title="'Trends'"
									:end-button="{ title: 'View All' }"
							/></Card>
						</b-col>
					</b-row>
					<b-row>
						<b-col>
							<InfoCard
								value-title="Members"
								icon="person-circle"
								color="primary"
								:footer-title="` Updated ${$helpers.timeSince(lastLoggedIn)}`"
								:value="organization.orgMembers"
							/>
						</b-col>
						<b-col>
							<InfoCard
								value-title="Followers"
								icon="person-circle"
								color="blue"
								:footer-title="` Updated ${$helpers.timeSince(lastLoggedIn)}`"
								:value="organization.orgFollowers"
							/>
						</b-col>
					</b-row>
					<b-row>
						<b-col v-if="organization.inviteCode">
							<InfoCard
								icon="upc-scan"
								variant="light"
								:isRounded="false"
								value-title="Invite Code"
								:footer-title="` Updated ${$helpers.timeSince(lastLoggedIn)}`"
								:value="organization.inviteCode"
							/>
						</b-col>
					</b-row>
					<b-row v-if="allPendingPosts.length > 0">
						<b-col>
							<Card no-body>
								<b-tabs
									fill
									class="m-2"
									active-nav-item-class="bg-white font-weight-bold description"
									nav-class="bg-white description"
								>
									<b-tab title="Pending Posts" active>
										<b-list-group class="py-2 p-0">
											<b-list-group-item
												class="m-0 p-0"
												v-for="postData in allPendingPosts"
												:key="postData.postId"
											>
												<FeedItem
													:postData="postData"
													@approvePost="approvePendingPost"
													@deletePost="deletePendingPost"
												/>
											</b-list-group-item>
										</b-list-group>
									</b-tab>
								</b-tabs>
							</Card>
						</b-col>
					</b-row>
					<b-row v-if="false">
						<b-col>
							<Card class="bg-gradient-primary">
								<CardHeader
									slot="header"
									:title="'Featured Sponsor'"
									:end-button="{ title: 'Learn More' }"
								/>
								<b-img
									class="card-img-top"
									src="https://static.wixstatic.com/media/88f5c1_5a0a69f5c7bc4416845d29c5b26ba012~mv2.png/v1/fill/w_580,h_391,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/img9web.png"
									style="background-color: transparent"
								/>
								<div class="px-2 py-3">
									<h4 class="text-center text-white">Need A Website Uplift?</h4>
									<p class="small text-center text-white">
										Now you can add your app information to your current website or a
										custom designed website!
									</p>
									<hr class="my-3 bg-white" />
									<BaseButton
										block
										type="dark"
										@click="openWebsite(MySundayInformation.mysundayWebsite)"
										>Learn More</BaseButton
									>
								</div>
							</Card>
						</b-col>
					</b-row>
				</b-col>
			</b-row>
		</b-container>
		<NewPostModal
			:showModal="showNewPostModal"
			@onNewPost="onNewPost"
			@close="showNewPostModal = false"
		></NewPostModal>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import BaseButton from '../../components/BaseButton.vue';
import Card from '../../components/Cards/Card.vue';
import CardHeader from '../../components/Cards/CardHeader.vue';
import StatsCard from '../../components/Cards/StatsCard.vue';
import DashboardHeader from '../../components/Dashboard/DashboardHeader.vue';
import Comment from '../../components/Feed/Comment.vue';
import FeedHeader from '../../components/Feed/FeedHeader.vue';
import FeedItem from '../../components/Feed/FeedItem.vue';
import NewPostCard from '../../components/Feed/NewPostCard.vue';
import OrganizationHeader from '../../components/Profile/OrganizationHeader.vue';
import PostItemManager from '../../models/PostItem/PostItemManager';
import { DashboardChildren } from '../../routes/routesNames';
import NewPostModal from '../SecondaryComponents/PostViews/NewPostModal.vue';
import FeaturedItemManager from '../../models/FeaturedItem/FeaturedItemManager';
import FeaturedCard from '../SecondaryComponents/FeaturedViews/FeaturedItem.vue';
import CardFooter from '../../components/Cards/CardFooter.vue';
import AllFeaturedCard from '../SecondaryComponents/FeaturedViews/AllFeaturedCard.vue';
import AllFeaturedButtonCard from '../SecondaryComponents/FeaturedViews/AllFeaturedButtonCard.vue';
import { mapGetters } from 'vuex';
import InfoCard from '../SecondaryComponents/OrgViews/InfoCard.vue';

export default {
	computed: {
		...mapGetters({
			organization: 'getOrganization',
			lastLoggedIn: 'getLastLoggedIn',
		}),
	},
	components: {
		DashboardHeader,
		VueAspectRatio,
		Card,
		Comment,
		FeedHeader,
		FeedItem,
		NewPostCard,
		OrganizationHeader,
		BaseButton,
		CardHeader,
		StatsCard,
		NewPostModal,
		FeaturedCard,
		CardFooter,
		AllFeaturedCard,
		AllFeaturedButtonCard,
		InfoCard,
	},
	data() {
		return {
			showNewPostModal: false,
			canLoadAllPosts: true,
			buttonItems: [],
			allFeatured: [],
			allPosts: [],
			lastPostFetched: null,
			allPendingPosts: [],
		};
	},
	methods: {
		setupView() {
			this.buttonItems = [
				{
					index: 0,
					title: 'New Post',
					type: 'primary',
					icon: 'fas fa-plus',
				},
			];
		},
		getPosts() {
			//this.allPosts = [];
			PostItemManager.getOrgPosts(null, false, this.lastPostFetched).then(
				(items) => {
					this.allPosts.push.apply(this.allPosts, items.items);
					this.lastPostFetched = items.lastCursor;
					this.canLoadAllPosts =
						items.items.length >= PostItemManager.itemFetchCount &&
						items.lastCursor != null;
				}
			);
		},
		getPendingPosts() {
			PostItemManager.getOrgPendingPosts(null, null).then((items) => {
				this.allPendingPosts = items.items;
			});
		},
		getFeatured() {
			this.allFeatured = [];
			FeaturedItemManager.getOrgFeatured().then((items) => {
				this.allFeatured = items;
			});
		},
		loadMore() {},
		newPostClicked() {
			this.showNewPostModal = !this.showNewPostModal;
		},
		onNewPost() {
			this.allPosts = [];
			this.getPosts();
		},
		approvePendingPost() {
			this.allPendingPosts = [];
			this.allPosts = [];
			this.getPendingPosts();
			this.getPosts();
		},
		deletePost() {
			this.allPosts = [];
			this.getPosts();
		},
		deletePendingPost() {
			this.allPendingPosts = [];
			this.getPendingPosts();
		},
		onNewFeature(featuredData) {
			this.getFeatured();
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
			}
		},
	},
	mounted() {
		this.setupView();
		this.getFeatured();
		this.getPosts();
		this.getPendingPosts();
	},
};
</script>
