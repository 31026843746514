<template>
	<b-overlay :show="isOverlayLoading" rounded="sm">
		<slot></slot>
		<template #overlay v-if="progress">
			<b-container style="width: 200px">
				<div class="text-center">
					<h4 class="mb-1 text">Loading...</h4>
					<b-progress :max="100" animated height="1rem">
						<b-progress-bar
							:value="loadingProgress"
							:label="`${loadingProgress}%`"
						></b-progress-bar>
					</b-progress>
				</div>
			</b-container>
		</template>
	</b-overlay>
</template>
<script>
export default {
	props: {
		isLoading: Boolean,
		progress: {
			type: Number,
			default: null,
		},
	},
	watch: {
		isLoading: {
			handler(val) {
				return (this.isOverlayLoading = val);
			},
		},
		progress: {
			handler(val) {
				return (this.loadingProgress = val);
			},
		},
	},
	data() {
		return {
			isOverlayLoading: false,
			loadingProgress: 0,
		};
	},
};
</script>
