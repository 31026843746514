<template>
	<SideBar :logo="organization.orgLogo" :title="organization.orgName">
		<div v-if="false" class="nav-button mt-0 pt-0">
			<BaseButton type="primary" size="sm" icon="fas fa-plus" block
				>New Post</BaseButton
			>
		</div>
		<div slot="links">
			<div
				v-for="menuItem in menuItems"
				v-if="!menuItem.isComingSoon && !menuItem.isAdditional"
			>
				<sidebar-item :link="menuItem">
					<div v-if="menuItem.children" v-for="children in menuItem.children">
						<sidebar-item :link="children" />
					</div>
				</sidebar-item>
			</div>
		</div>

		<div
			slot="links-after"
			v-if="menuItems.filter((e) => e.isAdditional).length > 0"
		>
			<hr class="my-3 bg-light" />
			<h6 class="navbar-heading p-0 text-muted">Additional Resources</h6>
			<b-nav class="navbar-nav mb-md-3">
				<div v-for="menuItem in menuItems" v-if="menuItem.isAdditional">
					<sidebar-item :link="menuItem"> </sidebar-item>
				</div>
			</b-nav>
		</div>

		<div
			slot="links-after"
			v-if="menuItems.filter((e) => e.isComingSoon).length > 0"
		>
			<hr class="my-3 bg-light" />
			<h6 class="navbar-heading p-0 text-muted">Coming Soon</h6>
			<b-nav class="navbar-nav mb-md-3">
				<div v-for="menuItem in menuItems" v-if="menuItem.isComingSoon">
					<b-nav-item href="">
						<i :class="menuItem.icon"></i>
						<b-nav-text class="p-0 mr-1">{{ menuItem.name }}</b-nav-text>
					</b-nav-item>
				</div>
			</b-nav>
		</div>

		<div slot="links-after">
			<hr class="my-3 bg-light" />
			<h6 class="navbar-heading p-0 text-muted">Support Center</h6>

			<b-nav class="navbar-nav mb-md-3">
				<b-nav-item :href="MySundayInformation.mysundaySupport" target="_blank">
					<i class="fas fa-question-circle"></i>
					<b-nav-text class="p-0">Help Center</b-nav-text>
				</b-nav-item>
				<b-nav-item
					:href="MySundayInformation.mysundaySupportTicket"
					target="_blank"
				>
					<i class="fas fa-info-circle"></i>
					<b-nav-text class="p-0">Submit A Ticket</b-nav-text>
				</b-nav-item>
				<b-nav-item>
					<i class="fas fa-clock"></i>
					<b-nav-text class="p-0">9am - 4:30pm EST</b-nav-text>
				</b-nav-item>
			</b-nav>
		</div>
		<div slot="footer">
			<hr class="my-3 bg-light" />
			<b-navbar-brand
				class="my-4 p-0 text-center"
				:href="MySundayInformation.mysundayWebsite"
				target="_blank"
			>
				<b-img
					style="filter: grayscale(100%)"
					fluid
					src="/img/AppLogoHorizontalWTEXT_Shadow.png"
				/>
			</b-navbar-brand>
		</div>
		<div v-if="false" slot="footer">
			<Card no-body class="d-flex align-items-center">
				<b-img
					class="card-img-top"
					src="https://static.wixstatic.com/media/88f5c1_5a0a69f5c7bc4416845d29c5b26ba012~mv2.png/v1/fill/w_580,h_391,al_c,q_85,usm_0.66_1.00_0.01,enc_auto/img9web.png"
					style="background-color: #f9f9f9"
				/>
				<div class="px-2 py-3">
					<h4 class="text-center">Need A Website Uplift?</h4>
					<p class="small text-center">
						Now you can add your app information to your current website or a custom
						designed website!
					</p>
					<hr class="my-3" />
					<BaseButton
						block
						type="info"
						@click="openWebsite(MySundayInformation.mysundayWebsite)"
						>Learn More</BaseButton
					>
				</div>
			</Card>
		</div>
	</SideBar>
</template>
<script>
import { mapGetters } from 'vuex';
import { DashboardChildren } from '../../routes/routesNames';
import { MySundayInformation } from '../../resources/MySundayInformation';
import SideBar from '../../components/SidebarPlugin/SideBar.vue';
import Card from '../../components/Cards/Card.vue';
import BaseButton from '../../components/BaseButton.vue';

export default {
	name: 'sideMenu',
	computed: {
		...mapGetters({
			organization: 'getOrganization',
		}),
	},
	data() {
		return {
			MySundayInformation,
			menuItems: [],
		};
	},
	methods: {
		setupSideMenu() {
			Object.values(DashboardChildren).forEach((entry) => {
				if (entry.icon && !entry.isHidden) {
					this.menuItems.push(entry);
				}
			});
		},
		openWebsite(url) {
			this.$helpers.openUrl(url);
		},
	},
	mounted() {
		this.setupSideMenu();
	},
	components: { SideBar, Card, BaseButton },
};
</script>
