<template>
	<div>
		<Modal :show="showModal" @close="closeModal" size="xl" hide-footer>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">New Giving Option</h5>
			</template>
			<hr class="m-0 p-0" />
			<b-overlay :show="isLoading" rounded="sm">
				<div>
					<h5
						class="py-2"
						v-if="errorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ errorMessage }}
					</h5>
				</div>
				<b-row class="my-2">
					<b-col>
						<div>
							<label class="form-control-label m-0 p-0">Giving Image</label>
							<h6 class="m-0 text-muted font-weight-normal">
								Upload a a picture that shows a representation of this giving option. A
								good thumbnail stands out and draws viewers' attention to the giving
								link.
							</h6>
							<div class="my-3">
								<BaseDropbox ar="3:1" @onFile="onFileChange" ifImageFill> </BaseDropbox>
							</div>
						</div>
						<BaseInput
							label="Giving Title"
							id="Giving Title"
							name="Giving Title"
							placeholder="Enter Giving Title"
							required
							type="text"
							v-model="givingData.title"
						></BaseInput>

						<BaseInput
							label="Giving Description"
							id="Giving Description"
							name="Giving Description"
							placeholder="Enter your description..."
							type="textarea"
							required
							rows="6"
							:max-length="350"
							v-model="givingData.description"
						/>

						<b-row>
							<b-col>
								<BaseInput
									label="Giving Button Name (optional)"
									id="Giving Button Name"
									name="Giving Button Name"
									placeholder="Enter Button Name"
									type="text"
									v-model="givingData.buttonTitle"
								/>
							</b-col>
							<b-col>
								<BaseInput
									label="Giving Button Link (optional)"
									id="Giving Button Link"
									name="Giving Button Link"
									placeholder="Enter Button Link"
									type="url"
									v-model="givingData.link"
								/>
							</b-col>
						</b-row>

						<BaseInput
							label="Giving Option Placement"
							id="Giving Option Placement"
							name="Giving Option Placement"
							placeholder="Enter Option Placement"
							type="number"
							:min-length="1"
							:max-length="5"
							v-model="givingData.index"
						/>
					</b-col>
				</b-row>
				<b-row class="mr-0 mt-3 mb-3">
					<div style="flex-grow: 1"></div>
					<BaseButton type="primary" :shake="shake" @click="submit"
						>Add Giving Option</BaseButton
					>
				</b-row>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Modal from '../../../components/Modals/Modal.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import GivingCard from './GivingCard.vue';
import BaseDropbox from '../../../components/Inputs/BaseDropbox.vue';
import BaseButton from '../../../components/BaseButton.vue';
import helpers from '../../../helpers/helpers';

export default {
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			errorMessage: null,
			givingThumbnail: null,
			givingData: {
				title: null,
				description: null,
				link: null,
				index: 1,
				buttonTitle: null,
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
		onFileChange(file) {
			this.givingThumbnail = file;
		},
		submit() {
			this.shake = false;
			if (this.givingThumbnail == null) {
				this.errorMessage = 'Please select a giving thumbnail.';
				this.shake = true;
				return;
			} else if (helpers.isBlank(this.givingData.title)) {
				this.errorMessage = 'Please enter a giving title.';
				this.shake = true;
				return;
			} else if (helpers.isBlank(this.givingData.description)) {
				this.errorMessage = 'Please enter a giving description.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;
				this.addGivingOption();
			}
		},
		addGivingOption() {
			this.$emit('uploadData', {
				givingThumbnail: this.givingThumbnail,
				givingData: this.givingData,
			});
			this.closeModal();
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
			//this.resetView();
		},
	},
	components: { Modal, BaseInput, GivingCard, BaseDropbox, BaseButton },
};
</script>
