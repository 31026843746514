import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getFunctions } from 'firebase/functions';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
	apiKey: 'AIzaSyDflAWR0YKvUAKlVDQfzV1LerTYgIfY-SI',
	authDomain: 'mysunday-7eab9.firebaseapp.com',
	projectId: 'mysunday-7eab9',
	storageBucket: 'mysunday-7eab9.appspot.com',
	messagingSenderId: '1091676479688',
	appId: '1:1091676479688:web:19d270fc13343f9a1845fd',
	measurementId: 'G-28GLJC8WBH',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Authentication
const auth = getAuth(app);

// Firestore
const firestore = getFirestore(app);

//Storage
const storage = getStorage(app);

// Functions
const functions = getFunctions(app);

export { firebaseConfig, auth, firestore, storage, functions };
