export const PricingInformation = [
	{
		billingType: 'basic',
		scheduleNotifications: false,
		giving: {
			count: 1,
		},
		events: {
			count: 5,
		},
		groups: {
			count: 5,
			maxUsers: 50,
		},
		videos: {
			count: 10,
			maxLength: 30,
		},
		calls: {
			count: 1,
			maxUsers: 100,
		},
	},
	{
		billingType: 'pro',
		scheduleNotifications: true,
		giving: {
			count: 3,
		},
		events: {
			count: null,
		},
		groups: {
			count: 10,
		},
		videos: {
			count: 10,
			maxLength: 120,
		},
		audios: {
			count: null,
		},
		files: {
			count: null,
		},
		calls: {
			count: 1,
			maxUsers: 100,
		},
	},
	{
		billingType: 'premium',
		scheduleNotifications: true,
		giving: {
			count: 10,
		},
		events: {
			count: null,
		},
		groups: {
			count: null,
		},
		videos: {
			count: null,
			maxLength: 120,
		},
		audios: {
			count: null,
		},
		files: {
			count: null,
		},
		checkins: {
			count: null,
		},
		forms: {
			count: null,
		},
		notes: {
			count: null,
		},
		calls: {
			count: 1,
			maxUsers: null,
		},
	},
];
