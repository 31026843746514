<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<BaseContent>
			<EventTable
				:headerTitle="'Upcoming Events'"
				:isLoading="isUpcomingLoading"
				:eventData="upcomingEvents"
				@currentPage="currentUpcomingPage"
				@viewClicked="viewClicked"
				@cancelClicked="cancelClicked"
			/>
			<EventTable
				style="opacity: 0.5"
				:headerTitle="'Previous Events'"
				:isLoading="isPreviousLoading"
				:eventData="previousEvents"
				@currentPage="currentPreviousPage"
				@viewClicked="viewClicked"
				@cancelClicked="cancelClicked"
			/>
		</BaseContent>
		<QuestionModal
			:question="showQuestion"
			:show="showQuestionModal"
			@yesClicked="cancelEvent"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import EventTable from '../../SecondaryComponents/EventViews/EventTable.vue';
import BaseContent from '../../../components/BaseContent.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import EventItemManager from '../../../models/EventItem/EventItemManager';
import { DashboardChildren } from '../../../routes/routesNames';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';

export default {
	components: { DashboardHeader, BaseContent, EventTable, QuestionModal },
	data() {
		return {
			isUpcomingLoading: false,
			isPreviousLoading: false,
			upcomingEvents: [],
			previousEvents: [],
			lastFetched: null,
			showQuestionModal: false,
			showQuestion: null,
			showEventData: null,
			buttonItems: [
				{
					index: 0,
					title: 'New Event',
					icon: 'fa fa-plus',
					type: 'primary',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getUpcomingEvents(maxCount) {
			this.isUpcomingLoading = true;
			EventItemManager.getOrgEvents(this.lastFetched, maxCount, false).then(
				(items) => {
					this.upcomingEvents.push.apply(this.upcomingEvents, items.items);
					this.lastFetched = items.lastCursor;
					this.isUpcomingLoading = false;
				}
			);
		},
		getPreviousEvents(maxCount) {
			this.isPreviousLoading = true;
			EventItemManager.getOrgEvents(this.lastFetched, maxCount, true).then(
				(items) => {
					this.previousEvents.push.apply(this.previousEvents, items.items);
					this.lastFetched = items.lastCursor;
					this.isPreviousLoading = false;
				}
			);
		},
		currentUpcomingPage(index) {
			const minCount = EventItemManager.limitFetch * (index - 1);
			const maxCount = EventItemManager.limitFetch * index;
			const inBetween = maxCount - this.upcomingEvents.length;
			if (minCount >= this.upcomingEvents.length && this.lastFetched != null) {
				const value =
					inBetween > EventItemManager.limitFetch
						? inBetween
						: EventItemManager.limitFetch;
				this.getUpcomingEvents(value);
			}
		},
		currentPreviousPage(index) {
			const minCount = EventItemManager.limitFetch * (index - 1);
			const maxCount = EventItemManager.limitFetch * index;
			const inBetween = maxCount - this.previousEvents.length;
			if (minCount >= this.previousEvents.length && this.lastFetched != null) {
				const value =
					inBetween > EventItemManager.limitFetch
						? inBetween
						: EventItemManager.limitFetch;
				this.getPreviousEvents(value);
			}
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.openNewEvent();
			}
		},
		openNewEvent() {
			this.$router.push({
				name: DashboardChildren.newEventView.name,
			});
		},
		cancelEvent() {
			const isCancelled =
				this.showEventData.isCancelled != null
					? this.showEventData.isCancelled
					: false;
			EventItemManager.updateEventDB(
				{
					isCancelled: !isCancelled,
				},
				this.showEventData.eventId
			).then(() => {
				this.upcomingEvents = this.upcomingEvents.map((e) =>
					e.eventId === this.showEventData.eventId
						? { ...e, isCancelled: !isCancelled }
						: e
				);
				this.previousEvents = this.previousEvents.map((e) =>
					e.eventId === this.showEventData.eventId
						? { ...e, isCancelled: !isCancelled }
						: e
				);
			});
		},
		cancelClicked(eventData) {
			const isCancelled =
				eventData.isCancelled != null ? eventData.isCancelled : false;
			this.showQuestion = `Are you sure you want to ${
				isCancelled ? 're-open' : 'cancel'
			} this event?`;
			this.showEventData = eventData;
			this.showQuestionModal = true;
		},
		viewClicked(eventData) {
			this.$router.push({
				name: DashboardChildren.eventView.name,
				params: {
					eventId: eventData.eventId,
					eventData: eventData,
				},
			});
		},
	},
	mounted() {
		this.getUpcomingEvents(null);
		this.getPreviousEvents(null);
	},
};
</script>
