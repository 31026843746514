<template>
	<div>
		<Modal :show="showModal" @close="closeModal" size="xl" hide-footer>
			<template v-slot:header>
				<h5 class="modal-title" id="modalTitle">New Resource</h5>
			</template>
			<hr class="m-0 p-0" />
			<b-overlay :show="isLoading" rounded="sm">
				<div>
					<h5
						class="py-2"
						v-if="errorMessage"
						style="color: red; flex: auto; text-align: center"
					>
						{{ errorMessage }}
					</h5>
				</div>

				<div class="py-2">
					<BaseInput
						label="Resource Title"
						id="Resource Title"
						name="Resource Title"
						placeholder="Enter Resource Title"
						required
						:max-length="10"
						type="text"
						v-model="resourceData.title"
					></BaseInput>
					<b-row class="align-items-center">
						<b-col>
							<BaseInput
								label="Resource Link"
								id="Resource Link"
								name="Resource Link"
								placeholder="https://www.example.com"
								pattern="https://.*"
								required
								type="url"
								v-model="resourceData.link"
							></BaseInput>
						</b-col>
						<b-col cols="auto" v-if="resourceData.link">
							<a
								target="_blank"
								rel="noopener noreferrer"
								:href="resourceData.link"
								class="base-button"
								><u><h5 class="mb-0 text-muted">Test Link</h5></u></a
							>
						</b-col>
					</b-row>

					<div>
						<label class="form-control-label">Resource Icon</label>
						<b-row>
							<b-col>
								<BaseButton
									id="selectIconButton"
									@click="showIconPicker = !showIconPicker"
									>{{ resourceData.icon == null ? 'Select Icon' : ''
									}}<i v-if="resourceData.icon" :class="`fa ${resourceData.icon}`"></i
								></BaseButton>
							</b-col>
						</b-row>
					</div>

					<b-popover
						target="selectIconButton"
						triggers="click"
						:show.sync="showIconPicker"
						placement="auto"
						ref="popover"
					>
						<IconPicker @selectIcon="onSelectedIcon" />
					</b-popover>

					<b-row class="mr-0 mt-3 mb-3">
						<div style="flex-grow: 1"></div>
						<BaseButton type="primary" :shake="shake" @click="submit"
							>Add Resource</BaseButton
						>
					</b-row>
				</div>
			</b-overlay>
		</Modal>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import Modal from '../../../components/Modals/Modal.vue';
import helpers from '../../../helpers/helpers';
import AppOrganizationManager from '../../../models/AppOrganization/AppOrganizationManager';
import BaseButton from '../../../components/BaseButton.vue';
import BaseInput from '../../../components/Inputs/BaseInput.vue';
import IconPicker from '../../../components/Picker/IconPicker.vue';

export default {
	props: {
		showModal: {
			type: Boolean,
			default: false,
		},
		reset: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			shake: false,
			isLoading: false,
			errorMessage: null,
			makeDefault: false,
			showIconPicker: false,
			resourceData: {
				title: null,
				icon: null,
				link: null,
			},
		};
	},
	watch: {
		reset(val) {
			if (val) {
				this.resetView();
			}
			return;
		},
	},
	components: { Modal, BaseButton, BaseInput, IconPicker },
	methods: {
		...mapActions(['showNotification']),
		onResourceData(data) {
			this.resourceData = data;
		},
		onSelectedIcon(icon) {
			this.showIconPicker = false;
			this.resourceData.icon = `fa-${icon.name}`;
		},
		submit() {
			this.shake = false;
			if (helpers.isBlank(this.resourceData.title)) {
				this.errorMessage = 'Please enter a resource title.';
				this.shake = true;
				return;
			} else if (helpers.isBlank(this.resourceData.link)) {
				this.errorMessage = 'Please enter a resource link.';
				this.shake = true;
				return;
			} else if (helpers.isBlank(this.resourceData.icon)) {
				this.errorMessage = 'Please enter a resource icon.';
				this.shake = true;
				return;
			} else {
				this.errorMessage = null;
				this.addResource();
			}
		},
		addResource() {
			this.isLoading = true;
			AppOrganizationManager.addOrganizationFeaturedButton(this.resourceData)
				.then((data) => {
					this.$emit('onNewResource', data);
					this.isLoading = false;
					this.closeModal();
				})
				.catch((errorMessage) => {
					this.isLoading = false;
					this.showNotification({
						type: 'danger',
						message: errorMessage,
					});
				});
		},
		closeModal() {
			this.$emit('update:show', false);
			this.$emit('close');
		},
		resetView() {
			this.resourceData.title = null;
			this.resourceData.icon = null;
			this.resourceData.link = null;
		},
	},
};
</script>
