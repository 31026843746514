<template>
	<div>
		<DashboardHeader
			:title="$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<BaseContent>
			<LoadingProgress :is-loading="isLoading" :progress="isLoadingProgress">
				<FileTable
					:isLoading="isLoading"
					:filesData="files"
					@currentPage="currentPage"
					@viewClicked="viewClicked"
					@deleteClicked="askDeleteQuestion"
				/>
			</LoadingProgress>
		</BaseContent>
		<NewFileModal
			:show-modal="showModal"
			@close="showModal = false"
			@uploadData="uploadData"
		/>
		<QuestionModal
			:show="showQuestionModal"
			:question="showQuestion"
			@yesClicked="deleteFile"
			@close="showQuestionModal = false"
		/>
	</div>
</template>
<script>
import { mapActions } from 'vuex';
import FileTable from '../../SecondaryComponents/FileViews/FileTable.vue';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import BaseContent from '../../../components/BaseContent.vue';
import FileItemManager from '../../../models/FileItem/FileItemManager';
import NewFileModal from '../../SecondaryComponents/FileViews/NewFileModal.vue';
import QuestionModal from '../../../components/Modals/QuestionModal.vue';
import LoadingProgress from '../../../components/LoadingProgress.vue';

export default {
	components: {
		DashboardHeader,
		BaseContent,
		FileTable,
		NewFileModal,
		QuestionModal,
		LoadingProgress,
	},
	data() {
		return {
			isLoading: false,
			isLoadingProgress: 0,
			showModal: false,
			showQuestionModal: false,
			showQuestion: null,
			selectedFile: null,
			files: [],
			lastFetched: null,
			buttonItems: [
				{
					index: 0,
					title: 'New File',
					icon: 'fa fa-plus',
					type: 'primary',
				},
			],
		};
	},
	methods: {
		...mapActions(['showNotification']),
		getFiles(maxCount) {
			this.isLoading = true;
			FileItemManager.getOrgFiles(this.lastFetched, maxCount).then((items) => {
				this.files.push.apply(this.files, items.items);
				this.lastFetched = items.lastCursor;
				this.isLoading = false;
			});
		},
		currentPage(index) {
			const minCount = FileItemManager.limitFetch * (index - 1);
			const maxCount = FileItemManager.limitFetch * index;
			const inBetween = maxCount - this.files.length;
			if (minCount >= this.files.length && this.lastFetched != null) {
				const value =
					inBetween > FileItemManager.limitFetch
						? inBetween
						: FileItemManager.limitFetch;
				this.getFiles(value);
			}
		},
		checkIfCanUpload() {
			FileItemManager.canUpload().then((canUpload) => {
				if (canUpload) {
					this.showModal = true;
				} else {
					this.showError('You have reached your limit on your current plan.');
				}
			});
		},
		uploadData(data) {
			const fileItem = data.fileItem;
			const fileData = data.fileData;
			this.isLoading = true;
			FileItemManager.addFileToDB(
				fileData,
				fileItem,
				fileData.fileTitle,
				fileData.fileContentType,
				null,
				(progress) => {
					this.isLoadingProgress = progress;
				}
			)
				.then((fileData) => {
					this.isLoading = false;
					this.files.push(fileData);
					//this.getFiles(null);
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		deleteFile() {
			this.isLoading = true;
			FileItemManager.deleteFileItem(this.selectedFile.fileId)
				.then(() => {
					this.files = this.files.filter(
						(e) => e.fileId !== this.selectedFile.fileId
					);
					this.isLoading = false;
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		askDeleteQuestion(fileItem) {
			this.selectedFile = fileItem;
			this.showQuestion = 'Are you sure you want to delete this file?';
			this.showQuestionModal = true;
		},
		showError(message) {
			this.showNotification({
				type: 'danger',
				message: message,
			});
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.checkIfCanUpload();
			}
		},
		viewClicked(fileData) {
			this.$helpers.openUrl(fileData.fileLink);
		},
	},
	mounted() {
		this.getFiles(null);
	},
};
</script>
