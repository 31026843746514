<template>
	<div>
		<DashboardHeader
			:title="this.$route.name"
			:buttonItems="buttonItems"
			@click="handleHeaderClick"
		/>
		<BaseContent>
			<b-overlay :show="isLoading" rounded="sm">
				<Card no-body>
					<b-tabs
						active-nav-item-class="bg-white font-weight-bold description"
						nav-class="bg-white description"
						card
						no-body
					>
						<b-tab title="Group Details" active>
							<TitleDescriptionView
								:header-label="'General Information'"
								:title-label="'Group Title'"
								imageLabel="Group Image"
								:description-label="'Group Description'"
								@onChange="onTitleDescriptionChange"
							/>
							<LocationView @onChange="onLocationChange" />
						</b-tab>
						<b-tab title="Registration">
							<RegistrationAttendanceView
								:titleLabel="'Group Members'"
								:titleLabelTooltip="'The maximum amount of users that can join this group.'"
								:maxUsersAllowed="returnMaxUsers()"
								@onChange="onAttendanceChange"
							/>
							<RegistrationButtonView
								:defaultButtonLabel="'Custom Button'"
								:defaultButtonFooterLabel="'Add a custom button to the group for all users.'"
								@onChange="onRegistrationChange"
							/>
						</b-tab>
						<b-tab title="Additional Settings">
							<AgeLimitView @onChange="onAgeChange" />
							<PrivacyView
								:titleLabel="'Private Group'"
								titleLabelTooltip="Decide if users can auto join this group or request access to join."
								@onChange="onPrivacyChange"
							/>
							<PrivacyView
								:titleLabel="'Member Group'"
								titleLabelTooltip="Decide if this item can been seen by users outside of the MySunday."
								@onUpdate="onMemberPrivacyChange"
							/>
						</b-tab>
					</b-tabs>
				</Card>
			</b-overlay>
		</BaseContent>
	</div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardHeader from '../../../components/Dashboard/DashboardHeader.vue';
import GroupItemManager from '../../../models/GroupItem/GroupItemManager';
import { DashboardChildren } from '../../../routes/routesNames';
import BaseContent from '../../../components/BaseContent.vue';
import Card from '../../../components/Cards/Card.vue';
import TitleDescriptionView from '../../SecondaryComponents/BaseNewViews/TitleDescriptionView.vue';
import LocationView from '../../SecondaryComponents/BaseNewViews/LocationView.vue';
import RegistrationAttendanceView from '../../SecondaryComponents/BaseNewViews/RegistrationAttendanceView.vue';
import RegistrationButtonView from '../../SecondaryComponents/BaseNewViews/RegistrationButtonView.vue';
import AgeLimitView from '../../SecondaryComponents/BaseNewViews/AgeLimitView.vue';
import PrivacyView from '../../SecondaryComponents/BaseNewViews/PrivacyView.vue';
import { PricingInformation } from '../../../resources/PricingInformation';

export default {
	components: {
		DashboardHeader,
		BaseContent,
		Card,
		TitleDescriptionView,
		LocationView,
		RegistrationAttendanceView,
		RegistrationButtonView,
		AgeLimitView,
		PrivacyView,
	},
	computed: {
		...mapGetters({
			organization: 'getOrganization',
			appInformation: 'getAppInformation',
		}),
	},
	data() {
		return {
			isLoading: false,
			buttonItems: [
				{
					index: 0,
					title: 'Save & Publish',
					type: 'primary',
				},
			],
			selectedImage: null,
			isAgeLimit: false,
			isCustomDefaultButton: false,
			groupData: {
				groupTitle: null,
				groupDescription: null,
				groupStart: null,
				groupEnd: null,
				groupDuration: null,
				groupMaxUsers: null,
				groupButtonTitle: null,
				groupButtonLink: null,
				groupCustomButtonTitle: null,
				groupCustomButtonLink: null,
				location: {
					title: null,
					description: null,
				},
				price: null,
				ageItem: {
					startAge: 0,
					endAge: null,
					enforced: false,
				},
				isPrivate: false,
				isMembersOnly: false,
			},
		};
	},
	methods: {
		...mapActions(['showNotification']),
		returnMaxUsers() {
			const planInfo = PricingInformation.find(
				(e) => e.billingType == this.organization.billingType
			);
			const maxAllowed = planInfo.groups.maxUsers
				? planInfo.groups.maxUsers
				: null;
			return maxAllowed;
		},
		onTitleDescriptionChange(updatedData) {
			const title = updatedData.title;
			const description = updatedData.description;
			const image = updatedData.image;
			this.selectedImage = image;
			this.groupData.groupTitle = title;
			this.groupData.groupDescription = description;
		},
		onLocationChange(updatedData) {
			const title = updatedData.title;
			const description = updatedData.description;
			this.groupData.location.title = title;
			this.groupData.location.description = description;
		},
		onAttendanceChange(updatedData) {
			var users = updatedData.maxUsers;
			if (users && this.returnMaxUsers()) {
				if (users > this.returnMaxUsers()) {
					users = this.returnMaxUsers();
				}
			}
			const maxUsers = users != null ? parseInt(users) : null;
			this.groupData.groupMaxUsers = maxUsers;
		},
		onRegistrationChange(updatedData) {
			const isCustomDefaultButton = updatedData.isCustomDefaultButton;
			const defaultButtonTitle = updatedData.defaultButtonTitle;
			const defaultButtonLink = updatedData.defaultButtonLink;
			if (isCustomDefaultButton) {
				this.groupData.groupCustomButtonLink = defaultButtonLink;
				this.groupData.groupCustomButtonTitle = defaultButtonTitle;
			} else {
				this.groupData.groupCustomButtonLink = null;
				this.groupData.groupCustomButtonTitle = null;
			}
			this.isCustomDefaultButton = isCustomDefaultButton;
		},
		onAgeChange(updatedData) {
			const isAgeLimit = updatedData.isAgeLimit;
			const startAge =
				updatedData.startAge != null ? parseInt(updatedData.startAge) : null;
			const endAge =
				updatedData.endAge != null ? parseInt(updatedData.endAge) : null;
			const ageEnforced = updatedData.ageEnforced;
			if (isAgeLimit) {
				if (startAge == 0 && endAge == null) {
					this.groupData.ageItem = null;
				} else {
					this.groupData.ageItem = {
						startAge: startAge,
						endAge: endAge,
						enforced: ageEnforced,
					};
				}
			} else {
				this.groupData.ageItem = null;
			}
			this.isAgeLimit = isAgeLimit;
		},
		onPrivacyChange(updatedData) {
			const isPrivate = updatedData.isPrivate;
			this.groupData.isPrivate = isPrivate;
		},
		onMemberPrivacyChange(updatedData) {
			const isMembersOnly = updatedData.isPrivate;
			this.groupData.isMembersOnly = isMembersOnly;
		},
		onSave() {
			if (!this.checkTitleDescription()) {
				this.showError('Please enter a title and description.');
				return;
			} else if (!this.checkImage()) {
				this.showError('Please add an event image.');
				return;
			} else if (!this.checkRegistrationButton()) {
				this.showError('Please add a custom button title and valid link.');
				return;
			} else if (!this.checkRegistrationAge()) {
				this.showError('Please enter a valid age limit.');
				return;
			}

			this.isLoading = true;

			GroupItemManager.addGroupToDB(this.groupData, this.selectedImage)
				.then((groupData) => {
					this.isLoading = false;
					this.navigateToGroups();
				})
				.catch((error) => {
					this.isLoading = false;
					this.showError(error.message);
				});
		},
		checkTitleDescription() {
			if (
				!this.$helpers.getNullOrValue(this.groupData.groupTitle) ||
				!this.$helpers.getNullOrValue(this.groupData.groupDescription)
			) {
				return false;
			}
			return true;
		},
		checkImage() {
			if (!this.$helpers.getNullOrValue(this.selectedImage)) {
				return false;
			}
			return true;
		},
		checkRegistrationButton() {
			if (this.isCustomDefaultButton) {
				if (
					!this.$helpers.getNullOrValue(this.groupData.groupCustomButtonTitle) ||
					!this.$helpers.getNullOrValue(this.groupData.groupCustomButtonLink)
				) {
					return false;
				}
			}
			return true;
		},
		checkRegistrationAge() {
			if (this.isAgeLimit) {
				if (!this.$helpers.getNullOrValue(this.groupData.ageItem.startAge)) {
					return false;
				}
			}
			return true;
		},
		handleHeaderClick(buttonItem) {
			if (buttonItem.index == 0) {
				this.onSave();
			}
		},
		checkIfCanUpload() {
			this.isLoading = true;
			GroupItemManager.canUpload().then((canUpload) => {
				this.isLoading = false;
				if (!canUpload) {
					this.showError('You have reached your limit on your current plan.');
					this.navigateToGroups();
				}
			});
		},
		showError(message) {
			this.showNotification({
				type: 'danger',
				message: message,
			});
		},
		navigateToGroups() {
			this.$router.replace({
				name: DashboardChildren.groups.name,
				params: { refresh: true },
			});
		},
	},
	mounted() {
		this.checkIfCanUpload();
	},
};
</script>
