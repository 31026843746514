<template>
	<!-- Left div because b-navbar does not trigger on mouseenter / mouseleave events -->
	<div
		class="sidenav navbar navbar-vertical fixed-left navbar-expand-xs navbar-dark bg-dark"
		@mouseenter="$sidebar.onMouseEnter()"
		@mouseleave="$sidebar.onMouseLeave()"
		:data="backgroundColor"
	>
		<div class="scrollbar-inner" ref="sidebarScrollArea">
			<div class="sidenav-header d-flex align-items-center">
				<b-navbar-brand
					class="p-0 flex-grow-1 d-flex align-items-center"
					style="overflow: hidden"
					href="#"
				>
					<b-avatar
						class="ml-3"
						alt="Image placeholder"
						:src="logo"
						size="md"
						variant="dark"
					/>
					<h4
						class="my-0 ml-2 text-sm text-white"
						style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
					>
						{{ title }}
					</h4>
				</b-navbar-brand>
				<div class="ml-auto">
					<!-- Sidenav toggler -->
					<div
						class="sidenav-toggler d-none d-xl-block"
						:class="{ active: !$sidebar.isMinimized }"
						@click="minimizeSidebar"
					>
						<div class="sidenav-toggler-inner">
							<i class="sidenav-toggler-line bg-light"></i>
							<i class="sidenav-toggler-line bg-light"></i>
							<i class="sidenav-toggler-line bg-light"></i>
						</div>
					</div>
				</div>
			</div>
			<slot></slot>
			<div class="navbar-inner">
				<b-navbar-nav>
					<slot name="links">
						<sidebar-item
							v-for="(link, index) in sidebarLinks"
							:key="link.name + index"
							:link="link"
						>
							<sidebar-item
								v-for="(subLink, index) in link.children"
								:key="subLink.name + index"
								:link="subLink"
							>
							</sidebar-item>
						</sidebar-item>
					</slot>
				</b-navbar-nav>
				<slot name="links-after"></slot>
			</div>
			<div class="sidenav-footer py-0"><slot name="footer"></slot></div>
		</div>
	</div>
</template>
<script>
import Card from '../Cards/Card.vue';
import BaseButton from '../BaseButton.vue';
export default {
	name: 'sidebar',
	props: {
		title: {
			type: String,
			default: 'MySunday | Dashboard With More Life',
			description: 'Sidebar title',
		},
		shortTitle: {
			type: String,
			default: 'DT',
			description: 'Sidebar short title',
		},
		logo: {
			type: String,
			default: 'favicon.png',
			description: 'Sidebar app logo',
		},
		backgroundColor: {
			type: String,
			default: 'green',
			validator: (value) => {
				let acceptedValues = [
					'',
					'vue',
					'blue',
					'green',
					'orange',
					'red',
					'primary',
				];
				return acceptedValues.indexOf(value) !== -1;
			},
			description: 'Sidebar background color (vue|blue|green|orange|red|primary)',
		},
		sidebarLinks: {
			type: Array,
			default: () => [],
			description:
				"List of sidebar links as an array if you don't want to use components for these.",
		},
		autoClose: {
			type: Boolean,
			default: true,
			description:
				'Whether sidebar should autoclose on mobile when clicking an item',
		},
	},
	provide() {
		return {
			autoClose: this.autoClose,
		};
	},
	methods: {
		minimizeSidebar() {
			if (this.$sidebar) {
				this.$sidebar.toggleMinimize();
			}
		},
		openWebsite() {},
	},
	mounted() {
		this.$sidebar.isMinimized = this.$sidebar.breakpoint < window.innerWidth;
		this.minimizeSidebar();
	},
	beforeDestroy() {
		if (this.$sidebar.showSidebar) {
			this.$sidebar.showSidebar = false;
		}
	},
	components: { Card, BaseButton },
};
</script>
