<template>
	<validation-provider
		:rules="rules"
		:name="name"
		v-bind="$attrs"
		v-slot="{ errors, valid, invalid, validated }"
	>
		<b-form-group class="m-0 p-0" :class="[withPadding ? 'mb-3' : '']">
			<slot name="label">
				<label v-if="label" class="oneLine" :class="labelClasses">
					{{ label }}
				</label>
			</slot>
			<div
				:class="[
					{ 'input-group': hasIcon },
					{ focused: focused },
					{ 'input-group-alternative': alternative },
					{ 'has-label': label || $slots.label },
					inputGroupClasses,
				]"
			>
				<div v-if="prependIcon || $slots.prepend" class="input-group-prepend">
					<span class="input-group-text">
						<slot name="prepend">
							<i :class="prependIcon"></i>
						</slot>
					</span>
				</div>
				<slot v-bind="slotData">
					<textarea
						v-if="type === 'textarea'"
						:value="value"
						v-on="listeners"
						v-bind="$attrs"
						:placeholder="placeholder"
						:maxlength="maxLength"
						:valid="valid"
						:required="required"
						:rows="rows"
						class="form-control"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							inputClasses,
						]"
					></textarea>
					<TheMask
						v-else-if="type === 'mask'"
						:mask="mask"
						:masked="maskedValue"
						:valid="valid"
						:value="value"
						:placeholder="placeholder"
						v-on="listeners"
						v-bind="$attrs"
						:required="required"
						:disabled="disabled"
						class="form-control"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							inputClasses,
						]"
					>
					</TheMask>
					<Multiselect
						v-else-if="type === 'multiselect'"
						v-model="multiSelectValue"
						:valid="valid"
						:required="required"
						:options="multiSelectOptions"
						label="categoryName"
						track-by="categoryId"
						@tag="onAddTag"
						@select="onSelectedTag"
						@remove="onTagRemove"
						:show-labels="false"
						:multiple="allowMultiple"
						:taggable="multiSelectTaggable"
						:hideSelected="allowMultiple"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							inputClasses,
						]"
					>
					</Multiselect>
					<input
						v-else-if="type === 'currency'"
						v-on="listeners"
						:valid="valid"
						:required="required"
						:disabled="disabled"
						class="form-control"
						type="text"
						:value="currencyValue"
					/>
					<input
						v-else
						:type="type"
						:pattern="pattern"
						:value="value"
						v-on="listeners"
						:placeholder="placeholder"
						:valid="valid"
						:required="required"
						:disabled="disabled"
						:maxlength="type == 'number' ? undefined : maxLength"
						:minlength="type == 'number' ? undefined : minLength"
						:max="maxLength"
						:min="minLength"
						class="form-control"
						:class="[
							{ 'is-valid': valid && validated && successMessage },
							{ 'is-invalid': invalid && validated },
							inputClasses,
						]"
					/>
				</slot>
				<b-row v-if="maxLength && showMaxLabel" class="my-1">
					<b-col> </b-col>
					<b-col class="justify-content-right text-right">
						<h6
							class="m-0"
							:style="{
								color: value.length == maxLength ? 'red' : 'gray',
							}"
						>
							Max: {{ value.length }}/{{ maxLength }}
						</h6>
					</b-col>
				</b-row>
				<div v-if="appendIcon || $slots.append" class="input-group-append">
					<span class="input-group-text">
						<slot name="append">
							<i :class="appendIcon"></i>
						</slot>
					</span>
				</div>
				<slot name="infoBlock"></slot>
			</div>
			<slot name="success">
				<div class="valid-feedback" v-if="valid && validated && successMessage">
					{{ successMessage }}
				</div>
			</slot>
			<slot name="error">
				<div v-if="errors[0]" class="invalid-feedback" style="display: block">
					{{ errors[0] }}
				</div>
			</slot>
		</b-form-group>
	</validation-provider>
</template>
<script>
import { EmojiPicker } from 'vue-emoji-picker';
import Multiselect from 'vue-multiselect';
import { TheMask } from 'vue-the-mask';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import BaseButton from '../BaseButton.vue';

export default {
	inheritAttrs: false,
	name: 'base-input',
	props: {
		required: {
			type: Boolean,
			default: false,
			description: 'Whether input is required (adds an asterix *)',
		},
		disabled: {
			type: Boolean,
			description: 'Whether input is disabled',
			default: false,
		},
		pattern: {
			type: String,
			description: 'Possible pattern for url',
		},
		group: {
			type: Boolean,
			description:
				'Whether input is an input group (manual override in special cases)',
		},
		placeholder: {
			type: String,
			description: 'Placeholder for input',
		},
		alternative: {
			type: Boolean,
			description: 'Whether input is of alternative layout',
		},
		label: {
			type: String,
			description: 'Input label (text before input)',
		},
		error: {
			type: String,
			description: 'Input error (below input)',
		},
		successMessage: {
			type: String,
			description: 'Input success message',
			default: '',
		},
		labelClasses: {
			type: String,
			description: 'Input label css classes',
			default: 'form-control-label',
		},
		inputClasses: {
			type: String,
			description: 'Input css classes',
		},
		inputGroupClasses: {
			type: String,
			description: 'Input group css classes',
		},

		type: {
			type: String,
			description: 'Input type',
			default: 'text',
		},
		value: {
			type: [String, Number, Date],
			description: 'Input value',
		},
		appendIcon: {
			type: String,
			description: 'Append icon (right)',
		},
		prependIcon: {
			type: String,
			description: 'Prepend icon (left)',
		},
		rules: {
			type: [String, Array, Object],
			description: 'Vee validate validation rules',
			default: '',
		},
		mask: {
			type: [String, Array],
			default: null,
		},
		maskedValue: {
			type: Boolean,
			default: false
		},
		maxLength: {
			type: Number,
		},
		minLength: {
			type: Number,
			default: null,
		},
		showMaxLabel: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			description: 'Input name (used for validation)',
			default: '',
		},
		allowMultiple: {
			type: Boolean,
			default: false,
		},
		multiSelectOptions: {
			type: [Array, Object],
			description: 'Category Options for Multi-select',
		},
		multiSelectValue: {
			type: [Array, Object],
			description: 'Category Selected Values',
		},
		multiSelectTaggable: {
			type: Boolean,
			description: 'If new tag items can be added',
			default: false,
		},
		rows: {
			type: String,
			description: 'Rows Height',
			default: '3',
		},
		withPadding: {
			type: Boolean,
			default: true,
		},
		showEmojiPicker: {
			type: Boolean,
			default: true,
		},
	},
	components: { Multiselect, TheMask, BaseButton, EmojiPicker },
	data() {
		return {
			focused: false,
			search: '',
			selections: [],
			isCurrencyActive: false,
			isCurrencyFocused: false,
		};
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: this.updateValue,
				focus: this.onFocus,
				blur: this.onBlur,
			};
		},
		slotData() {
			return {
				focused: this.focused,
				error: this.error,
				...this.listeners,
			};
		},
		hasIcon() {
			const { append, prepend } = this.$slots;
			return (
				append !== undefined ||
				prepend !== undefined ||
				this.appendIcon !== undefined ||
				this.prependIcon !== undefined ||
				this.group
			);
		},
		currencyValue: {
			get: function () {
				if (this.type === 'currency') {
					if (this.isCurrencyActive) {
						// Cursor is inside the input field. unformat display value for user
						return this.value.toString();
					} else {
						// User is not modifying now. Format display value for user interface
						return (
							'$ ' +
							parseFloat(this.value)
								.toFixed(2)
								.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
						);
					}
				}
			},
			set: function (modifiedValue) {
				if (this.type === 'currency') {
					// Recalculate value after ignoring "$" and "," in user input
					let newValue = parseFloat(modifiedValue.replace(/[^\d\.]/g, ''));
					// Ensure that it is not NaN
					if (isNaN(newValue)) {
						newValue = 0;
					}
					// Note: we cannot set this.value as it is a "prop". It needs to be passed to parent component
					// $emit the event so that parent component gets it
					this.$emit('input', newValue);
				}
			},
		},
	},
	methods: {
		updateValue(evt) {
			if (this.type === 'mask') {
				this.$emit('input', evt);
			} else {
				let value = evt.target.value;
				this.$emit('input', value);
			}
		},
		onFocus(evt) {
			if (this.type === 'currency') {
				this.isCurrencyActive = true;
			} else {
				this.focused = true;
			}
			this.$emit('focus', evt);
		},
		onBlur(evt) {
			if (this.type === 'currency') {
				this.isCurrencyActive = false;
			} else {
				this.focused = false;
			}
			this.$emit('blur', evt);
		},
		onAddTag(tag) {
			this.$emit('tag', tag);
		},
		onSelectedTag(tag) {
			this.$emit('select', tag);
		},
		onTagRemove(tag) {
			this.$emit('remove', tag);
		},
	},
};
</script>
<style></style>
