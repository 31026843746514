<template>
	<div
		v-if="givingData"
		@mouseover="imageHover = true"
		@mouseleave="imageHover = false"
	>
		<Card no-body style="overflow: hidden" class="b-img-helpers">
			<div :style="{ opacity: imageHover ? 0.4 : 1 }">
				<b-container
					v-if="givingData.imageItem"
					class="m-0 p-0"
					style="overflow: hidden"
				>
					<VueAspectRatio ar="3:1">
						<div>
							<b-img fluid center :src="givingData.imageItem.imageUrl"></b-img>
						</div>
					</VueAspectRatio>
				</b-container>
				<b-container class="p-2">
					<h4 class="mb-1">{{ givingData.title }}</h4>
					<h6 class="text-muted mb-2">{{ givingData.description }}</h6>
					<BaseButton v-if="givingData.link" class="mb-2" block>{{
						givingData.buttonTitle ? givingData.buttonTitle : 'Give Now'
					}}</BaseButton>
				</b-container>
			</div>

			<div
				v-if="imageHover"
				style="
					height: 100%;
					width: 100%;
					border: dashed 1px black;
					display: flex;
					flex-wrap: wrap;
					align-content: center;
				"
				class="justify-content-center b-img-base"
			>
				<BaseButton squared type="medium" size="sm" block @click="deleteClicked"
					>Delete</BaseButton
				>
			</div>
		</Card>
	</div>
</template>
<script>
import VueAspectRatio from 'vue-aspect-ratio';
import Card from '../../../components/Cards/Card.vue';
import BaseButton from '../../../components/BaseButton.vue';
import CardHeader from '../../../components/Cards/CardHeader.vue';

export default {
	props: {
		givingData: {
			type: Object,
			default: null,
		},
	},
	components: { Card, VueAspectRatio, BaseButton, CardHeader },
	data() {
		return {
			imageHover: false,
		};
	},
	methods: {
		deleteClicked() {
			this.$emit('deleteClicked', this.givingData);
		},
	},
};
</script>
