const mutations = {
	setUser(state, data) {
		state.user = data;
	},
	setOrganization(state, data) {
		state.organization = data;
	},
	setNotification(state, data) {
		state.notification = data;
	},
	setLastLoggedIn(state, data) {
		state.lastLoggedIn = data;
	},
};

export default mutations;
